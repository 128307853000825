import { React, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStyle } from '../../context/style_context/styleContext';
import axios from 'axios';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';
import OrderConfirmationModal from '../../pages/checkoutPage/ConfirmaionModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to replace this with your own publishable key from Stripe Dashboard
//test key
const stripePromise = loadStripe('pk_test_51PnT4PAo6Xubpx1wGhb0K7NG1NYRfuoxMwBy52NucgxJG5n0bioR9AmTCzOiE0Rfy0m18B9biE0MB9Zm97OEruKi00J9OuEF8h');
//live key
//const stripePromise = loadStripe('pk_live_51PnT4PAo6Xubpx1wNTwkfYwcO1eFp8Yb4yeCy59v3NkRneYNfCx1f1SyI9Pdrf5AzpO2rWxyAuF5AS5I6Q55OTrK00Eunny8fZ');



const SubscribeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { style } = useStyle();
  const { cartItems, clearShoppingCart } = useContext(ShoppingCartContext);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const openConfirmationModal = () => setConfirmationModalOpen(true);
  const closeConfirmationModal = () => setConfirmationModalOpen(false);




  const handleSubmit = async (event) => {
    event.preventDefault();
    var subscriptionCreated = false;

    try {
      if (!stripe || !elements) {
        return;
      }

      setIsProcessing(true);

      const cardElement = elements.getElement(CardElement);

      // Create a payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
        return;
      }

      // Send the payment method ID and the subscription plan ID to the server
      const response = await axios.post('/api/create_subscription', {
        paymentMethodId: paymentMethod.id,
        planId: 'prod_QoFgCoAg0dV5wJ', // Subscription Plan ID
      });

      const subscriptionResponse = await response.data;

      if (subscriptionResponse.error) {
        setErrorMessage(subscriptionResponse.error.message);
        setIsProcessing(false);
        toast.error(subscriptionResponse.message);
      } else {
        // Confirm the payment for the first payment of the subscription
        const { latest_invoice } = subscriptionResponse;
        const { payment_intent: paymentIntent } = latest_invoice;

        if (paymentIntent) {
          const { error: confirmError } = await stripe.confirmCardPayment(
            paymentIntent.client_secret
          );

          if (confirmError) {
            setErrorMessage(confirmError.message);
            toast.error(confirmError.message);
          } else if (paymentIntent.status === 'succeeded') {
            subscriptionCreated = true;
            toast.success("Boo yeah!")
            finalizeTransaction();
            clearShoppingCart();
            openConfirmationModal();
          }
        }
        setIsProcessing(false);
      }
    } catch (error) {
      if (!subscriptionCreated) {
        toast.error("ERROR: We were unable to process your subscription.");
        setIsProcessing(false);
      }
    }
  };

  const finalizeTransaction = async () => {
    try {
      const { data } = await axios.post('/api/finalize_transaction', {
        products: cartItems,
        subscription: true, // Indicate it's a subscription
      });

      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success("Subscription and transaction finalized!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: '20pt',
        color: 'white',
        backgroundColor: style.color10,
        width: '50%',
        maxWidth: '500px',
        marginLeft: '50px',
        padding: '5px',
        borderColor: style.color4,
        borderWidth: '1px',
        borderStyle: 'solid',
        marginTop: '20px',
        '::placeholder': {
          color: 'grey',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: 'auto', backgroundColor: style.color9 }}>
        <div
          style={{
            width: '80%',
            maxWidth: '800px',
            margin: 'auto',
            backgroundColor: style.color10,
            padding: '10px',
            borderColor: style.color4,
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
        >
          <CardElement options={cardStyle} />
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        </div>
        <div>
          <Button
            type="submit"
            sx={{
              backgroundColor: style.color4,
              color: style.color5,
              minWidth: '250px',
              fontSize: '1.2em',
              marginTop: '50px',
              marginBottom: '25px',
            }}
            disabled={!stripe || isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Submit Payment'}
          </Button>
        </div>
        <div style={{ marginBottom: '25px' }}>
          <Link to={'/shopping_cart'} style={{ color: style.color4 }}>
            Back To Shopping Cart
          </Link>
        </div>

        <div id="modal-root">
          <OrderConfirmationModal isOpen={isConfirmationModalOpen} close={closeConfirmationModal}></OrderConfirmationModal>
        </div>
      </form>
    </>
  );
};

const SubscribePage = () => {
  return (
    <Elements stripe={stripePromise}>
      <SubscribeForm />
    </Elements>
  );
};



export default SubscribePage;
