
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export default function Support(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

    ReactGA.send({
      hitType:"pageview",
      page:"support",
      title:"support",
    })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | State Of The Art Audio Plugins and Processors | Support';
  }, []);


  const changeBackground = () => {
    setStyle({ ...style, backgroundColor: 'rgb(0,255,0)' });
  };

    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
          <div style={{textAlign:'center', color: style.color5, minHeight:'600px'}}>
            <div>

            </div>
            <h1> Support</h1>

            <div style={{width:'95%', maxWidth:'1000px', margin:'auto'}}>
              <Accordion sx={{backgroundColor:style.color3, color:style.color5}}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon style={{color:style.color5}}/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography><h3 style={{marginTop:'0px', marginBottom:'0px'}}>Product Installation and Registration</h3></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:style.color10, color:style.color5}}>
                  <Typography>
                  <ul style={{margin:'auto', listStyleType:'disc', marginLeft:'30px'}}>
                  <li>
                    <p style={{textAlign:'left'}}>
                    Once you purchase an item or sign up for a subscription, your product licenses will automatically be generated and your products will display on your <a href='/my-gear' style={{color:style.color4}}><strong>my gear</strong></a> page. 

                    </p>
                    </li>
                    <li>
                      <p style={{textAlign:'left'}}>
                        Navigate to your <a href='/my-gear' style={{color:style.color4}}><strong>my gear</strong></a> page, and select the appropriate download option.
                      </p>
                    </li>
                    <li>
                      <p style={{textAlign:'left'}}>
                        Once the installation file has downloaded, run the program. This will install and setup all necesarry files. 
                      </p>
                    </li>
                    <li>
                      <p style={{textAlign:'left'}}>
                        Launch the application, or open the plugin in your DAW. Use your username and password to login.
                      </p>
                    </li>
                    <li>
                      <p style={{textAlign:'left'}}>
                        <strong>Done!</strong> Your application is now registered and is ready to use.
                      </p>
                    </li>

                    </ul>
                                </Typography>
                </AccordionDetails>
              </Accordion >
              <Accordion sx={{backgroundColor:style.color3, color:style.color5}}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon style={{color:style.color5}}/>}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography><h3 style={{marginTop:'0px', marginBottom:'0px'}}>Contact Us</h3></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:style.color10, color:style.color5}}>
                  <Typography>
                  We would love to hear from you. If you have questions or need support, please contact us at <a href="mailto:brainwavesoundlab@gmail.com" style={{color:style.color4, fontSize:'14pt'}}>brainwavesoundlab@gmail.com</a>.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

                
            </div>
          </div>
        </>

    )
}