import React, { createContext, useContext, useState } from 'react';
import './style.css';



// Create a context
export const StyleContext = createContext();

// Provider component that wraps your app and provides the style values
export const StyleProvider = ({ children }) => {


  const [style, setStyle] = useState({

    color1: 'rgb(22,21,20)',
    color2: 'rgb(15,16,17)',
    color3: 'rgb(88,87,83)',
    color4: '#535bf2',
    color5: 'rgb(250,250,248)',
    color6: 'rgb(4,3,2)',
    color7: 'rgb(220,220,220)',
    color8: 'rgb(100,100,100)',
    color9: 'rgb(58,59,60)',
    color10: 'rgb(40,41,42)',

    productBGColor:'rgb(25,31,34)',

    cardCalloutGreen: 'rgb(6, 138, 42)',
    cardCalloutBlue: 'rgb(14, 31, 143)',

  });

  document.body.style.color = style.color5
  document.body.style.backgroundColor = style.color1;
  document.body.style.color = style.textColor5;


  return (
    <StyleContext.Provider value={{ style, setStyle }}>
      {children}
    </StyleContext.Provider>
  );
};

// Custom hook to use the style context
export const useStyle = () => useContext(StyleContext);
