import {React, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { StyleContext,  useStyle } from '../../context/style_context/styleContext';
import Button from '@mui/material/Button';
import ReactGA from 'react-ga4';

const TermsModal = ({ isOpen, close, children }) => {

  const { style, setStyle } = useStyle();
  ReactGA.send({
    hitType:"pageview",
    page:"terms_modal",
    title:"terms_modal",
  })
  useEffect(() => {
    document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Terms and Conditions';
}, []);
  
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        {children}
        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button onClick={close} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'12pt', width:'100%', minWidth:'100px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>OK</Button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

  
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  zIndex: "1000",
};

const modalContentStyle = {
  padding: '20px',
  background: 'rgb(58,59,60)',
  borderRadius: '5px',
  borderColor:'#535bf2',
  borderSize:'1px',
  borderStyle:'solid',
  maxWidth: '500px',
  minHeight: '300px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column'
};

const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default TermsModal;
