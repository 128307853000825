
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReactGA from 'react-ga4';



export default function ReturnPolicy(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

    ReactGA.send({
      hitType:"pageview",
      page:"return policy",
      title:"about",
    })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | Frequently Asked Questions';
  }, []);



    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', minHeight:'68vh', margin:'auto'}}>
          <div style={{textAlign:'left', color: style.color5, textAlign:'center', marginTop:'0px', marginBottom:'0px'}}>
            <h1 style={{ marginTop:'30px', marginBottom:'0px'}}> Return Policy</h1>

            </div>
            <div style={{width:'95%', maxWidth:'1000px', margin:'auto', marginTop:'10px'}}>
              <p>We want you to be completely satisfied with your software. If you are a subscriber, you may cancel your subscription at any time with no cancellation fee. Your products will remain activated until the end of your current subscription period expires.</p>
              <p>For software purchases, you may request a refund withn 14 days of the date of purchase. </p>
              
            </div>
          </div>
        </>

    )
}