import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

// Create the shopping cart context
export const ShoppingCartContext = createContext();

export const ShoppingCartProvider = ({ children }) => {
  
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve the cart from localStorage if it exists
    const savedCart = localStorage.getItem('shoppingCart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [cartSubtotal, setCartSubtotal] = useState(0)

  // Save cart items to localStorage whenever it changes
  useEffect(() => {

    //console.log("CART ITEMS:", cartItems)
    //console.log("ITERATOR:")
    let subtotal = 0;
    for(var i =0; i < cartItems.length; i++)
    {
        subtotal = subtotal + cartItems[i].price
    }
    setCartSubtotal(subtotal)

    localStorage.setItem('shoppingCart', JSON.stringify(cartItems))
    //console.log(cartSubtotal);
  }, [cartItems]);

  const addToCart = async (productSKU) => {
    const productInfo = await getProductInfo(productSKU);
    
    console.log(productSKU)

    if (!productInfo) {
      toast.error("Product not found!");
      return;
    }

    

    setCartItems((prevItems) => {
      // Check if the product is already in the cart
      const itemExists = prevItems.some(item => item.SKU === productInfo.SKU);
      
      // Only add the product if it doesn't already exist
      if (!itemExists) {
        return [...prevItems, productInfo];
      }

      return prevItems; // If it exists, return the existing array unchanged
    });
  };

  const clearShoppingCart = () => {
    setCartItems([])
    localStorage.setItem('shoppingCart', JSON.stringify(cartItems))
  }

  const removeFromCart = (productId) => {    
    setCartItems((prevItems) => prevItems.filter(item => item.SKU !== productId));
  };

  // Fetch product details by SKU
  const getProductInfo = async (productSKU) => {
    console.log("Searching for Product: ", productSKU);

    try {
      const { data } = await axios.post('/api/get_product', { productSKU });

      if (data.error) {
        toast.error(data.error);
        return null;
      }

      console.log("Product Data: ", data);
      return data; // Full product details returned from backend
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch product details");
      return null;
    }
  };

  return (
    <ShoppingCartContext.Provider value={{ cartItems, cartSubtotal, addToCart, removeFromCart, clearShoppingCart }}>
      {children}
    </ShoppingCartContext.Provider>
  );
};


