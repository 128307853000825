
import {React, useState, useContext, useEffect, useRef, List} from "react"
import { Link } from "react-router-dom"
import axios from 'axios';
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import { ShoppingCartContext } from "../../context/shoppingCartContext";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {toast} from 'react-hot-toast';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';
import Background from '../../images/RecordingStudioStockImage1.jpg'
import SubscribeRedirectModal from "../../components/SubscribeRedirectModal/SubscribeRedirectModal";
import LoginSubscribePromptModal from "../../components/LoginSubscribePromptModal/LoginSubscribePromptModal";
import LeaveReviewModal from "./leaveReviewModal";




export default function ProductReviewPage(props){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();
  const { addToCart, removeFromCart, cartItems } = useContext(ShoppingCartContext);
  const [reviews, setReviews] = useState();
  const [productSKU, setProductSKU] = useState();

  const [isSubscribeRedirectModalOpen, setSubscribeRedirectModalOpen] = useState(false);
  const openSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(true);
  const closeSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(false);
  
  
  const [isLoginRegisterModalOpen, setLoginRegisterModalOpen] = useState(false);
  const openLoginRegisterModal = () => setLoginRegisterModalOpen(true);
  const closeLoginRegisterModal = () => setLoginRegisterModalOpen(false);

  const [isLeaveReviewModalOpen, setLeaveReviewModalOpen] = useState(false);
  const openLeaveReviewModal = () => setLeaveReviewModalOpen(true);
  const closeLeaveReviewModal = () => setLeaveReviewModalOpen(false);

  const name = window.location.pathname.split("/").pop();


    ReactGA.send({
      hitType:"pageview",
      page: name,
      title: name,
    })

    const leaveReview = async (e) => {

      if(user)
      {
        openLeaveReviewModal()
      }
      else
      {
        openLoginRegisterModal()
      }
    }

    const getReviews = async (e) => {

      const productURI = window.location.pathname.split("/").pop();
      console.log(productURI)
      let sku = "";

      let productData = await axios.post('/api/get_product_by_uri', {productURI})



        if(productData)
        {
            sku = productData.data.SKU
            console.log(productData.data.SKU)
            setProductSKU(productData.data.SKU)
        }
        else{
            console.log('data error')
        }

        console.log("SKU ", sku)
        

        console.log("requesting data, productSKU: ", sku)
        const{data} = await axios.post('/api/get_all_product_reviews', {productSKU: sku})
        if(data)
        {
            console.log(data)
            setReviews(data)
        }
        else{
            console.log('data error')
        }

    }
    
    
    useEffect(() => {
        getReviews();
    }, []);

    const addProductToCart = async (e) => {

        e.preventDefault();

        //addToCart(productData.SKU)
        toast.success('Product added to cart')    
    
    }
    
    const navToSubscribePage = async (e) =>
      {
        console.log("subscribe button")
        if(user)
        {
          window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
        }
        else
        {
          openLoginRegisterModal()
        }
        
      }

      const getAllReviews = async (e) =>
        {

          console.log("get reviews function fired")
          console.log(name)
        }

  const helpful = async (reviewID) => 
    {
    console.log("helpful")
    console.log(reviewID)
  }

  const notHelpful = async (reviewID) => 
    {
      console.log("not helpful")
      console.log(reviewID)
    }


  

const downloadProductWindows = async (e) => 
    {

      try{
        const response = await axios.post('/api/log_download_attempt', 
          {
            sku: "10001",
            productName:"Infinity Reverb",
            productVersion:"1.0.0",
            productURI: '/Infinity-Reverb-v1_0_0-Windows-Installer-VST3.exe',
          });
      }
      catch{
        
      }
      

      console.log("download product!")
      
      window.location.href = "/Infinity-Reverb-v1_0_0-Windows-Installer-VST3.exe";
  }




    return(
        <>

<>

        {!productSKU && (<div style={{margin:'auto', textAlign:'center', marginTop:'100px'}}><h1>Loading...</h1></div>)}
        {productSKU && (
        <div style={{minHeight:'68vh'}}>

          {reviews && (
            reviews.map((review) => (

            <div style={{ marginTop:'30px'}} key={review._id}>


  <Card sx={{ maxWidth: 345, margin:'auto' }}>

  <CardContent>

  <div style={{display:'flex', flexDirection:'row', marginBottom:'10px'}}>
    <div style={{display:'flex', flex:1}}>
        <Typography gutterBottom variant="h5" component="div">
          {review.userName}
      </Typography>
    </div>
    <div style={{display:'flex', width:'125px'}}>
      <Rating name="read-only" precision={0.5} value={review.starRating} readOnly sx={{margin:'auto'}} />
    </div>

  </div>

  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    {review.comment}
  </Typography>
</CardContent>
{/* <CardActions>
  <div style={{display:'flex', flex:1, flexDirection:'row', textAlign:'center'}}>
              <div style={{flex:1}}>
                <Button onClick={() => helpful(review._id)} size="small">Helpful</Button>
              </div>
              <div style={{flex:1}}>
                <Button onClick={() => notHelpful(review._id)} size="small">Not Helpful</Button>
              </div>

  </div>

</CardActions> */}
</Card>
</div>

            // <MyGearTableRow
            // key={review._ID} 
            // productSKU={product.name}
            // imageURI = {product.imageURIs[0]}
            // windowsDownloadURI={product.windowsDownloadURI}

            // //productImageURIs={item.imageURIs}
            // sx={{ minWidth: 700, marginBottom:'30px'}}
            // />
          ))

          )}
          
          
          <div id='modal-root'>
          <SubscribeRedirectModal isOpen={isSubscribeRedirectModalOpen} close={closeSubscribeRedirectModal} />
          <LoginSubscribePromptModal isOpen={isLoginRegisterModalOpen} close={closeLoginRegisterModal} />
          <LeaveReviewModal sku={productSKU}isOpen={isLeaveReviewModalOpen} close={closeLeaveReviewModal} />
        </div>
          </div>




        )}
        


        </>
    
        
        </>

    )
}