import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ReactGA from 'react-ga4';
import{UserContext} from '../../context/userContext';
import { useStyle } from "../../context/style_context/styleContext";
import TermsModal from "../terms/TermsModal";
import Terms from "../terms/Terms";
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Checkbox, TextField, FormControl, FormHelperText, InputLabel, OutlinedInput, InputAdornment, IconButton, FormGroup, FormControlLabel } from "@mui/material";

export default function Register() {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const[okToSubmit, setHideButtons] = useState(false)
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { style, setStyle } = useStyle();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState({ message: '', color: 'red' });
  const [confirmPasswordHelper, setConfirmPasswordHelper] = useState({ message: '', color: '' });
  const { setUser } = useContext(UserContext);
  const { isLoading,  } = useContext(UserContext);
  const { setUpdateFlag } = useContext(UserContext);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  ReactGA.send({
    hitType: "pageview",
    page: "register",
    title: "register",
  });

  ReactGA.send({
    hitType:"pageview",
    page:"account",
    title:"account",
  })

  useEffect(() => {
  document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Register';
}, []);

  const [data, setData] = useState({
    email: '',
    password: '',
    password2: '',
  });

  const navToLogin = () => {
    navigate('/login');
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setData({ ...data, password });

    if (password.length >= 6 && password.length > 0) {
      setPasswordHelper({ message: 'Password OK', color: 'green' });
    } else {
      setPasswordHelper({ message: 'Password must be 6 characters or more', color: 'red' });
    }
  };

  const checkIfOK = (e) => {

  }

  const handleConfirmPasswordChange = (e) => {
    const password2 = e.target.value;
    setData({ ...data, password2 });

    if(data.password.length < 1)
    {
        setConfirmPasswordHelper({message: '', color:'red'})
    }
    else if(password2.length < 1)
    {
        setConfirmPasswordHelper({message: '', color:'red'})
    }
    else if (password2 !== data.password) {
      setConfirmPasswordHelper({ message: 'Passwords do not match', color: 'red' });
    } else {
      setConfirmPasswordHelper({ message: 'Passwords match', color: 'green' });
    }
  };

  const registerUser = async (e) => {
    e.preventDefault();
    var okToSend = true;

    const { email, password, password2 } = data;
    const credType = 'cookie'

    if (!email) {
      okToSend = false;
      toast.error("Error: You must enter your E-Mail address to create an account");
    }

    if (!password) {
      okToSend = false;
      toast.error("Error: Password cannot be blank");
    }

    if (!password2) {
      okToSend = false;
      toast.error("Error: Please enter your password again");
    }

    if (password !== password2) {
      okToSend = false;
      toast.error("Passwords do not match");
    }

    if (okToSend) {
      try {



        const { data } = await axios.post('/register', { email, password });

        if (data.error) {
          toast.error(data.error);
        } else {
          setData({});

          const{data} = await axios.post('/login', {
            email, 
            password,
            credType,
          });
          const userData = data;
          if(data.error){

            toast.error(data.error)
        }   else {            

            await setUser(userData);

            toast.success(data.success)
            await setUpdateFlag()
            //window.location.href = `/`
    }



          toast.success('Registration Successful. Welcome!');
         window.location.href = `/`
        }






      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '800px', margin: 'auto', color: style.color5, textAlign: 'center', marginTop: '5px', backgroundColor: style.color2, borderRadius: '10px', borderStyle: 'solid', borderColor: style.color4 }}>
          <div style={{ width: '95%', margin: 'auto' }}>
            <div style={{ display: 'flex', flex: 1, textAlign: 'center', marginTop: '20px' }}>
              <h1 style={{ flex: 1, textAlign: 'center', margin: 'auto', marginBottom: '50px' }}>
                Create Your Account
              </h1>
            </div>
            <div style={{ maxWidth: '400px', margin: 'auto' }}>
              <div style={{ display: 'flex', flex: 1, textAlign: 'center', minWidth: '300px', margin: 'auto', marginBottom: '10px' }}>
                <h4 style={{ flex: 1, textAlign: 'left', margin: 'auto', color: style.color5 }}>
                  <div style={{  }}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      value={data.email}
                      onChange={(e) => setData({ ...data, email: e.target.value })}
                      size='small'
                      fullWidth
                      sx={{
                        backgroundColor: style.color10,
                        borderRadius: '4px',
                        '& .MuiInputBase-input': {
                          color: style.color5,
                          fontSize: '16pt',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: style.color4,
                            borderWidth: '2px'
                          },
                          '&:hover fieldset': {
                            borderColor: style.color4,
                            borderWidth: '3px',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: style.color4,
                            borderWidth: '3px'
                          },
                          '&.Mui-disabled': {
                            '& fieldset': {
                              borderColor: style.color4,
                            },
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: style.color7,
                          fontSize: '16pt',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: style.color5,
                        },
                        '& .MuiInputLabel-root.Mui-disabled': {
                          color: style.color7,
                        },
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& input': {
                            color: style.color4,
                            WebkitTextFillColor: style.color4,
                            fontSize: '16pt'
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          color: 'white',
                          '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                            WebkitTextFillColor: style.color7, color: style.color7
                          },
                        },
                      }}
                    />
                  </div>
                </h4>
              </div>

              <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginBottom: '10px', width: '100%', backgroundColor:style.color2}}>
                <FormControl sx={{
                  backgroundColor: style.color10,
                  flex: 1,
                  width:'100%',
                  margin: 'auto',
                  '& .MuiInputBase-input': {
                    color: 'white',
                    fontSize: '16pt',
                    size: 'small',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: style.color4,
                      borderWidth: '2px'
                    },
                    '&:hover fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-disabled': {
                      '& fieldset': {
                        borderColor: style.color4,
                      },
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: style.color7,
                    fontSize: '14pt',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5,
                  },
                  '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color5,
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                      color: style.color5,
                      WebkitTextFillColor: style.color5,
                      fontSize: '18pt'
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'white',
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                      WebkitTextFillColor: style.color7, color: style.color7
                    },
                  },
                }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword1 ? 'text' : 'password'}
                    value={data.password}
                    size='small'
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ color: style.color4 }}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
  
                 </FormControl>

                    <FormHelperText sx={{ color: passwordHelper.color, backgroundColor:style.color2, fontSize: '12pt', width:'100%' }}>
                        {passwordHelper.message}
                  </FormHelperText>
              </div>

              <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginBottom: '20px', width: '100%', backgroundColor:style.color2  }}>
                <FormControl sx={{
                  backgroundColor: style.color10,
                  flex: 1,
                  width:'100%',
                  margin: 'auto',
                  '& .MuiInputBase-input': {
                    color: 'white',
                    fontSize: '16pt',
                    size: 'small',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: style.color4,
                      borderWidth: '2px'
                    },
                    '&:hover fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-disabled': {
                      '& fieldset': {
                        borderColor: style.color4,
                      },
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: style.color7,
                    fontSize: '14pt',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5,
                  },
                  '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color5,
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                      color: style.color5,
                      WebkitTextFillColor: style.color5,
                      fontSize: '18pt'
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'white',
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                      WebkitTextFillColor: style.color7, color: style.color7
                    },
                  },
                }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password Again</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword2 ? 'text' : 'password'}
                    value={data.password2}
                    size='small'
                    onChange={handleConfirmPasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ color: style.color4 }}
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password Again"
                  />
                </FormControl>
                <FormHelperText sx={{ color: confirmPasswordHelper.color, backgroundColor: style.colo9, fontSize: '12pt' }}>
                    {confirmPasswordHelper.message}
                  </FormHelperText>
              </div>


              
                <div style={{ marginTop: '50px', marginBottom: '25px', display: 'flex', flex: 1 }}>
                  <Button onClick={registerUser} sx={{ my: 2, color: style.color5, display: 'block', backgroundColor: style.color4, fontSize: '16pt', width: '100%', minWidth: '250px', margin: 'auto', marginTop: '0px', borderColor: style.color4, borderWidth: '2px', borderStyle: 'solid', '&:hover': { backgroundColor: style.color4 }, }}>Create Account</Button>
                </div>
              
              {/* {(data.password.length < 6 || data.password != data.password2) && (
                <div style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', flex: 1 }}>
                  <Button disabled onClick={registerUser} sx={{ my: 2, color: style.color5, display: 'block', backgroundColor: style.color3, fontSize: '16pt', width: '100%', minWidth: '250px', margin: 'auto', marginTop: '0px', borderColor: style.color4, borderWidth: '2px', borderStyle: 'solid', '&:hover': { backgroundColor: style.color4 }, }}>Create Account</Button>
                </div>
              )} */}

              <div style={{ marginBottom: '20px' }}>
                Already have an account?
                <Button onClick={navToLogin} sx={{ my: 2, color: style.color4, display: 'block', margin: 'auto', fontSize: '14pt' }}>
                  Login
                </Button>
              </div>
              <div style={{ flex: 1, borderBottomLeftRadius: '15px', color: style.color5, marginBottom: '25px' }}>
                By using this website, you agree to our
                <div>
                  <Button sx={{ color: style.color4, marginTop: '5px' }} onClick={setModalOpen.bind(null, true)}>terms and conditions</Button>
                </div>
              </div>

              <div id='modal-root'>
                <TermsModal isOpen={isModalOpen} close={closeModal}>
                  <Terms />
                </TermsModal>
              </div>
            </div>
          </div>
        </div>
        <div id='modal-root'></div>
      </div>
    </>
  );
}


// import {useState, select} from "react"
// import { Link } from "react-router-dom"
// import axios from 'axios';
// import {toast} from 'react-hot-toast';
// import {useNavigate} from 'react-router-dom'
// import { useStyle } from "../../context/style_context/styleContext";
// import ReactGA from 'react-ga4';

// import TermsModal from "../terms/TermsModal";
// import Terms from "../terms/Terms";
// import Button from '@mui/material/Button';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { Checkbox, TextField, FormCOntrol, FormHelperText, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel } from "@mui/material";





// export default function Register(){

//     const navigate = useNavigate();
//     const [isModalOpen, setModalOpen] = useState(false);
//     const openModal = () => setModalOpen(true);
//     const closeModal = () => setModalOpen(false);
//     const { style, setStyle } = useStyle();


//     const [showPassword1, setShowPassword1] = useState(false);
//     const [showPassword2, setShowPassword2] = useState(false);
//     const [showConfirm, setShowConfirm] = useState(false);

//     const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
//     const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

//     const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//     ReactGA.send({
//         hitType:"pageview",
//         page:"register",
//         title:"register",
//       })

//     const [data, setData] = useState({
//         email: '',
//         password: '',
//         password2: '',
//     })

//     const navToLogin = () => {
//         navigate('/login')
//       }


//     const registerUser = async (e) => {

//         e.preventDefault();
//         var okToSend = true;

//         const {email, password, password2} = data


//         if(!email)
//             {
//                 okToSend = false
//                 toast.error("Error: You must enter your E-Mail address to create an account")
//             }


//         if(!password)
//             {
//                 okToSend = false
//                 toast.error("Error: Password cannot be blank")
//             }
//         if(!password)
//             {
//                     okToSend = false
//                     toast.error("Error: Please enter your password again")
//             }



//         if(password != password2)
//         {
//             okToSend = false
//             toast.error("Passwords do not match")
//         }
        
        
     

//         if(okToSend)
//         {
//             try{
//                 okToSend = false   
//                 const{data} = await axios.post('/register', {email, password})
    
    
//             if(data.error){
//                 toast.error(data.error)
//             }
//             else{
//                 setData({})
//                 toast.success('Registration Successful. Welcome!')
//                 navigate('/home')
//             }
//             }catch(error){
//                 console.log(error)
//             }
//         }
//     }


//     return(
//         <>



// <div style={{display:'flex', flexDirection:'column', width:'100%'}}>

// <div style={{display:'flex', flexDirection:'column', width:'90%', maxWidth:'800px', margin:'auto', color:style.color5, textAlign:'center', marginTop:'40px', backgroundColor:style.color9, borderRadius:'10px', borderStyle:'solid', borderColor:style.color4}}>
//     <div style={{width:'90%', margin:'auto'}}>

    
//         <div style={{display:'flex', flex:1, textAlign:'center', marginTop:'20px'}}>
//             <h1 style={{flex:1, textAlign:'center', margin:'auto', marginBottom:'40px'}}>
//                 Create your account
//             </h1>
           
//         </div>
//         <div style={{maxWidth:'400px', margin:'auto'}}>
//         <div style={{display:'flex', flex:1, textAlign:'center', minWidth:'300px', margin:'auto', marginBottom:'30px'}}>
//             <h4 style={{flex:1, textAlign:'left', margin:'auto', color:style.color5}}>
//                 <div style={{marginBottom:'5px'}}>
//                 <TextField
//                 id="outlined-basic"
//                 label="Email"
//                 variant="outlined"
//                 value= {data.firstName}
//                 onChange={(e) => setData({...data, email: e.target.value})}
//                 size='small'
//                 fullWidth
//                 sx={{
//                     backgroundColor: style.color10,
//                     borderRadius:'4px',
//                     '& .MuiInputBase-input': {
//                     color: style.color5,  // Change the font color here
//                     fontSize: '16pt',  // Change the font size here
                                                    
//                     },
//                     '& .MuiOutlinedInput-root': {
//                     '& fieldset': {
//                         borderColor: style.color4, // Change the border color if needed
//                         borderWidth:'2px'
//                     },
//                     '&:hover fieldset': {
//                         borderColor: style.color4, // Change the border color on hover if needed\
//                         borderWidth:'3px',                                                               
                 
                        
//                     },
//                     '&.Mui-focused fieldset': {
//                         borderColor: style.color4, // Change the border color when focused if needed
//                         borderWidth:'3px'
                        
//                     },
//                     '&.Mui-disabled': {
//                         '& fieldset': {
//                         borderColor: style.color4, // Change the border color when disabled
//                         },
//                     },
//                     },
//                     '& .MuiInputLabel-root': {
//                     color: style.color7, // Change the label color here
//                     fontSize:'16pt',
//                     },
//                     '& .MuiInputLabel-root.Mui-focused': {
//                     color: style.color5, // Change the label color when focused here                                 
//                     },
//                     '& .MuiInputLabel-root.Mui-disabled': {
//                     color: style.color7, // Change the label color when disabled
//                     },
//                     '& .MuiInputBase-root.Mui-disabled': {
//                     '& input': {
//                         color: style.color4, // Change the input font color when disabled // so it works in safari
//                         WebkitTextFillColor: style.color4, // so it works on chrome
//                         fontSize:'16pt'
//                     },
//                     },
//                     '& .MuiOutlinedInput-input': { color: 'white',
//                     '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
//                         WebkitTextFillColor: style.color7, color:style.color7
//                      }, }, 
//                 }}
//                 />
                

//                 </div>
//                 </h4>
//         </div>

//         <div style={{display:'flex', flex:1, marginBottom:'30px', width:'100%', backgroundColor:'teal'}}>                        
//                           <FormControl sx={{
//                                 backgroundColor: style.color10,
//                                 flex:1,
//                                 margin:'auto',
//                                 '& .MuiInputBase-input': {
//                                 color: 'white',  // Change the font color here
//                                 fontSize: '16pt',  // Change the font size here
//                                 size:'small',
//                                 },
//                                 '& .MuiOutlinedInput-root': {
//                                 '& fieldset': {
//                                     borderColor: style.color4, // Change the border color if needed
//                                     borderWidth:'2px'
//                                 },
//                                 '&:hover fieldset': {
//                                     borderColor: style.color4, // Change the border color on hover if needed
//                                 },
//                                 '&.Mui-focused fieldset': {
//                                     borderColor: style.color4, // Change the border color when focused if needed
//                                 },
//                                 '&.Mui-disabled': {
//                                     '& fieldset': {
//                                     borderColor: style.color4, // Change the border color when disabled
//                                     },
//                                 },
//                                 },
//                                 '& .MuiInputLabel-root': {
//                                 color: style.color7, // Change the label color here
//                                 fontSize:'14pt',
//                                 },
//                                 '& .MuiInputLabel-root.Mui-focused': {
//                                 color: style.color5, // Change the label color when focused here
//                                 },
//                                 '& .MuiInputLabel-root.Mui-disabled': {
//                                 color: style.color5, // Change the label color when disabled
//                                 },
//                                 '& .MuiInputBase-root.Mui-disabled': {
//                                 '& input': {
//                                     color: style.color5, // Change the input font color when disabled // so it works in safari
//                                     WebkitTextFillColor: style.color5, // so it works on chrome
//                                     fontSize:'18pt'
//                                 },
//                                 },
//                                 '& .MuiOutlinedInput-input': { color: 'white',
//                                 '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
//                                 WebkitTextFillColor: style.color7, color:style.color7
//                      }, }, 
//                             }} variant="outlined">
//                             <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
//                             <OutlinedInput
//                               id="outlined-adornment-password"
//                               type={showPassword1 ? 'text' : 'password'}
//                               value= {data.password}
//                               onChange={(e) => setData({...data, password: e.target.value})}
//                               endAdornment={
//                                 <InputAdornment position="end">
//                                   <IconButton
//                                     aria-label="toggle password visibility"
//                                     onClick={handleClickShowPassword1}
//                                     onMouseDown={handleMouseDownPassword}
//                                     edge="end"
//                                     sx={{ color:style.color4}}
//                                   >
//                                     {showPassword1 ? <VisibilityOff /> : <Visibility />}
//                                   </IconButton>
//                                 </InputAdornment>
//                               }
//                               label="Password"
//                             />
//                           </FormControl>
//                         </div>

                        
       
//                         <div style={{display:'flex', flex:1, marginBottom:'30px', width:'100%', backgroundColor:'teal'}}>                        
//                           <FormControl sx={{
//                                 backgroundColor: style.color10,
//                                 flex:1,
//                                 margin:'auto',
//                                 '& .MuiInputBase-input': {
//                                 color: 'white',  // Change the font color here
//                                 fontSize: '16pt',  // Change the font size here
//                                 size:'small',
//                                 },
//                                 '& .MuiOutlinedInput-root': {
//                                 '& fieldset': {
//                                     borderColor: style.color4, // Change the border color if needed
//                                     borderWidth:'2px'
//                                 },
//                                 '&:hover fieldset': {
//                                     borderColor: style.color4, // Change the border color on hover if needed
//                                 },
//                                 '&.Mui-focused fieldset': {
//                                     borderColor: style.color4, // Change the border color when focused if needed
//                                 },
//                                 '&.Mui-disabled': {
//                                     '& fieldset': {
//                                     borderColor: style.color4, // Change the border color when disabled
//                                     },
//                                 },
//                                 },
//                                 '& .MuiInputLabel-root': {
//                                 color: style.color7, // Change the label color here
//                                 fontSize:'14pt',
//                                 },
//                                 '& .MuiInputLabel-root.Mui-focused': {
//                                 color: style.color5, // Change the label color when focused here
//                                 },
//                                 '& .MuiInputLabel-root.Mui-disabled': {
//                                 color: style.color5, // Change the label color when disabled
//                                 },
//                                 '& .MuiInputBase-root.Mui-disabled': {
//                                 '& input': {
//                                     color: style.color5, // Change the input font color when disabled // so it works in safari
//                                     WebkitTextFillColor: style.color5, // so it works on chrome
//                                     fontSize:'18pt'
//                                 },
//                                 },
//                                 '& .MuiOutlinedInput-input': { color: 'white',
//                                 '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
//                                 WebkitTextFillColor: style.color7, color:style.color7
//                      }, }, 
//                             }} variant="outlined">
//                             <InputLabel htmlFor="outlined-adornment-password">Password Again</InputLabel>
//                             <OutlinedInput
//                               id="outlined-adornment-password"
//                               type={showPassword2 ? 'text' : 'password'}
//                               value= {data.password2}
//                               onChange={(e) => setData({...data, password2: e.target.value})}
//                               endAdornment={
//                                 <InputAdornment position="end">
//                                   <IconButton
//                                     aria-label="toggle password visibility"
//                                     onClick={handleClickShowPassword2}
//                                     onMouseDown={handleMouseDownPassword}
//                                     edge="end"
//                                     sx={{ color:style.color4}}
//                                   >
//                                     {showPassword2 ? <VisibilityOff /> : <Visibility />}
//                                   </IconButton>
//                                 </InputAdornment>
//                               }
//                               label="Password"
//                             />
//                           </FormControl>
//                         </div>
        

//         {(data.email && data.password ) &&(
//         <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
//             <Button onClick={registerUser} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Create Account</Button>
//         </div>
//         )}
//         {(!data.email || !data.password ) &&(
//         <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
//             <Button disabled onClick={registerUser} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color3, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Create Account</Button>
//         </div>
//         )} 

//         <div style={{marginBottom:'50px'}}>
//             Already have an account? 

//             <Button onClick={navToLogin} sx={{ my: 2, color: style.color4, display: 'block', margin:'auto', fontSize:'14pt'}}>
//                 Login
//             </Button>


//             </div>
//             <div style={{ flex:1, borderBottomLeftRadius:'15px', color:style.color5, marginBottom:'25px'}}>                 
//                     By using this website, you agree to our 
//                     <div>
//                         <Button sx={{color:style.color4, marginTop:'5px'}}onClick={setModalOpen.bind(null, true)}>terms and conditions</Button>
//                     </div>
//                     </div>

//                     <div id='modal-root'>
//                     <TermsModal isOpen={isModalOpen} close={closeModal}>
//                         <Terms />
//                     </TermsModal>
//                     </div>
            
//                 </div>                




//     </div>

//     </div>

// </div>
// <div id='modal-root'>
// </div>



//          </>         

//     )
// }

