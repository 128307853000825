import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as React from 'react';
import { Link, useProps } from "react-router-dom"
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import {useState, useEffect} from "react"
import { useParams } from 'react-router-dom';
import {toast} from 'react-hot-toast';

function createData(SKU, name, price, cost, profit, history) {

  return {
    SKU,
    name,
    price,
    cost,
    profit,

    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function formatAsDollar(inputNumber)
{
    const formattedNumber = inputNumber.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    return(formattedNumber)
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.SKU}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.price}</TableCell>
        <TableCell align="right">{row.replacementCost}</TableCell>
        <TableCell align="right">{formatAsDollar(Math.round((row.price - row.replacementCost)* 100) / 100)}</TableCell>
        <TableCell align="right">{row.qtyOnHand}</TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Datez</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                {console.log("VENDORS FROM HTML: ", row.vendors)}
                <TableBody>
                  {/* {row.vendors.map((vendorRow) => (
                    <TableRow key={vendorRow}>
                      <TableCell component="th" scope="row">
                        {vendorRow}
                      </TableCell>
                      <TableCell>{vendorRow.name}</TableCell>
                      <TableCell align="right">{vendorRow.name}</TableCell>
                      <TableCell align="right">
                        {vendorRow.name}
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



Row.propTypes = {
  row: PropTypes.shape({
    SKU: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    avgCost: PropTypes.number,
    qtyOnHand: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    manufacturer: PropTypes.string,

    vendors: PropTypes.arrayOf(
      PropTypes.shape({        
        name: PropTypes.string,
      }),
    ).isRequired,

  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];



export default function ProductsTable(props) {


    const products = props.products.product;
    console.log("type: ", typeof(products))
    const rows = [];


    for(var i = 0; i < props.products.length; i++){
        const product = props.products[i]
        console.log("PRODUCT: ", i)
        console.log(product)
                
        const formattedNumber = product.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

        rows.push(product)
        console.log("")


    }
    // products.forEach(product => {
    //     console.log(product);
    //     //createRow(product);
    // });

   console.log('*******************') 
    console.log('products: ', props.products)
    console.log("type: ", typeof(products))
    console.log("size", props.products.length)
    console.log('products: ', props.products[0])
    console.log('products: ', props.products[1])
   console.log('*******************') 


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>SKU</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Cost</TableCell>
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">Inventory</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}