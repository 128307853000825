

// CheckoutPage.js
import React, { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/StripeCheckoutForm/StripeCheckoutForm';
import { useStyle } from '../../context/style_context/styleContext';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import {useNavigate, Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import OrderConfirmationModal from './ConfirmaionModal';
import StripeLogo from '../../icons/stripeLogo.svg';



// Make sure to replace this with your own publishable key from Stripe Dashboard
//test key
//const stripePromise = loadStripe('pk_test_51PnT4PAo6Xubpx1wGhb0K7NG1NYRfuoxMwBy52NucgxJG5n0bioR9AmTCzOiE0Rfy0m18B9biE0MB9Zm97OEruKi00J9OuEF8h');
//live key
const stripePromise = loadStripe('pk_live_51PnT4PAo6Xubpx1wNTwkfYwcO1eFp8Yb4yeCy59v3NkRneYNfCx1f1SyI9Pdrf5AzpO2rWxyAuF5AS5I6Q55OTrK00Eunny8fZ');



const CheckoutPage = () => {

const { cartItems, cartSubtotal } = useContext(ShoppingCartContext);
const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
const openConfirmationModal = () => setConfirmationModalOpen(true);
const closeConfirmationModal = () => setConfirmationModalOpen(false);

console.log(cartItems)


  const { style } = useStyle();

  return (
    <>
    <div className="checkout" style={{textAlign:'center', minHeight:'68vh'}}>
      <h1>Complete your purchase</h1>
      
      <div style={{backgroundColor:style.color2, width:"95%", maxWidth:'600px', margin:'auto', borderRadius:'10px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid'}}>
      <img src={StripeLogo} style={{width:'150px', marginTop:'10px', marginTop:'10px'}}/>
      <h1 style={{marginTop:'35px', marginBottom:'35px'}}>
        Your Total: $ {parseFloat(cartSubtotal).toFixed(2)}
      </h1>

      <Elements stripe={stripePromise}>
        <CheckoutForm openConfermationModal={setConfirmationModalOpen}/>
      </Elements>

      </div>
    
      
    </div>
    <div id='modal-root'>
    <OrderConfirmationModal isOpen={isConfirmationModalOpen} close={closeConfirmationModal}>
        
    </OrderConfirmationModal>
    </div>
    </>
  );
};

export default CheckoutPage;