import {React, useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import { useStyle } from "../../context/style_context/styleContext";
import{UserContext} from '../../context/userContext'
import Button from '@mui/material/Button';
import {useNavigate, Link} from 'react-router-dom';
import { Checkbox, TextField, FormGroup, FormControlLabel } from "@mui/material";




const SubscribeRedirectModal = ({ isOpen, close, children }) => {

    const { style, setStyle } = useStyle();
    const navigate = useNavigate()
    const { user, userEmail, userFirstName, userLastName } = useContext(UserContext);
    const [checkBoxChecked, setCheckBoxChecked] = useState(false)
  
    if (!isOpen) return null;

    const closeFunction = async (e) => { 
                setCheckBoxChecked(false)           
                close() 

    }

    const gotIt = () => {
      
      if(checkBoxChecked)
      {
        console.log("unchecked")
        setCheckBoxChecked(false)
      }
      else{
        console.log("checked")
        setCheckBoxChecked(true)
      }

    }

    const navToStripe = () => {
      //window.location.href = `https://buy.stripe.com/test_fZe2bz1OL84P2VGcMP?prefilled_email=${user.email}`
      window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
      
    }


        

  return ReactDOM.createPortal(


    
    <div style={modalStyle}>
      <div style={{padding: '20px',
            borderRadius: '15px',
            maxWidth: '800px',
            minHeight: '300px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            borderColor:'#535bf2',
            borderWidth:'2px',
            borderStyle:'solid',
            backgroundColor: style.color1,}}>
        <div style={{width:'90%', margin:'auto'}}>
            
                    <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'95%', margin:'auto'}}>
                        
                          <>
                          <div style={{marginBottom:'10px', marginTop:'0px'}}>
                            <h1 style={{marginBottom:'30px'}}>
                              We partner with Stripe to simplify subscriptions.
                            </h1>
                            <h3>Important! Please make sure you are using THIS Email: </h3><h2><strong>{userEmail}</strong></h2>
                                <p>This allows our system to identify your subscription and issue your product licenses</p>
                                <p>Once you subscribe, your products will be avaialble for download on your <a href='/my-gear' style={{color:style.color4}}> My Gear </a> page</p>
                                
                            </div>
                            <div style={{margin:'auto', marginBottom:'10px'}}>
                            <FormGroup>
                              <FormControlLabel checked={checkBoxChecked} onChange={gotIt} control={<Checkbox size='large' sx={{color:style.color4, '&.Mui-checked': {
                                  color: style.color4,
                              },}} />} label="Got it!" />
                          </FormGroup>
                            </div>
                            {(checkBoxChecked) &&(
                            <div style={{marginTop:'50px', display:'flex', flex:1}}>
                              <Button onClick={navToStripe} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Proceed To Stripe</Button>
                            </div>
                            )}
                            {(!checkBoxChecked) &&(
                            <div style={{marginTop:'50px', display:'flex', flex:1}}>
                              <Button disabled onClick={navToStripe} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Proceed To Stripe</Button>
                            </div>
                            )} 
                            <div style={{ marginTop:'10px'}}>
                            <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color2, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Go Back</Button>
                            </div>


                           

                              </>
                            

                    </div>
           
            
        </div>
      </div>

    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};


const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default SubscribeRedirectModal;
