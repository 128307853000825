import { Outlet, Navigate } from "react-router-dom";

import {useContext} from "react";
import {UserContext} from '../context/userContext';
import {useNavigate} from 'react-router-dom'


const PrivateRoutes = () => {

  const{user, isLoading} = useContext(UserContext);
  const navigate = useNavigate()

  if(isLoading) {
    return <div>Loading...</div>
  }
  else{   

    if(!user)
    {    
      //navigate to login page
      return <Navigate to="/login" />;
    }
    else{
      const email = user.email
      //console.log("Check passed, USER FOUND! user: " + email)
      return(<Outlet />)
    }

}

};

export default PrivateRoutes;