import {React, useContext, useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import{UserContext} from '../../context/userContext'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import axios from 'axios';
import {toast} from 'react-hot-toast';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useStyle } from '../../context/style_context/styleContext';
import {useNavigate, Link} from 'react-router-dom';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import Rating from '@mui/material/Rating';
import LabelSharpIcon from '@mui/icons-material/LabelSharp';
import SubscribeRedirectModal from '../SubscribeRedirectModal/SubscribeRedirectModal';
import LoginSubscribePromptModal from '../LoginSubscribePromptModal/LoginSubscribePromptModal';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';




export default function ProductCard({productSKU}) {
  const theme = useTheme();
  const { style } = useStyle();

  const { user, userEmail, userFirstName, userLastName } = useContext(UserContext);
  const { addToCart, removeFromCart, cartItems } = useContext(ShoppingCartContext);
  const [value, setValue] = useState();
  const [productData, setProductData] = useState();


  const [isSubscribeRedirectModalOpen, setSubscribeRedirectModalOpen] = useState(false);
  const openSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(true);
  const closeSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(false);
  
  
  const [isLoginRegisterModalOpen, setLoginRegisterModalOpen] = useState(false);
  const openLoginRegisterModal = () => setLoginRegisterModalOpen(true);
  const closeLoginRegisterModal = () => setLoginRegisterModalOpen(false);



  const loadData = async (e) => {

    console.log("DATA LOADING: ", productSKU)

      const{data} = await axios.post('/api/get_product', {productSKU})
      if(data)
      {
     
          setProductData(data)
      }
      else{
          console.log('data error')
      }
    


    

  }

  const getProductPageInfo = async (e) => {


    // try{
    //   const{data} = await axios.post('/api/get_product_page_info', {productURI})
    //   if(data)
    //   {
    //       //console.log(data)
    //       setProductData(data)
    //   }
    //   else{
    //       console.log('data error')
    //   }
    // }
    // catch
    // {
    //   console.log('Error retrieving data...')
    // }
    
}

  useEffect(() => {
    loadData();
  }, []);

const addProductToCart = async (e) => {

    e.preventDefault();
    console.log("Sending request to cart componant..")

    addToCart(productSKU)
    toast.success('Product added to cart')


}

const navToSubscribePage = async (e) =>
  {
    if(user)
    {
      window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
    }
    else
    {
      openLoginRegisterModal()
    }
    
  }


  return (

<>
{!productData &&("Loading....")}
{productData &&(

<div style={{display:'flex', flex:1, flexDirection:'column', width:'300px', backgroundColor:'black', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px', padding:'30px'}}>

<a href={"product/"+ productData.productURI}>
  <div style={{display:'flex', flexDirection:'column', maxHeight:'300px', minHeight:'300px', backgroundColor:'rgb(25,31,34)', borderRadius:'10px', borderWidth:'0px', borderColor:style.color4, borderStyle:'solid'}}>

  <img src={productData.imageURIs[0]} style={{display:'flex', width:'100%', maxWidth:'150px', margin:'auto', marginTop:'20px', marginBottom:'5px'}}></img>


  </div>
  {productData.cardCallout == 'new' && (
      <div style={{backgroundColor:style.cardCalloutGreen, marginTop:'-13px', color:'white', width:'50%', minWidth:'60px', textAlign:"center", padding:'5px', borderRadius:'5px', borderColor:'rgb(7, 200, 2)', borderWidth:'0px', borderStyle:'solid'}}>
          <strong>NEW</strong>
      </div>
    )}

  {productData.cardCallout == 'bestSeller' && (
      <div style={{backgroundColor:style.cardCalloutBlue, marginTop:'-13px', color:'white', width:'50%', minWidth:'60px', textAlign:"center", padding:'5px', borderRadius:'5px', borderColor:'rgb(7, 99, 255)', borderWidth:'0px', borderStyle:'solid'}}>
          <strong>BEST SELLER</strong>
      </div>
    )}
  </a>
  <div style={{display:'flex', flex:1, margin:'auto', marginTop:'10px', textAlign:'center'}}>
  <a href={"product/"+ productData.productURI} style={{margin:'auto', color:style.color5}}>
    <h2 style={{fontSize:'1.25em', textAlign:'left'}}>
      <strong>
        {productData.name}
      </strong>
    </h2>
    </a>
  </div>
  <div style={{fontSize:'.8em'}}>
    {productData.shortDescription}
  </div>
  <div style={{display:'flex', flexDirection:'row', marginTop:'25px'}}>
 
    <a href={"reviews/"+ productData.productURI} style={{color:style.color7}}>
    <div style={{display:'flex', flexDirection:'row'}}>
      <div>
        <Rating name="read-only" precision={0.25} value={productData.starRating} readOnly icon={<StarIcon sx={{ stroke: style.color8, strokeWidth: 1 }} />}
                                                                                 emptyIcon={<StarBorderIcon sx={{ stroke: style.color8 }} />}/>
      </div>
      <div style={{marginLeft:'5px'}}>
        ({productData.ratingCount} reviews)
      </div>
      </div>
      </a>

  </div>
  <div>

  <div style={{width:'300px', height:'60px', marginTop:'20px'}}>
                {productData.audioURI &&(
                  <audio controls style={{width:'300px'}}>  
                  <source src={productData.audioURI} type="audio/mp3" size='small'  />              
                  </audio>

                )}

              </div>
  </div>
  <div style={{display:'flex', flexDirection:'column', marginTop:'25px'}}>
    <div>
      <h3 style={{marginTop:'0px', marginBottom:'0px'}}>
      <strike>${productData.msrp}</strike>
      </h3>

    </div>

    <div style={{display:'flex', flexDirection:'row', marginTop:'0px'}}>
        <div style={{display:'flex', flex:1, color:'white', marginTop:'0px', marginBottom:'0px'}}><span style={{marginTop:'0px', marginBottom:'0px', fontSize:'1.6em'}}><strong>${productData.price}</strong></span>
        <span style={{marginTop:'0px', marginBottom:'0px', color:'red', fontSize:'1.6em', marginLeft:'10px'}}>Save {(Math.round((1000*(productData.msrp-productData.price)/productData.msrp))/10)} %</span></div>

    </div>
    <div style={{ marginTop:'10px'}}>
    <Button onClick={addProductToCart} sx={{fontSize:'1.3em'}}>Add to Cart</Button>
    </div>
    <div style={{fontSize:'.8em', marginTop:'10px'}}>
      Included with subscription
    </div>
    <div style={{}}>
      <Button onClick={navToSubscribePage} sx={{fontSize:'1.3em'}}>Subscribe</Button>
    </div>
    {productSKU == '10003' &&(<div>
      <a href='/dev-notes/mk1-overdrive' style={{color:style.color4}}>Developer Notes</a>
    </div>)}
    

  </div>



  </div>

)}

  <div id='modal-root'>
          <SubscribeRedirectModal isOpen={isSubscribeRedirectModalOpen} close={closeSubscribeRedirectModal} />
          <LoginSubscribePromptModal isOpen={isLoginRegisterModalOpen} close={closeLoginRegisterModal} />
        </div>
</>



    // <Card sx={{ display: 'flex', maxWidth:'1500px', backgroundColor:'rgba(0,0,0,0.7)', color:'white', margin:'auto', marginTop:'5px', marginBottom:'5px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'10px'}}>
    //   <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    //     <CardContent sx={{ flex: '1 0 auto' , color:'white'}}>
    //       <a href={productLink}>
    //       <Typography component="div" variant="h3" sx={{textAlign:'center', color:'white', marginTop:'10px', marginBottom:'30px'}}>
    //         {productName}
    //       </Typography>
    //       </a>
    //       <p style={{fontSize:'12pt'}}>
    //         {productDescription}
    //       </p>
    //       <p style={{fontSize:'12pt', marginTop:'30px'}}>
    //         {productDescription2}
    //       </p>
    //     </CardContent>
    //     <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, margin:'auto', color:'white'}}>
    //     <Typography variant="subtitle1" sx={{color:'white', marginRight:'25px'}} component="div">
    //       </Typography>
    //       <div>
    //       <div>
    //       <audio controls style={{marginTop:'15px'}}>
    //         <source src="ReverbSample.wav" type="audio/wav" />                    
    //     </audio> 

    //       </div>

    //     <div>
          
    //       <div>
    //       <h3 style={{marginBottom:'5px'}}>
    //         <strike>$99.95</strike>
    //         <span style={{color:'red', marginLeft:'20px', fontStyle:'italic'}}>$49.95</span> 
    //         </h3>


    //         <Button onClick={addProductToCart}>Add to Cart</Button>
    //         </div>
          
          
    //       </div>  

    //       </div>
                 
    //     </Box>
    //   </Box>
    //   <div>
    //     <img src={productImageURI} alt={productImageAltText}></img>
    //     </div>
    //   {/* <CardMedia
    //     component="img"
    //     image={imageSource}
    //     alt={imageAltText}
    //     sx={{ marginLeft:'25px', borderColor:style.color4, borderStyle:'solid', borderWidth:'5px' }}
    //   /> */}
    // </Card>
  );
}