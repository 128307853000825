import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import {useNavigate, Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import axios from 'axios'
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import AdbIcon from '@mui/icons-material/Adb';
import { UserContext } from '../../context/userContext';
import { StyleContext, useStyle } from '../../context/style_context/styleContext';
import SoundwaveIcon from '../../icons/SoundwaveIcon.svg'
import CartIcon from '../../icons/shopping_cart3.svg'
import LogoIcon from '../../icons/LogoIcon.svg'
import { IoMenu } from "react-icons/io5";
import Cookies from 'js-cookie';
import ProfilePicIcon from '../../icons/Default_Profile_Pic.svg'
import AdminDashboard from '../../pages/adminDashboard/AdminDashboard';
import LogoutModal from '../../pages/user/LogoutModal';
import { ShoppingCartContext } from '../../context/shoppingCartContext';



const pages = ['Plugins', 'Support', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


export default function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate()
  const { style, setStyle } = useStyle();
  const { user, setUser, updateFlag, isLoading, userEmail } = useContext(UserContext);
  const { isAdmin, setIsAdmin } = useState(false);
  const [width, setWidth] = useState({width: window.innerWidth})

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const openLogoutModal = () => setLogoutModalOpen(true);
  const closeLogoutModal = () => setLogoutModalOpen(false);

  const { cartItems, cartSubtotal } = useContext(ShoppingCartContext);

  const [isSubRedirectModalOpen, setSubRedirectModalOpen] = useState(false);
  const openSubRedirectModal = () => setSubRedirectModalOpen(true);
  const closeSubRedirectModal = () => setSubRedirectModalOpen(false);
  
  useEffect(() => {
    function handleResize() {      
      setWidth(window.innerWidth)     
    
}

    window.addEventListener('resize', handleResize)
  })
   

  const widthThing = window.innerWidth;

  

  //console.log("cartItems from navbar: ", cartItems)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navToDashboard = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/')
  }

  const navToProducts = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/products')
  }

  const navToSupport = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/support')
  }

  const navToBlog = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/blog')
  }

  const navToAccount = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/account')
  }
  const navToGear = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/my-gear')
  }

  const navToRegister = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/register')
  }

  const navToLogin = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/login')
  }

  const navToSubscriptions = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    window.location.href = "https://billing.stripe.com/p/login/cN29DI5UIdTh4006oo"
    
  }

  const navToAdminDashboard = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
    navigate('/admin')
  }

  const logout = async () => {
    try {

        //const response = await axios.get('/logout', { withCredentials: true });
        //console.log(response.data.message);  // Log the server response message
        setUser(null);  // Clear user context
        Cookies.remove('token');
        setAnchorElUser(null);
        openLogoutModal();
        

    } catch (error) {
        console.error('Logout failed:', error);
    }
};

  // const handleCloseNavMenu = async (event) => {
  //   //console.log(page)
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (<>
  <div style={{backgroundColor:'black', maxWidth:'100vw'}}>
    <AppBar position="static" sx={{backgroundColor:'black', width:'95%,', maxWidth:'1000px', margin:'auto'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{color:style.color4, margin:'auto', justifyContent:'center',  flex:1 }}>

          {/* LOGO BIG */}
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, backgroundColor:'fuchsia'}} /> */}

          <div style={{display:'flex', flexDirection:'row', flex:'1', maxWidth:'1000px', margin:'auto'}}>

            <Link to='/' className= 'headerLinkText' style={{color:style.color4, fontWeight:'bold', flex:3}}>
          <div style={{display:'flex', flexDirection:'row', margin:'auto'}}>
          
            <div style={{display:'flex', flex:1, maxWidth:'50px', textAlign:'left'}}>
              <img src={LogoIcon} style={{flex:1 , minWidth:'50px', height:'50px', maxWidth:'100%', maxHeight:'100%', margin:'auto', paddingTop:'10px'}} />
            </div>






            {widthThing > 600 && (
            <div style={{flex:3, margin:'auto'}}>
            <h1 style={{marginBottom:'0px', marginTop:'0px', fontSize:'20pt', textAlign:'left', paddingTop:'10px', marginLeft:'3px'}}>
                Brainwave Sound Lab
            </h1>
            
            </div>
            )}
            {widthThing <= 600 && (
              <div style={{flex:3, margin:'auto'}}>
              <h1 style={{marginBottom:'0px', marginTop:'0px', fontSize:'18pt', textAlign:'left', paddingTop:'10px', marginLeft:'5px'}}>
                  BSL
              </h1>
              
            </div>
            )}



            

            </div>
            </Link>

            {widthThing > 600 &&(

            <div style={{alignContent:'center', flex:1, width:'100%'}}>
              <Link to='/products' className= 'headerLinkText' style={{color:style.color4, flex:1, height:'100%', fontSize:'1em', fontWeight:'bold', margin:'auto', marginRight:'10px', }}> Products </Link>

            </div>

            )}

            <div style={{alignContent:'center', flex:1, width:'100%', textAlign:'center'}}>
            <Link to='/my-gear' className= 'headerLinkText' style={{color:style.color4, flex:1, minWidth:'100px', height:'100%', fontSize:'1em', fontWeight:'bold', margin:'auto', marginRight:'10px', textAlign:'center'}}>My Gear</Link>

            </div>
            <Box sx={{ flex:1, height:'100%', flexGrow: 0, flex:2, maxWidth:'60px', margin:'auto', textAlign:'center', textAlign:'center', marginBottom:'0px', marginRight:'25px'}}>
            <Tooltip title="Navigation">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              
            >
              <IoMenu 
              style={{ color: style.color4, fontSize: "1.8em", marginBottom:'0px', margin:'auto', marginRight:'5px', paddingTop:'0px'}}/>
            </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >

                {!user && (
                  <div style={{ paddingTop:'0px'}}>
                     <MenuItem onClick={navToDashboard} sx={{marginTop:'0px'}}>
                      <Typography textAlign="center">Dashboard</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToProducts}>
                      <Typography textAlign="center">Plugins</Typography>
                    </MenuItem>
                      <MenuItem onClick={navToBlog}>
                    <Typography textAlign="center">Blog</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToSupport}>
                      <Typography textAlign="center">Support</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToRegister}>
                      <Typography textAlign="center">Create Account</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToLogin}>
                      <Typography textAlign="center">Login</Typography>
                    </MenuItem>
                  </div >
                )}
                {user && (
                  <div style={{}}>
                    <MenuItem onClick={navToDashboard}>
                      <Typography textAlign="center">Dashboard</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToGear}>
                    <Typography textAlign="center">My Gear</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToProducts}>
                      <Typography textAlign="center">Shop All Products</Typography>
                    </MenuItem>
                      {/* <MenuItem onClick={navToBlog}>
                    <Typography textAlign="center">Blog</Typography>
                    </MenuItem> */}
                    <MenuItem onClick={navToSubscriptions}>
                      <Typography textAlign="center">Manage Subscriptions</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToSupport}>
                      <Typography textAlign="center">Support</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToAccount}>
                      <Typography textAlign="center">Account</Typography>
                    </MenuItem>                      
                    <MenuItem onClick={logout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </div >
                )}



            </Menu>
          </Box>
          {user && widthThing > 600 &&(<>
          
          
            <Box sx={{ flexGrow: 0 , flex:1, margin:'auto', textAlign:'center', marginRight:'10px', maxWidth:'60px'}}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" sx={{color:style.color4, backgroundColor:style.color1, borderWidth:'2px', borderColor:style.color4, borderStyle:"solid"}}/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

                {!user && (
                  <div>
                    <MenuItem onClick={navToRegister}>
                      <Typography textAlign="center">Create Account</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToLogin}>
                      <Typography textAlign="center">Login</Typography>
                    </MenuItem>
                  </div >
                )}
                {user && (
                  <div >
                    <MenuItem onClick={navToDashboard}>
                      <Typography textAlign="center">Dashboard</Typography>
                    </MenuItem>
                    <MenuItem onClick={navToAccount}>
                      <Typography textAlign="center">Account</Typography>
                    </MenuItem>
                      <MenuItem onClick={navToGear}>
                    <Typography textAlign="center">My Gear</Typography>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </div >
                )}

                

            </Menu>
          </Box>
          
          
          </>)}
          

          <div style={{display:'flex' , flex:1, margin:'auto', maxWidth:'60px'}}>
          <Link to='/shopping-cart' className= 'headerLinkText' style={{color:style.color4, fontWeight:'bold', margin:'auto'}}>
          <Badge badgeContent={cartItems.length} color="primary" overlap="circular">
            <img src={CartIcon} style={{flex:1, height:'50px', maxWidth:'100%', maxHeight:'100%', margin:'auto', marginLeft:'10px', marginTop:'10px'}} />

          </Badge>
          </Link>
            </div>
          </div>
          
          
          
        </Toolbar>
      </Container>
    </AppBar>
    </div>
    <div id='modal-root'>
            <LogoutModal isOpen={isLogoutModalOpen} close={closeLogoutModal}>
                
            </LogoutModal>

            </div>

    </>
  );
}

