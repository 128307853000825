
import {React, useState, useContext, useEffect} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductCardVertical from "../../components/ProductCard/ProductCardVertical";


export default function Plugins(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

    ReactGA.send({
      hitType:"pageview",
      page:"plugins",
      title:"plugins",
    })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | State Of The Art Audio Processors | All Plugins';
  }, []);

  const changeBackground = () => {
    setStyle({ ...style, backgroundColor: 'rgb(0,255,0)' });
  };

    return(
        <>


        
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center'}}>
          <div>
                <h1>
                    All Plugins
                </h1> 
                <h2>
                  Guitar Stompboxes:
                  </h2>      
            </div>

            <div style={{display:'flex', flexDirection:'column', textAlign:'center', maxWidth:'1000px', margin:'auto', width:'95%'}}>
              <ProductCard productName="Infinity Reverb" 
                productSKU = "10001"
                productVersion = "1.00.0"
                productDescription="The Infinity Reverb is a world class reverb that is custom tailored specifically for guitar players. Designed to balance the natural harmonics and reflections of your guitar, the Infinity Reverb produces a full body soundscape that you can fine tune to get your perfect tone." 
                productDescription2="This reverb can be used by itself to create a rich shimmering clean tone, or use it as part of your overall signal chain to add complexity and ambiance to your sound. Currently available for Windows, this download includes a standalone application that can be used by itself, as well as a VST3 plugin that can be used with your DAW, such as Pro Tools, Reaper, Cubase, FL Studio, and many more."
                productImageURI="InfinityReverb.png"
                productLink="/Infinity_Reverb"
                productImageAltText="Infinity Reverb stompbox with glowing blue LED indicator"
              />
            </div>

            <div style={{display:'flex', flexDirection:'column', textAlign:'center', maxWidth:'1000px', margin:'auto', width:'95%'}}>
              <ProductCard productName="MK1 Reverb" 
                productSKU = "10002"
                productVersion = "1.00.0"
                productDescription="The Classic Digital Reverb provides lush, shimmering reverb that is great to combine with a clean tone to add body and warmth. With a crunchy or high gain distortion tone, you will get increased sustain and smoothness. Reverb controls for this pedal include total room size, room width, and damping. You can also dial the output with wet and dry, allowing you to get just the right levels for your mix." 
                productDescription2=""
                productImageURI="MK1Reverb.png"
                productLink="/MK1_Reverb"
                productImageAltText="MK1 Reverb Pedal"
              />
            </div>

            <div style={{display:'flex', flexDirection:'column', textAlign:'center', maxWidth:'1000px', margin:'auto', width:'95%'}}>
              <ProductCard productName="MK1 Overdrive" 
                productSKU = "10003"
                productVersion = "1.00.0"
                productDescription="The MK1 Overdrive is inspired by the classic overdrive pedals that we all know and love. The MK1 adds rich, tubelike sustain to your tone. A critical part of any guitar rig, you can use this pedal by itself, or use it as part of your effects chain to drive your amp." 
                productDescription2=""
                productImageURI="MK1Overdrive.png"
                productLink="/MK1_Overdrive"
                productImageAltText="MK1 Overdrive Pedal"
              />
            </div>

            <div style={{display:'flex', flexDirection:'row', margin:'auto', width:'90%', textAlign:'center'}}>
          <div style={{flex:1, textAlign:'center', color:style.color5, borderColor:style.color4, margin:'20px', borderWidth:'2px', borderStyle:'solid', padding:'20px',backgroundColor:style.color1, borderRadius:'8px'}}>
            <div style={{backgroundColor:style.color1}}>

            
            <h2>Coming Soon</h2>
            <h3>MK1 Overdrive</h3>
            </div>
            <div style={{backgroundColor:style.color1, marginBottom:'25px'}}>
            <img src='MK1Overdrive.png' style={{width:'75%', maxWidth:'300px'}}></img>
            </div>
            <p style={{maxWidth:'500px', margin:'auto'}}>The MK1 Overdrive is inspired by the classic overdrive pedals that we all know and love. The MK1 adds rich, tubelike sustain to your tone. A critical part of any guitar rig, you can use this pedal by itself, or use it as part of your effects chain to drive your amp.  
            </p>
          </div>

          <div style={{flex:1, textAlign:'center', color:style.color5, borderColor:style.color4, margin:'20px', borderWidth:'2px', borderStyle:'solid',backgroundColor:style.color1, borderRadius:'8px', padding:'10px'}}>
            <div style={{backgroundColor:style.color1}}>

            
            <h2>Coming Soon</h2>
            <h3>Classic Digital Reverb</h3>
            </div>
            <div style={{backgroundColor:style.color1, marginBottom:'25px'}}>
            <img src='MK1Reverb.png' style={{width:'75%', maxWidth:'300px'}}></img>
            </div>
            <p style={{maxWidth:'500px', margin:'auto'}}> The Classic Digital Reverb provides lush, shimmering reverb that is great to combine with a clean tone to add body and warmth. With a crunchy or high gain distortion tone, you will get increased sustain and smoothness. Reverb controls for this pedal include total room size, room width, and damping. You can also dial the output with wet and dry, allowing you to get just the right levels for your mix. 
            </p>
          </div>
          </div>

          </div>
        </>

    )
}