


import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductCardVertical from "../../components/ProductCard/ProductCardVertical";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SubscribeRedirectModal from "../../components/SubscribeRedirectModal/SubscribeRedirectModal";
import LoginSubscribePromptModal from "../../components/LoginSubscribePromptModal/LoginSubscribePromptModal";


export default function Products(){

  const { user, userEmail } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();
  
  const [width, setWidth] = useState(window.innerWidth)

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

  const [isSubscribeRedirectModalOpen, setSubscribeRedirectModalOpen] = useState(false);
  const openSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(true);
  const closeSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(false);
  
  
  const [isLoginRegisterModalOpen, setLoginRegisterModalOpen] = useState(false);
  const openLoginRegisterModal = () => setLoginRegisterModalOpen(true);
  const closeLoginRegisterModal = () => setLoginRegisterModalOpen(false);
  

  const topContainer = useRef();
  useEffect(() => {
      // To make sure page starts from the top
      topContainer.current.scrollIntoView({ block: "end" });
  }, []);

    ReactGA.send({
      hitType:"pageview",
      page:"products",
      title:"products",
    })

    
    useEffect(() => {
        function handleResize() {      
          setWidth(window.innerWidth)
      }      
        window.addEventListener('resize', handleResize)
      })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | State Of The Art Audio Processors | All Products';
  }, []);

  const subscribeButtonClick = () => {

    if(user)
    {
      window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
    }
    else
    {
      openLoginRegisterModal()
    }
  
  };

    return(
        <>


        
<div ref={topContainer}></div>

{isMobile && (<>
  <div style={{display:'flex', flexDirection:'column', width:'95%%', margin:'auto'}}>

<div>
  <div style={{display:'flex', flexDirection:'column', margin:'auto', width:'95%'}}>
<div style={{display:'flex', flexDirection:'column', margin:'auto'}}>
  <div style={{textAlign:'center'}}>
    <h1>
      All Products
    </h1>
  </div>
  <div style={{marginBottom:'25px', margin:'auto'}}>
<Card sx={{ maxWidth: 320, backgroundColor:style.color2, color:style.color7, borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px', marginBottom:'25px'}}>
          {/* <CardMedia
            sx={{ height: 140 }}
            image="InfinityReverb.png"
            title="green iguana"
          /> */}
          <CardContent >
            <div style={{ textAlign:'center', padding:'10px', backgroundColor:'rgb(25,31,34)', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
              <a href={'/product/mk1-overdrive'}>
              <img src={'/MK1Overdrive.png'} style={{width:'150px', margin:'auto', alt:'MK1 Overdrive pedal'}} />
              </a>
            </div>
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            <a href={'/product/MK1Overdrive'} style={{color:'white'}}>
              MK1 Overdrive
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
            The MK1 Overdrive is a classic overdrive pedal...
            </Typography>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Share</Button> */}
            <a href={'/product/mk1-overdrive'} style={{color:style.color4}}>LEARN MORE</a>
          </CardActions>
        </Card>

</div>
<div style={{marginBottom:'25px', margin:'auto'}}>
<Card sx={{ maxWidth: 320, backgroundColor:style.color2, color:style.color7, borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px', marginBottom:'25px'}}>
          {/* <CardMedia
            sx={{ height: 140 }}
            image="InfinityReverb.png"
            title="green iguana"
          /> */}
          <CardContent >
            <div style={{ textAlign:'center', padding:'10px', backgroundColor:'rgb(25,31,34)', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
              <a href={'/product/instagate'}>
              <img src={'/InstaGate.png'} style={{width:'150px', margin:'auto', alt:'Instagate Noisegate'}} />
              </a>
            </div>
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            <a href={'/product/instagate'} style={{color:'white'}}>
              Insta-Gate Noisegate
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
            The Insta-Gate is a noisegate designed speacifically for high gain guitar. It is ultra fast acting, so it preserves the attack...             </Typography>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Share</Button> */}
            <a href={'/product/instagate'} style={{color:style.color4}}>LEARN MORE</a>
          </CardActions>
        </Card>

</div>
<div style={{marginBottom:'10px', margin:'auto'}}>
<Card sx={{ maxWidth: 320, backgroundColor:style.color2, color:style.color7, borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
          <CardContent >
            <div style={{ textAlign:'center', padding:'5px', backgroundColor:'rgb(25,31,34)', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
              <a href={'/product/infinity-reverb'}>
              <img src={'/InfinityReverb.png'} style={{width:'150px', margin:'auto', borderRadius:'5px', alt:'Infinity Reverb Pedal'}} />
              </a>
            </div>
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            <a href={'/product/infinity-reverb'} style={{color:'white'}}>
              Infinity Reverb
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
              The Infinity Reverb is a world class reverb that is custom tailored specifically for guitar players. Designed to balance...
            </Typography>
          </CardContent>
          <CardContent>
              <div style={{margin:'auto', textAlign:'center'}}>
                <audio controls style={{width:'280px', margin:'auto'}}>  
                <source src="/ReverbSample.mp3" type="audio/mp3" />              
              </audio>
              </div>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Share</Button> */}
            <a href={'/product/infinity-reverb'} style={{color:style.color4}}>LEARN MORE</a>
          </CardActions>
        </Card>

</div>
        







  </div>
    </div>
</div>



  </div>



</>)}

        {!isMobile && (<>
          <div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', margin:'auto'}}>

          <div>
            <div style={{display:'flex', flexDirection:'column', margin:'auto', width:'100%'}}>
            <h2>
             
              
            </h2>

            <div style={{textAlign:'center', marginBottom:'0px'}}>
            <h1 style={{marginBottom:'0px'}}>
              All Products
            </h1>
          </div>

          <div style={{display:'flex', flexDirection:'row', margin:'auto', padding:'10px'}}>
          <div style={{flex:1, padding:'10px', marginRight:'25px'}}>
              <ProductCard productName="MK1 Overdrive" productSKU='10003' shortDescription = "World class reverb for guitar" productImageURI="InfinityReverb.png" productLink="/product/infinity-reverb" imageAltText="MK1 Overdrive Pedal" userEmail={userEmail} starRating={4.5} ratingCount={3} msrp = {99.95} price={49.95} cardCallout='new'/>
              </div>
              <div style={{flex:1, padding:'10px', marginRight:'25px'}}>
              <ProductCard productName="Infinity Reverb" productSKU='10001' shortDescription = "World class reverb for guitar" productImageURI="InfinityReverb.png" productLink="/product/infinity-reverb" imageAltText="Infinity Reverb stompbox with glowing blue LED indicator" userEmail={userEmail} starRating={4.5} ratingCount={3} msrp = {99.95} price={49.95} cardCallout='new'/>
              </div>
               <div style={{flex:1, padding:'10px'}}>
               <ProductCard productName="Insta-Gate Noisegate" productSKU='10002' shortDescription = "Ultra fast acting guitar noisegate" productImageURI="InstaGate.png" productLink="/product/instagate" imageAltText="Insta-Gate stompbox with glowing red LED indicator" userEmail={userEmail} starRating={5.0} ratingCount={6} msrp = {99.95} price={49.95} cardCallout='bestSeller'/>

                </div> 
            </div>
              </div>
          </div>


       
            </div>

        
        
        
        </>)}
        <div id='modal-root'>
          <SubscribeRedirectModal isOpen={isSubscribeRedirectModalOpen} close={closeSubscribeRedirectModal} />
          <LoginSubscribePromptModal isOpen={isLoginRegisterModalOpen} close={closeLoginRegisterModal} />
        </div>
        </>

    )
}

// import {useState, useEffect} from "react"
// import { useStyle } from "../../context/style_context/styleContext";
// import { useParams } from 'react-router-dom';
// import {useNavigate, Link} from 'react-router-dom';
// import axios from 'axios';
// import {toast} from 'react-hot-toast';
// import ReactGA from 'react-ga4';
// import NavBar from "../../components/Navbar/Navbar";
// import Footer from "../../components/Footer/Footer";

// import ProductsTable from "../../components/ProductsTable/ProductsTable";




// export default function Products(){

//     const { style, setStyle } = useStyle();
//     const { id } = useParams()
//     //const id  = "667895b7d2e6bec139507ec9"
//     console.log("ID: ", id)

//     useEffect(() => {getProductInfo();}, []); // pull list when screen loads
//     useEffect(() => {
//         document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Products';
//     }, []);

//     const [products, setProducts] = useState({})

//     ReactGA.send({
//         hitType:"pageview",
//         page:"products",
//         title:"products",
//       })

//     const getProductInfo = async (e) => {
//         console.log("getting product info")        
//         try{
            
//             const{data} = await axios.get(`/products/${id}`)
//             console.log(data)
//         if(data.error){
//             toast.error(data.error)
//         }
//         else{
//             //setCompanyData({name: '', size: '', type: ''})
//             //toast.success('companies retrieved!')
//             // console.log(data.name)
//             // console.log("")
//             // console.log("xxxxxxxxx")
//             // console.log(data)
//             // console.log(data[0])
//             // console.log(data[1])
//             // console.log("data type: ", typeof(data))
//             // console.log("xxxxxxxxx")
//             // console.log("")
//             setProducts(data)            

//         }
//         }catch(error){
//             console.log(error)
//         }
//     }

  

//     return(<>
//     <NavBar />
//     <div style={{textAlign:'center', color:style.color2}}>
//         Products<br />
//     </div>
//     <div style={{display:'flex', flexDirection:'row', margin:'auto', textAlign:'center', justifyContent:'center', alignContent:'center', width:'90%'}}>

//         <div style={{display:'flex', flex:1, flexDirection:'column', backgroundColor:style.color2, minWidth:'320px', maxWidth:'800px', width:'90%', margin:'auto', marginTop:'20px', alignContent:'center', justifyItems:'center', textAlign:'center', borderRadius:'15px', marginBottom:'50px'}}>
//         <div><h1>Products</h1></div>
//         <div style={{display:'flex', flexDirection:'row', backgroundColor:style.color5, borderTopLeftRadius:'15px', borderTopRightRadius:'15px', boxShadow: "rgba(0, 0, 0, .2) 1px 1px 20px 5px, rgba(31, 31, 35, .1) 0px 0px 0px 2px"}}>

//             <div style={{backgroundColor:'teal', flex:1}}>
//                 <ProductsTable companyID={{id}} products={products}/>
//             </div>

//         </div>
//         </div>
//     </div>

//      <Footer />
//         </>
//     )
// }