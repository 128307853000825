

export default function MK1OverdriveDevNotes({}) {
  
  
    return (
  
  <>
  <div style={{width:'95%', maxWidth:'1000px', margin:'auto'}}>
  <div style={{textAlign:'center'}}><h1>MK1 Overdrive</h1></div>
<div style={{textAlign:'center'}}><h2>Development Notes</h2></div>
<div><strong>Nate Parish - Developer</strong></div>
<div><strong style={{fontSize:'.8em'}}>October 11, 2024</strong></div>
<h3>
  Background
</h3>
<p>
    The first guitar effect that I ever used was an Overdrive pedal, and my first exposure came from my dad. I grew up amongst the potato fields of Southern Idaho, in a quiet little town. Well.. not too quiet anyway.. This was the early 80's, and my dad was a bit of a metalhead, and a virtuoso lead guitarist.
    He was a bit of a tone chaser, and these were the days when Van Halen ruled the world, so he did a lot of tinkering with amps and effects, as well as guitar modifiactions as well. 
    
</p>
<p>
  Some of my earliest memories are of his guitar rig from the early 80s. He could crank it up and it could be heard clearly on the other side of town, a mile and a half away. For a while anyway. Apparantly at some point when I was around 2 years old, I took a pencil and poked it through almost all of his speakers and completely destroyed them. I think after that incident he still had an Acoustic 6x12 cabinet that (fortunately) I hadn't gotten to before my rampage was cut short. After this incident, his setup consisted of a Wine Red 1974 Gibson Les Paul Custom, which was sent into an Ibanez TS9 Tube Screamer.
  The TS9 would boost the signal and add a bit of distortion. He then fed this signal into a tube preamp. I'm not entirely sure what the brand or model was, but he had 2 combo amps that he would use the tube preamp stages of those to provide the bulk of his distortion. This signal would then be sent to a 200 Watt Acoustic head, which then would power the 6 x 12 Acoustic cabinet.  
</p>
<p>
  When I was 12, I wanted to start learning guitar, my dad's setup was the first gear that I ever used. The Tube Screamer, as it turns out, was central to the tone in that entire setup. The increased output provided by the Tube Screamer was pushing the tubes in the preamp harder, causing them to distort as well. That setup yielded a respectable amount of gain, and the tone remained crystal clear. I wouldn't call it a high gain tone by the standards of today, closer to a Marshall Plexi but with a bit more gain. He is a huge fan of Eddie Van Halen, so I think that was the direction he was going for.
  Unfortunately at some point the TS9 was stolen, but there are many other overdrives that I have used since then that have been fantastic.  
</p>
<h2>Design</h2>
<p>
  For this Overdrive, I wanted to incorporate the best parts of some of my favorite pedals. I decided I wanted to have a bit more gain on tap, when compared to other models. I wanted to stick with the classic, simple layout from the TS9, so I went with a gain control, a tone control that adjusts the low pass filter from 1k to 10 kHz, and then an output level control that adjusts the output volume.
</p>
  </div>

  </>
  
    );
  }