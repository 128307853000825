import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import {useState, useEffect} from "react"
import { useParams } from 'react-router-dom';
import {toast} from 'react-hot-toast';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

// function createData(name, calories, fat, carbs, protein, price) {

// }

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


// const columns = [
//     { field: '_id', headerName: 'ID', width: 70 },
//     { field: 'name', headerName: 'Record Name', width: 130 },
//     { field: 'type', headerName: 'Record Type', width: 130 },
//     {
//       field: 'baseAmt',
//       headerName: 'Amount',
//       type: 'number',
//       width: 90,
//     },

//   ];

//   const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//     },
//   }));
  
//   const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//       border: 0,
//     },
//   }));
  
//   function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
//   }

//   const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//   ];


// export default function DataTable() {

//     useEffect(() => {getAllRecords();}, [])
//     const [records, setRecords] = useState({})

//     const [recordData, setRecordData] = useState({}) 
//     const { id } = useParams()

//     const getAllRecords = async (e) => {    

        
        
//                 try{
                    
//                     const{data} = await axios.get(`/projection_records/${id}`)     
        
//                 if(data.error){
//                     toast.error(data.error)
//                 }
//                 else{
//                     //setCompanyData({name: '', size: '', type: ''})
//                     toast.success('records retrieved for table!')
//                     setRecords(data)
                    
//                     setRecordData(data.map((item, index) => {
//                         return {
//                           id: index + 1,
//                           ...item
//                         };
//                       }));

//                       console.log("Record Data: ", recordData)
                        
//                     //setRecords(data.map(mon => (mon._id === data._id ? data : mon)));            
        
//                 }
//                 }catch(error){
//                     console.log(error)
//                 }
//             }


//   // return (
//   //   <div style={{ height: 400, width: '100%' }}>
//   //     <DataGrid 
//   //       rows={recordData} 
//   //       columns={columns} 
//   //       initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 },},}} 
//   //       pageSizeOptions={[5, 10]} 
//   //       checkboxSelection
//   //     />
//   //   </div>
//   //);

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 700 }} aria-label="customized table">
//         <TableHead>
//           <TableRow>
//             <StyledTableCell>Dessert (100g serving)</StyledTableCell>
//             <StyledTableCell align="right">Calories</StyledTableCell>
//             <StyledTableCell align="right">Fat&nbsp;(g)</StyledTableCell>
//             <StyledTableCell align="right">Carbs&nbsp;(g)</StyledTableCell>
//             <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row) => (
//             <StyledTableRow key={row.name}>
//               <StyledTableCell component="th" scope="row">
//                 {row.name}
//               </StyledTableCell>
//               <StyledTableCell align="right">{row.calories}</StyledTableCell>
//               <StyledTableCell align="right">{row.fat}</StyledTableCell>
//               <StyledTableCell align="right">{row.carbs}</StyledTableCell>
//               <StyledTableCell align="right">{row.protein}</StyledTableCell>
//             </StyledTableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );

// }


// // import ProjectionsTableRow from "./ProjectionsTableRow";
// // import axios from 'axios';
// // import {useState, useEffect} from "react"
// // import {toast} from 'react-hot-toast';
// // import { Link } from "react-router-dom"


// // export default function ProjectionsTable() {

// //     useEffect(() => {getAllRecords();}, [])

// //     const [records, setRecords] = useState({})


// //     const deleteRecord = async (e) => {
// //         console.log("DELETE!!", e)
// //     }

// //     const getAllRecords = async (e) => {        

        
        
// //         try{
            
// //             const{data} = await axios.get('/projection_records')
// //             console.log("RECORDS MAMAJAMMMA!!", data)


// //         if(data.error){
// //             toast.error(data.error)
// //         }
// //         else{
// //             //setCompanyData({name: '', size: '', type: ''})
// //             toast.success('records retrieved for table!')
// //             //setRecords(data)
// //             setRecords(data.map(mon => (mon._id === data._id ? data : mon)));            

// //         }
// //         }catch(error){
// //             console.log(error)
// //         }
// //     }

// //     return(<>
// //     <div>
// //         <ProjectionsTableRow />
// //         <ProjectionsTableRow />
// //         <table style={{margin:'auto', marginBottom:'50px'}}>
// //                     <thead>
// //                         <tr>
// //                             Company Name
// //                         </tr>
// //                     </thead>
// //                     <tbody>
                        
// //                     </tbody>
// //                 </table>
// //     </div>

// //     </>)

// // }