import {useState, useContext, useEffect} from "react"
import axios from 'axios'
import {toast} from 'react-hot-toast'
import {useNavigate, Link} from 'react-router-dom'
import{UserContext} from '../../context/userContext';
import { useStyle } from "../../context/style_context/styleContext";
import ReactGA from 'react-ga4';
import TermsModal from "../terms/TermsModal";
import Terms from "../terms/Terms";
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Checkbox, TextField, FormCOntrol, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel } from "@mui/material";




export default function AppLoginTestPage(){
    const navigate = useNavigate()
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const { style, setStyle } = useStyle();
    const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    
    ReactGA.send({
        hitType:"pageview",
        page:"login",
        title:"login",
      })

  
      useEffect(() => {
      document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Login';
  }, []);

    const[data,setData] = useState({
        email: '',
        password: '',
        token:'19384nsdfglaiodfhgna'
    })

    const navToRegister = () => {
        navigate('/register')
      }

    const { setUser } = useContext(UserContext);
    const { isLoading,  } = useContext(UserContext);
    const { setUpdateFlag } = useContext(UserContext);

    const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));

    const loginUser = async (e) =>
    {
        //console.log("logging in...")
        e.preventDefault()
        

        const {email, password, token} = data
        
        try{ 
            
            console.log(email);
            console.log(password);
            console.log(token);
            
            const{data} = await axios.post('/api/app_login', {
                email, 
                password,
                token,
            });
            const userData = data;

            //console.log("USER DATA: ", userData)
            //console.log(userData)
            if(data.error){
                //console.log("ERROR1")
                //console.log(data.error)
                toast.error(data.error)
        }   else {            
                //console.log(data)
                //console.log("success! setting user..")
                //await setUser(userData);
                //setData(userData);
                toast.success(data.success)
                await setUpdateFlag()
                //navigate('/')
                //navigate('/')    
            //await setData({});
        }
        }catch(error){
            
            //console.log("ERROR2")
            //console.log("RESPONSE DATA: ", error)
            //toast.error(error.response?.data?.error || "An error occurred"); // Improved error handling
            toast.error(data.error)
        }
        //axios.get('/')
        //console.log("end navigate function firing..")
        // await setUpdateFlag()
        // navigate('/')
    }

    return(
        <><>
        <div style={{display:'flex', flexDirection:'column', width:'100%'}}>

<div style={{display:'flex', flexDirection:'column', width:'90%', maxWidth:'600px', margin:'auto', color:style.color5, textAlign:'center', marginTop:'40px', backgroundColor:style.color9, borderRadius:'10px', borderStyle:'solid', borderColor:style.color4}}>
    <div style={{width:'90%', margin:'auto'}}>

    
        <div style={{display:'flex', flex:1, textAlign:'center', marginTop:'20px'}}>
            <h1 style={{flex:1, textAlign:'center', margin:'auto', marginBottom:'75px', fontSize:'2.5em'}}>
                APPLICATION LOGIN TEST PAGE
            </h1>
           
        </div>
        <div style={{maxWidth:'400px', margin:'auto'}}>
        <div style={{display:'flex', flex:1, textAlign:'center', minWidth:'300px', margin:'auto', marginBottom:'30px'}}>
            <h4 style={{flex:1, textAlign:'left', margin:'auto', color:style.color5}}>
                <div style={{marginBottom:'5px'}}>
                <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value= {data.firstName}
                onChange={(e) => setData({...data, email: e.target.value})}
                size='medium'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                </h4>
        </div>

        <div style={{display:'flex', flex:1, marginBottom:'10px', width:'100%', backgroundColor:'teal'}}>                        
                          <FormControl sx={{
                                backgroundColor: style.color10,
                                flex:1,
                                margin:'auto',
                                '& .MuiInputBase-input': {
                                color: 'white',  // Change the font color here
                                fontSize: '16pt',  // Change the font size here
                                size:'medium',
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: style.color4, // Change the border color if needed
                                    borderWidth:'2px'
                                },
                                '&:hover fieldset': {
                                    borderColor: style.color4, // Change the border color on hover if needed
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: style.color4, // Change the border color when focused if needed
                                },
                                '&.Mui-disabled': {
                                    '& fieldset': {
                                    borderColor: style.color4, // Change the border color when disabled
                                    },
                                },
                                },
                                '& .MuiInputLabel-root': {
                                color: style.color7, // Change the label color here
                                fontSize:'14pt',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                color: style.color5, // Change the label color when focused here
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                color: style.color5, // Change the label color when disabled
                                },
                                '& .MuiInputBase-root.Mui-disabled': {
                                '& input': {
                                    color: style.color5, // Change the input font color when disabled // so it works in safari
                                    WebkitTextFillColor: style.color5, // so it works on chrome
                                    fontSize:'18pt'
                                },
                                },
                                '& .MuiOutlinedInput-input': { color: 'white',
                                '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                                WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                            }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              value= {data.password}
                              onChange={(e) => setData({...data, password: e.target.value})}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ color:style.color4}}
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          </FormControl>
                          
                        </div>
                        <div >
                          <Link to='/forgot_password' style={{fontSize:'.9em', marginTop:'10px', fontWeight:'bold', textAlign:'center', color:style.color4, margin:'auto', alignContent:'center'}}>
                              Forgot Password?
                        </Link>
                          </div>
       

        

        {(data.email && data.password ) &&(
        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button onClick={loginUser} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Login</Button>
        </div>
        )}
        {(!data.email || !data.password ) &&(
        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button disabled onClick={loginUser} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color3, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Login</Button>
        </div>
        )} 

        <div style={{marginBottom:'50px'}}>

            <Button onClick={navToRegister} sx={{ my: 2, color: style.color4, display: 'block', margin:'auto', fontSize:'14pt'}}>
                Create Account
            </Button>


            </div>
            <div style={{ flex:1, borderBottomLeftRadius:'15px', color:style.color5, marginBottom:'25px'}}>                 
                    By using this website, you agree to our 
                    <div>
                        <Button sx={{color:style.color4, marginTop:'5px'}}onClick={setModalOpen.bind(null, true)}>terms and conditions</Button>
                    </div>
                    </div>

                    <div id='modal-root'>
                    <TermsModal isOpen={isModalOpen} close={closeModal}>
                        <Terms />
                    </TermsModal>
                    </div>
            
                </div>                




    </div>

    </div>

</div>
<div id='modal-root'>
</div>


        </>
        </>         

    )

    
}

