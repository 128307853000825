import {useNavigate, Link} from 'react-router-dom';
import { useStyle } from '../../context/style_context/styleContext';

export default function Footer(){

    const { style, setStyle } = useStyle();
    var yearDisplayString = ""
    const startYear = '2024'
    const curDateTime = new Date()
    const curYear = curDateTime.getFullYear()
    
    if(startYear == curYear)
        {
            yearDisplayString = startYear + " - "
        }
    else
        {
            yearDisplayString = startYear + " - " + curYear
        }


    return (
    <>
    <footer className="footer" style={{backgroundColor:style.color2, color:style.color5, marginTop:'50px', width:'100%', marginBottom:'0px'}}>
        <div style={{margin:'auto', width:'90%', textAlign:'center', marginTop:'25px', marginBottom:'25px', padding:'10px'}}>
            <div>
            <Link to='/' style={{className:'headerLinkText', fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>Home</Link>
            <Link to='/products' style={{fontSize:'.9em', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>Products</Link>
            <Link to='/support' style={{fontSize:'.9em', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>Support</Link>
            <Link to='/privacy-policy' style={{fontSize:'.9em', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>Privacy Policy</Link>
            </div>
            <div>
            <Link to='/contact' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>Contact</Link>
            <Link to='/about' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>About</Link>
            <Link to='/faq' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px', color:style.color4}}>FAQ</Link>

            </div>
            <p>&copy; {yearDisplayString} Brainwave LLC -  All rights reserved.</p>
        </div>  


    </footer>
    
    </>)
}
