import { useState, useEffect } from "react";
import {useNavigate, Link} from 'react-router-dom';
import {toast} from 'react-hot-toast'
import ReactGA from 'react-ga4';
import { useStyle } from "../../context/style_context/styleContext";
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Checkbox, TextField, FormCOntrol, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel } from "@mui/material";
import { FaAngleLeft } from "react-icons/fa6";
import AlertModal from "../../components/AlertModal/AlertModal";

//import IMAGES from "../../assets";
import {Notify} from '../../utilities'

export default function ForgotPassword(){
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { style } = useStyle();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
      setIsModalOpen(false);
      navigate('/login')

    }
  

  ReactGA.send({
    hitType:"pageview",
    page:"forgot-password",
    title:"forgot-password",
  })

  useEffect(() => {
  document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Forgot Password';
}, []);

const navigate = useNavigate()





  const sendRequest = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If any field is missing
    if (email) {
      //console.log('email is missing...')
      setIsLoading(false);
      try {
        const response = await fetch("https://api.brainwavesoundlab.com/forgot_password", {
        //const response = await fetch("http://localhost:8080/forgot_password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        });
        const data = await response.json();
        //console.log("RESPONSE ", data)
  
        if (data.success) {
          setIsLoading(false);
          setIsEmailSent(true);
          //toast.success("Your request has been sent. Pleast check your email for a link to reset your password.")
          //return Notify(data.data, "success");
          
          
        } else {
          setIsLoading(false);
          //return Notify(data.error, "error");
          //toast.error("An error has occured, Please try again.")
        }
      } catch (error) {
        setIsLoading(false);
        //return Notify("Internal server error", "error");
        //toast.error("An error has occured, Please try again.")
      }
  
      openModal();

    }
    else{
      toast.error("Please enter your email address");
    }

    
  };

  return (
    <>
      {isEmailSent ? (
        <div>
          <img
            //src={IMAGES.email}
            className="mx-auto d-block mt-5 mb-3"
            width="100px"
            alt="email sent successfully"
          />

          <p className="email__heading text-center fs-2">Check your mail</p>
          <p className="text-center text-muted fs-5">
            We have sent a password recover instructions to your email.
          </p>
        </div>
      ) : (
        <div style={{margin:'auto', borderColor:style.color4, borderWidth:'2px', borderRadius:'10px', borderStyle:'solid', maxWidth:'600px', marginTop:'30px', backgroundColor:style.color9}}>
         <div style={{margin:'auto', textAlign:'center', width:'90%'}}>
          <div>
            <h1 style={{marginBottom:'40px', marginTop:'30px'}}>Forgot your password?</h1>
            <h4>
            Enter the email associated with your account, and we will send instructions to reset your password.
            </h4>
          </div>
          <div>

         </div>
         <div style={{width:'90%', margin:'auto', marginTop:'50px'}}>
          <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value= {email}
                onChange={(e) => setEmail(e.target.value)}
                size='medium'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                              
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                </div>
                <div style={{marginTop:'50px', marginBottom:'30px', display:'flex', flex:1}}>
                    <Button onClick={sendRequest} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', minWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Send Link</Button>
                </div>
                <div style={{display:'flex', marginBottom:'50px', flexDirection:'row'}}>  
                  <div style={{margin:'auto', flex:1, flexDirection:'row'}}>
                    
                 
                    <div >
                      
                    <div>
                    <Link to='/login' style={{fontSize:'1.3em', fontWeight:'bold', textAlign:'center', color:style.color4, margin:'auto', alignContent:'center', textAlign:'center'}}>
                         Back To Login
                      </Link>
                    </div>

                    </div>
                    
                    

                  

                  </div>

                <Link to='/login' style={{fontSize:'1.3em', fontWeight:'bold', textAlign:'center', color:style.color4, margin:'auto', alignContent:'center'}}>
                
                
                </Link>
                </div>
                
               
          </div>
          <div id='modal-root'>
            <AlertModal message={"A password reset link has been sent to your email. Please follow the instructions contained in the email to reset your password."} buttonText={'Proceed To Login'} isOpen={isModalOpen} close={closeModal}>
                
            </AlertModal>
            </div>
        </div>



      )}
    </>
  );
};

