
import {React, useState, useContext, useEffect} from "react"
import{UserContext} from '../../context/userContext'
import { useStyle } from '../../context/style_context/styleContext';
import axios from 'axios'
import {toast} from 'react-hot-toast'
import Button from '@mui/material/Button';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeStripeEmailModal from "./ChangeStripeEmailModal";
import Terms from "../terms/Terms";
import { Checkbox, TextField, FormGroup, FormControlLabel } from "@mui/material";


export default function Account(){

  const { user } = useContext(UserContext);
  const { style, setStyle } = useStyle();
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const openPasswordModal = () => setPasswordModalOpen(true);
  const closePasswordModal = () => setPasswordModalOpen(false);

  const [isStripeEmailModalOpen, setStripeEmailModalOpen] = useState(false);
  const openStripeEmailModal = () => setStripeEmailModalOpen(true);
  const closeStripeEmailModal  = () => setStripeEmailModalOpen(false);

  useEffect(() => {getAccountInfo();}, []); // pull list when screen loads
  useEffect(() => {update();}, [])

    ReactGA.send({
        hitType:"pageview",
        page:"account",
        title:"account",
    })

    useEffect(() => {
    document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Account';
    }, []);
  
    // const [userData, setUserData] = useState({
    // email: "",
    // firstName: "",
    // lastName: "",
    // okToEmail: false,    
    // })

    const [formData, setFormData] = useState({
    email:"",
    firstName: "",
    lastName: "",
    okToEmail: false,
    })

    const [originalData, setOriginalData] = useState({
        email:"",
        firstName: "",
        lastName: "",
        okToEmail: false,
        })
    
    const [imgData, setImgData] = useState({
        profileImage: "",
        })



    const handleSubmit = async (e) => {
        e.preventDefault();        
    }


    const saveNewImgUriToDB = async (imgURI) => {

        console.log("submit!")

        console.log('imgURI: ', imgURI)
    
        try {
            const { data } = await axios.patch(`/update_profile_pic/${imgURI}`);
            if (data.error) {
                toast.error(data.error);
            } else {
    
                await getAccountInfo();
                edit.activated = false
                toast.success('Account Information Updated!');
            }
        } catch (error) {
            console.error(error);
            toast.error('Update failed');
        }
    };

    const [edit, setEdit] = useState({
        activated : false
    })

    
  const changeBackground = () => {
    setStyle({ ...style, backgroundColor: 'rgb(0,255,0)' });
  };

  const fire = () => {
    console.log("FIRE!!!!!!")
    console.log("form data x")
    console.log(formData)
    console.log("O Data: ")
    console.log(originalData)
  };

  const toggleEditMode = async (e) => {
    if(edit.activated){
        setEdit({ ...edit, activated: false });
    }
    else{
        setEdit({ ...edit, activated: true });
    }


  }

  const cancel = async (e) => {

    if(edit.activated){
        setEdit({ ...edit, activated: false });
    }
    else{
        setEdit({ ...edit, activated: true });
    }
  }

  const update = async (e) => {

  } 
  


  const getAccountInfo = async (e) => {         
    try{
        const{data} = await axios.get('/account')

        if(data.error){
            toast.error(data.error)
        }
        else{
            //setCharacterData({name: '', size: '', type: ''})
            //await setUserData(data)
            await setFormData(data)
            await setOriginalData(data)        
                        
           
            setIsPageLoading(false)
        }
    }catch(error){
        console.log(error)
    }
}




  const submitChanges = async (e) => {
    
    e.preventDefault();


    try {
        const { data } = await axios.patch('/account', formData);
        if (data.error) {
            toast.error(data.error);
        } else {
            
            toast.success('Account Information Updated!');
            await getAccountInfo();
        }
    } catch (error) {
        console.error(error);
        toast.error('Update failed');
    }
};

    return(
        <>          

    {isPageLoading &&(<div style={{textAlign:'center', minHeight:'500px'}}><h1> Page Loading.......</h1></div>)}
    {!isPageLoading &&(


<div style={{display:'flex', flexDirection:'column', width:'100%'}}>

<div style={{display:'flex', flexDirection:'column', width:'90%', maxWidth:'800px', margin:'auto', color:style.color5, textAlign:'center', marginTop:'40px', backgroundColor:style.color9, borderRadius:'10px', borderStyle:'solid', borderColor:style.color4}}>
    <div style={{width:'90%', margin:'auto'}}>

    
        <div style={{display:'flex', flex:1, textAlign:'center', marginTop:'20px'}}>
            <h1 style={{flex:1, textAlign:'center', margin:'auto', marginBottom:'40px'}}>
                Account Information
            </h1>
           
        </div>
        <div style={{maxWidth:'400px', margin:'auto'}}>
        <div style={{display:'flex', flex:1, textAlign:'center'}}>
            <h4 style={{flex:1, textAlign:'left', margin:'auto', color:style.color5}}>
                <div style={{marginBottom:'5px'}}>
                
                <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={formData.email}
                size='small'
                fullWidth
                disabled
                sx={{
                    backgroundColor: style.color10,
                    '& .MuiInputBase-input': {
                    color: 'white',  // Change the font color here
                    fontSize: '16px',  // Change the font size here
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'14pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: 'yellow', // Change the label color when focused here
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color5, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color5, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color5, // so it works on chrome
                        fontSize:'18pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
            WebkitTextFillColor: style.color7, color:style.color7
         }, }, 
                }}
                />

                </div>
                </h4>
        </div>
        <div style={{display:'flex', flex:1, textAlign:'center', marginBottom:'10px'}}>
        
        <FormGroup>
            <FormControlLabel checked={formData.okToEmail} onChange={(e) => setFormData({ ...formData, okToEmail: e.target.checked }) } control={<Checkbox size='large' sx={{color:style.color4, '&.Mui-checked': {
                color: style.color4,
            },}} />} label="Subscribe to Emails" />
        </FormGroup>
        </div>
        <div style={{display:'flex', flex:1, textAlign:'center', padding:'0px', justifyContent:'top', margin:'auto', marginBottom:'25px'}}>
                <Button onClick={setPasswordModalOpen.bind(null, true)} sx={{ my: 2, color:style.color4, display:'block', backgroundColor:style.color9, margin:'auto', fontSize:'12pt', width:'100%', minWidth:'250px', padding:'5px', borderColor:style.color9, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color3 }, }}>Change Password</Button>
        </div>
        <div style={{display:'flex', flex:1, textAlign:'center', marginBottom:'20px'}}>
        <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value= {formData.firstName}
                onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'14pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
        '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
            WebkitTextFillColor: style.color7, color:style.color7
         }, }, 
                }}
                />
        </div>

        <div style={{display:'flex', flex:1, textAlign:'center', marginBottom:'15px'}}>
        <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                value= {formData.lastName}
                onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px'
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'14pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color5, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
        '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
            WebkitTextFillColor: style.color7, color:style.color7
         }, }, 
                }}
                />

        
        </div>
        <div style={{display:'flex', flex:1, textAlign:'center', padding:'0px', justifyContent:'top', margin:'auto', marginBottom:'25px'}}>
                <Button onClick={setStripeEmailModalOpen.bind(null, true)} sx={{ my: 2, color:style.color4, display:'block', backgroundColor:style.color9, margin:'auto', fontSize:'12pt', width:'100%', minWidth:'250px', padding:'5px', borderColor:style.color9, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color3 }, }}>Attach Stripe Email</Button>
        </div>

        {(formData.firstName != originalData.firstName || formData.lastName != originalData.lastName || formData.okToEmail != originalData.okToEmail) &&(
        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button onClick={submitChanges} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Submit Changes</Button>
        </div>
        )}
        {(formData.firstName === originalData.firstName && formData.lastName === originalData.lastName && formData.okToEmail === originalData.okToEmail) &&(
        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button disabled onClick={submitChanges} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color3, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Submit Changes</Button>
        </div>
        )}                          




    </div>

    </div>

</div>
<div id='modal-root'>
<ChangePasswordModal isOpen={isPasswordModalOpen} close={closePasswordModal}>
    
</ChangePasswordModal>
<ChangeStripeEmailModal isOpen={isStripeEmailModalOpen} close={closeStripeEmailModal} />
</div>


</div>


    )}

        
        </>

    )
}