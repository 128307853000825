import {useState, useEffect, select} from "react"
import { Link } from "react-router-dom"
import axios from 'axios';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom'
import { useStyle } from "../../context/style_context/styleContext";
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';

import { Checkbox, TextField, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel } from "@mui/material";




export default function CreateProduct(){


    const { style, setStyle } = useStyle();

    ReactGA.send({
        hitType:"pageview",
        page:"create_product",
        title:"create_product",
      })

      useEffect(() => {
        document.title = 'Brainwave Sound Lab | ADMIN | Create Product';
    }, []);

    const [data, setData] = useState({

        name: "",
        SKU: "",
        shortDescription: "",
        longDescription: "",
        bulletPoint1: "",
        bulletPoint2: "",
        bulletPoint3: "",
        bulletPoint4: "",
        bulletPoint5: "",
        msrp: 0,
        price: 0,
        imageURIs: [],
        UPC: "",
        productURI: "",
        windowsDownloadURI:"",
        macDownloadURI:"",

    })

    const submitData = async (e) => {

        e.preventDefault();
        
        var okToSend = true;

        const {
            name,
            SKU,
            shortDescription,
            longDescription,
            bulletPoint1,
            bulletPoint2,
            bulletPoint3,
            bulletPoint4,
            bulletPoint5,
            msrp,
            price,
            imageURIs,
            UPC,
            productURI,
            windowsDownloadURI,
            macDownloadURI,
        } = data



        if(!name)
            {
                okToSend = false
                toast.error("Error: You must enter a product Name")
            }

        if(okToSend)
        {
            console.log("sending....")
            try{
                okToSend = false   
                const{data} = await axios.post('/auth/create_product',{name,
                                                                        SKU,
                                                                        shortDescription,
                                                                        longDescription,
                                                                        bulletPoint1,
                                                                        bulletPoint2,
                                                                        bulletPoint3,
                                                                        bulletPoint4,
                                                                        bulletPoint5,
                                                                        msrp,
                                                                        price,
                                                                        imageURIs,
                                                                        UPC,
                                                                        productURI,
                                                                        windowsDownloadURI,
                                                                        macDownloadURI,
                                                                    })
    
    
            if(data.error){
                console.log("error")
                toast.error(data.error)
            }
            else{
                //setData({})
                toast.success('Success! Product created!')
                //navigate('/home')
            }
            }catch(error){
                console.log(error)
            }
        }
    }


    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'90%', maxWidth:'800px', margin:'auto', color:style.color5, textAlign:'center', marginTop:'40px', backgroundColor:style.color9, borderRadius:'10px', borderStyle:'solid', borderColor:style.color4}}>
    <div style={{width:'90%', margin:'auto'}}>

    
        <div style={{display:'flex', flex:1, textAlign:'center', marginTop:'20px'}}>
            <h1 style={{flex:1, textAlign:'center', margin:'auto', marginBottom:'40px'}}>
                New Product
            </h1>
           
        </div>
        <div style={{maxWidth:'400px', margin:'auto'}}>
        <div style={{display:'flex', flex:1, textAlign:'center', minWidth:'300px', margin:'auto', marginBottom:'30px'}}>
            <h4 style={{flex:1, textAlign:'left', margin:'auto', color:style.color5}}>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="Product Name"
                variant="outlined"
                value= {data.name}
                onChange={(e) => setData({...data, name: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>

                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="SKU"
                variant="outlined"
                value= {data.SKU}
                onChange={(e) => setData({...data, SKU: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>

                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="Short Description"
                variant="outlined"
                value= {data.shortDescription}
                onChange={(e) => setData({...data, shortDescription: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="Long Description"
                variant="outlined"
                value= {data.longDescription}
                onChange={(e) => setData({...data, longDescription: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="bulletPoint1"
                variant="outlined"
                value= {data.bulletPoint1}
                onChange={(e) => setData({...data, bulletPoint1: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="bulletPoint2"
                variant="outlined"
                value= {data.bulletPoint2}
                onChange={(e) => setData({...data, bulletPoint2: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="bulletPoint3"
                variant="outlined"
                value= {data.bulletPoint3}
                onChange={(e) => setData({...data, bulletPoint3: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="bulletPoint4"
                variant="outlined"
                value= {data.bulletPoint4}
                onChange={(e) => setData({...data, bulletPoint4: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="bulletPoint5"
                variant="outlined"
                value= {data.bulletPoint5}
                onChange={(e) => setData({...data, bulletPoint5: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="MSRP"
                variant="outlined"
                type="number"
                value= {data.msrp}
                onChange={(e) => setData({...data, msrp: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                type="number"
                value= {data.price}
                onChange={(e) => setData({...data, price: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                </div>

<div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="UPC"
                variant="outlined"
                value= {data.UPC}
                onChange={(e) => setData({...data, UPC: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>

                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="Product URI"
                variant="outlined"
                value= {data.productURI}
                onChange={(e) => setData({...data, productURI: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>

                </h4>
                </div>
        </div>


        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button onClick={submitData} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Create Product</Button>
        </div> 

        </div>
        </div>      
                
         </>         

    )
}

