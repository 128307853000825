
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReactGA from 'react-ga4';



export default function FAQ(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

    ReactGA.send({
      hitType:"pageview",
      page:"FAQ",
      title:"about",
    })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | Frequently Asked Questions';
  }, []);



    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', minHeight:'68vh', margin:'auto'}}>
          <div style={{textAlign:'left', color: style.color5, textAlign:'center', marginTop:'0px', marginBottom:'0px'}}>
            <h1 style={{ marginTop:'30px', marginBottom:'0px'}}> Frequently Asked Questions</h1>

            </div>
            <div style={{width:'95%', maxWidth:'1000px', margin:'auto', marginTop:'10px'}}>
              <Accordion sx={{backgroundColor:style.color3, color:style.color5}}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon style={{color:style.color5}}/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography><h3 style={{marginTop:'0px', marginBottom:'0px'}}>How many devices can I install my product on?</h3></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:style.color10, color:style.color5}}>
                  <Typography>

                    <p style={{textAlign:'left'}}>
                    You are allowed to install and use the application on up to 5 devices. If you need to change or remove a device from your device list, this can be done on the <a href='/my-gear' style={{color:style.color4}}><strong>my gear</strong></a> page. 
                    </p>
                    <p>
                        In certain circumstances we may increase this limit. This will be considered on a case by case basis. If you would like to request an increase, please contact us at <a href="mailto:brainwavesoundlab@gmail.com" style={{color:style.color4, fontSize:'14pt'}}>brainwavesoundlab@gmail.com</a>.
                    </p>

                                </Typography>
                </AccordionDetails>
              </Accordion >
              <Accordion sx={{backgroundColor:style.color3, color:style.color5}}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon style={{color:style.color5}}/>}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography><h3 style={{marginTop:'0px', marginBottom:'0px'}}>Which operating systems are supported?</h3></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:style.color10, color:style.color5}}>
                  <Typography>
                    Currently are products are available on windows as VST3 files. We will be adding support for Mac soon.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor:style.color3, color:style.color5}}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon style={{color:style.color5}}/>}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography><h3 style={{marginTop:'0px', marginBottom:'0px'}}>Contact Us</h3></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:style.color10, color:style.color5}}>
                  <Typography>
                  We would love to hear from you. If you have questions or need support, please contact us at <a href="mailto:brainwavesoundlab@gmail.com" style={{color:style.color4, fontSize:'14pt'}}>brainwavesoundlab@gmail.com</a>.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </>

    )
}