import {React, useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import { useStyle } from "../../context/style_context/styleContext";
import Button from '@mui/material/Button';




const LogoutModal = ({ isOpen, close, children }) => {

    const { style, setStyle } = useStyle();
  
    if (!isOpen) return null;

    
    const closeFunction = async (e) => {            
                close() 

    }


        

  return ReactDOM.createPortal(


    
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <div style={{backgroundColor:style.color9, width:'90%', margin:'auto'}}>
            
                    <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'90%', margin:'auto'}}>
                        
                          <>
                          <div style={{marginBottom:'75px', marginTop:'0px'}}>
                            <h2>
                              Success! You have been logged out.
                            </h2>
                            
                            </div>
                            <div>
                            <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>OK</Button>
                              </div>
                              </>
                            

                    </div>
           
            
        </div>
      </div>

    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};

const modalContentStyle = {
  padding: '20px',
  borderRadius: '15px',
  maxWidth: '800px',
  minHeight: '300px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  borderColor:'#535bf2',
  borderWidth:'2px',
  borderStyle:'solid',
  backgroundColor: 'rgb(58,59,60)',
};

const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default LogoutModal;
