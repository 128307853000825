import { useState, useEffect } from "react";
//import * as Yup from "yup";
import {toast} from 'react-hot-toast';
import {useNavigate, Link} from 'react-router-dom'
import axios from "axios";
import ReactGA from 'react-ga4';
import { useStyle } from "../../context/style_context/styleContext";
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Checkbox, TextField, FormCOntrol, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel } from "@mui/material";


export default function ResetPassword(){
  const navigate = useNavigate()
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [formData, setFormData] = useState({
    newPassword1: "",
    newPassword2: "",
    })
    const [values, setValues] = useState({
      password: "",
      showPassword: false,
  });


const { style } = useStyle();

ReactGA.send({
  hitType:"pageview",
  page:"password_reset",
  title:"password_reset",
})


useEffect(() => {
document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Password Reset';
}, []);
   


    const submitUpdate = async (e) => {
      
    const token = window.location.pathname.split("/").pop();
    let okToSend = false;

    if(formData.newPassword1 === formData.newPassword2)
    {
        okToSend = true;
    }
    if(formData.newPassword1.length < 6)
    {
      okToSend = false;
      return toast.error('your password must be at least 6 characters long')
    }
    if(formData.newPassword1.length > 1000)
    {
      okToSend = false;
      return toast.error('your password must be 1000 characters or less')
    }

    if(okToSend)
    {
        try {
            const { data } = await axios.patch(`/reset_password/${token}`, formData);
            if (data.error) {
                toast.error(data.error);
            } else {

                toast.success('Success! Password has been changed.');
                navigate('/login')
            }
        } catch (error) {
            console.error(error);
            toast.error('Update failed');
        }
    } 
    else{
        toast.error('Your passwords do not match!')
    }

    }
  

  return (<>

  <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'90%', margin:'auto'}}>


{!showConfirm &&(
                        <>
                        <div style={{margin:'auto', textAlign:'center', marginBottom:'20px'}}>
                            <h1>Change Your Password</h1>
                        </div>
                        <div style={{marginBottom:'30px', borderWidth:'2px', borderColor:style.color4, borderRadius:'10px', borderStyle:'solid', width:'90%', maxWidth:'600px', margin:'auto'}}>



                          
                        
                        <div style={{marginBottom:'30px', marginTop:'25px'}}>                        
                          <FormControl sx={{
                                width:'90%',
                                maxWidth:'400px',
                                marginTop:'35px',
                                backgroundColor: style.color10,
                                '& .MuiInputBase-input': {
                                color: 'white',  // Change the font color here
                                fontSize: '16px',  // Change the font size here
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: style.color4, // Change the border color if needed
                                    borderWidth:'2px'
                                },
                                '&:hover fieldset': {
                                    borderColor: style.color4, // Change the border color on hover if needed
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: style.color4, // Change the border color when focused if needed
                                },
                                '&.Mui-disabled': {
                                    '& fieldset': {
                                    borderColor: style.color4, // Change the border color when disabled
                                    },
                                },
                                },
                                '& .MuiInputLabel-root': {
                                color: style.color7, // Change the label color here
                                fontSize:'14pt',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                color: style.color5, // Change the label color when focused here
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                color: style.color5, // Change the label color when disabled
                                },
                                '& .MuiInputBase-root.Mui-disabled': {
                                '& input': {
                                    color: style.color5, // Change the input font color when disabled // so it works in safari
                                    WebkitTextFillColor: style.color5, // so it works on chrome
                                    fontSize:'18pt'
                                },
                                },
                            }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword1 ? 'text' : 'password'}
                              value= {formData.newPassword1}
                              onChange={(e) => setFormData({...formData, newPassword1: e.target.value})}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword1}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ color:style.color4}}
                                  >
                                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          </FormControl>
                        </div>

                        <div>                        
                          <FormControl sx={{
                                width:'90%',
                                maxWidth:'400px',
                                backgroundColor: style.color10,
                                '& .MuiInputBase-input': {
                                color: 'white',  // Change the font color here
                                fontSize: '16px',  // Change the font size here
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: style.color4, // Change the border color if needed
                                    borderWidth:'2px'
                                },
                                '&:hover fieldset': {
                                    borderColor: style.color4, // Change the border color on hover if needed
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: style.color4, // Change the border color when focused if needed
                                },
                                '&.Mui-disabled': {
                                    '& fieldset': {
                                    borderColor: style.color4, // Change the border color when disabled
                                    },
                                },
                                },
                                '& .MuiInputLabel-root': {
                                color: style.color7, // Change the label color here
                                fontSize:'14pt',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                color: style.color5, // Change the label color when focused here
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                color: style.color5, // Change the label color when disabled
                                },
                                '& .MuiInputBase-root.Mui-disabled': {
                                '& input': {
                                    color: style.color5, // Change the input font color when disabled // so it works in safari
                                    WebkitTextFillColor: style.color5, // so it works on chrome
                                    fontSize:'18pt'
                                },
                                },
                            }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">New Password Again</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword2 ? 'text' : 'password'}
                              value= {formData.newPassword2}
                              onChange={(e) => setFormData({...formData, newPassword2: e.target.value})}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ color:style.color4}}
                                    
                                  >
                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="New Password Again"
                            />
                          </FormControl>
                        </div>


                        


                        <div style={{display:'flex', flexDirection:'row', maxWidth:'800px', margin:'auto'}}>
                        <div style={{marginTop:'60px', marginBottom:'50px', display:'flex', flex:1, width:'90%'}}>
                            <Button onClick={submitUpdate} sx={{width:'90%', maxWidth:'300px',my: 2, flex:1, minWidth:'150px', color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', margin:'auto', marginTop:'0px', padding:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Submit</Button>
                        </div>

                        </div>

                        </div>

                           </> )}



                    </div>

  </>
    
  );
};

