import {React, useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios'
import {toast} from 'react-hot-toast'
import { useStyle } from "../../context/style_context/styleContext";
import{UserContext} from '../../context/userContext'
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useNavigate, Link} from 'react-router-dom';
import { Checkbox, TextField, FormCOntrol, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel, FormHelperText } from "@mui/material";
import TermsModal from "../../pages/terms/TermsModal"
import Terms from "../../pages/terms/Terms";




const LoginSubscribePromptModal = ({ isOpen, close, children }) => {

    const { style, setStyle } = useStyle();
    const { setUser } = useContext(UserContext);
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [showRegisterPassword1, setShowRegisterPassword1] = useState(false);
    const [showRegisterPassword2, setShowRegisterPassword2] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const { setUpdateFlag } = useContext(UserContext);
    const [passwordHelper, setPasswordHelper] = useState({ message: '', color: 'red' });
    const [showPassword2, setShowPassword2] = useState(false);
    const navigate = useNavigate()
    const { user, userEmail, userFirstName, userLastName } = useContext(UserContext);
    const [confirmPasswordHelper, setConfirmPasswordHelper] = useState({ message: '', color: '' });
    const [checkBoxChecked, setCheckBoxChecked] = useState(false)
    const [okToLogin, setOkToLogin] = useState(false)
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const[data,setData] = useState({
        email: '',
        password: '',
        credType:'cookie',
        registerEmail:"",
        registerPassword1:"",
        registerPassword2:"",
    })

    const [showLogin, setShowLogin] = useState(true)
  
    if (!isOpen) return null;

    
    const closeFunction = async (e) => { 

                close() 

    }
    
    const closeModal = () => setModalOpen(false);

    const handleClickShowLoginPassword = () => setShowLoginPassword((show) => !show);
    const handleClickShowRegisterPassword1 = () => setShowRegisterPassword1((show) => !show);
    const handleClickShowRegisterPassword2 = () => setShowRegisterPassword2((show) => !show);
    const handleMouseDownLoginPassword = (event) => {event.preventDefault();};
    const handleMouseDownRegisterPW1 = (event) => {event.preventDefault();};
    const handleMouseDownRegisterPW2 = (event) => {event.preventDefault();};

    const gotIt = () => {
      
      if(checkBoxChecked)
      {
        console.log("unchecked")
        setCheckBoxChecked(false)
      }
      else{
        console.log("checked")
        setCheckBoxChecked(true)
      }

    }

    const handlePasswordChange = (e) => {
      const registerPassword1 = e.target.value;


      setData({ ...data, registerPassword1 });
      
      console.log("password changed: ", registerPassword1)
      if(registerPassword1.length < 1) {
        setPasswordHelper({message:'', color:'green'})
      }  
      else if (registerPassword1.length >= 6 && registerPassword1.length > 0) {
        setPasswordHelper({ message: 'Password OK', color: 'green' });
      } else {
        setPasswordHelper({ message: 'Password must be 6 characters or more', color: 'red' });
      }


    };



    const handleConfirmPasswordChange = (e) => {
      const registerPassword2 = e.target.value;
      setData({ ...data, registerPassword2 });
  
      if(data.registerPassword1.length < 1)
      {
          setConfirmPasswordHelper({message: '', color:'red'})
      }
      else if(registerPassword2.length < 1)
      {
          setConfirmPasswordHelper({message: '', color:'red'})
      }
      else if (registerPassword2 !== data.registerPassword1) {
        setConfirmPasswordHelper({ message: 'Passwords do not match', color: 'red' });
      } else {
        setConfirmPasswordHelper({ message: 'Passwords match', color: 'green' });
      }
    };




    const switchToRegister = () => {


      if(showLogin)
      {
        setShowLogin(false)
      }
      else
      {
        setShowLogin(true)
      }

        console.log("SWITCH TO REGISTER")
        console.log(showLogin)

    }

    const switchToLogin = () => {}

    


    

    const navToStripe = () => {
      window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
    }

    const loginUser = async (e) =>
        {
            //console.log("logging in...")
            e.preventDefault()
            console.log("Logging In")
            
    
            const {email, password, credType} = data
            console.log("data to send: ", email, " ", password, " ", credType )
            
          const lowerCaseEmail = email.toLowerCase();

            try{            
                const{data} = await axios.post('/login', {
                    email:lowerCaseEmail, 
                    password,
                    credType,
                });
                const userData = data;
                if(data.error){
                    console.log("ERROR", data.error)
                    toast.error(data.error)
            }   else {            
                    await setUser(userData);
                    console.log("SUCCESS!")
                    toast.success(data.success)
                    await setUpdateFlag()
                    close()
                    
                  //  window.location.href = `https://buy.stripe.com/test_fZe2bz1OL84P2VGcMP?prefilled_email=${lowerCaseEmail}`
                    window.location.href =  `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${lowerCaseEmail}`
            }
            }catch(error){
                console.log(data.error)
                //toast.error(responseData.error)
            }
            //axios.get('/')
            //console.log("end navigate function firing..")
            // await setUpdateFlag()
            // navigate('/')
            console.log("SAILING AWAY......")
            //window.location.href =  `https://buy.stripe.com/test_fZe2bz1OL84P2VGcMP?prefilled_email=${lowerCaseEmail}`
        }

        const registerUser = async (e) => {
          e.preventDefault();
          var okToSend = true;
      
          const { registerEmail, registerPassword1, registerPassword2 } = data;
          const credType = 'cookie'
      
          console.log(registerEmail)
          console.log(registerPassword1)
          console.log(registerPassword2)

          if (!registerEmail) {
            okToSend = false;
            toast.error("Error: You must enter your E-Mail address to create an account");
          }
      
          if (!registerPassword1) {
            okToSend = false;
            toast.error("Error: Password cannot be blank");
          }
      
          if (!registerPassword2) {
            okToSend = false;
            toast.error("Error: Please enter your password again");
          }
      
          if (registerPassword1 !== registerPassword2) {
            okToSend = false;
            toast.error("Passwords do not match");
          }
      
          if (okToSend) {
            try {
      
      
      
              const { data } = await axios.post('/register', { email:registerEmail, password:registerPassword1 });
      
              if (data.error) {
                toast.error(data.error);
              } else {
                setData({});
                const password = registerPassword1;
                console.log(registerPassword1)
      
                const{data} = await axios.post('/login', {
                  email:registerEmail, 
                  password,
                  credType,
                });
                const userData = data;
                if(data.error){
      
                  toast.error(data.error)
              }   else {            
      
                  await setUser(userData);
      
                  toast.success(data.success)
                  await setUpdateFlag()
                  //window.location.href = `/`
          }
      
      
      
                toast.success('Registration Successful. Welcome!');
                window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${registerEmail.toLowerCase()}`
              }
      
      
      
      
      
      
            } catch (error) {
              console.log(error);
            }
          }
        };
        

        return ReactDOM.createPortal(


    
            <div style={modalStyle}>
              <div style={{padding: '20px',
                    borderRadius: '15px',
                    maxWidth: '800px',
                    minHeight: '300px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    borderColor:'#535bf2',
                    borderWidth:'2px',
                    borderStyle:'solid',
                    backgroundColor: style.color1,}}>
                <div style={{width:'90%', margin:'auto'}}>
                    
                            <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'95%', margin:'auto'}}>
                                
                                
                                
                                {showLogin && (<div> 



                                  <>
                                    <div style={{marginBottom:'10px', marginTop:'0px'}}>
                                      <h1 style={{marginBottom:'10px'}}>
                                      Login to your account to continue.
                                      </h1>
                                     <div style={{marginBottom:'35px'}}>
                                      Not yet a member? 
                                      <Button onClick={switchToRegister} style={{color:style.color4, fontSize:'14pt'}}>Create a New Account</Button>
                                      </div>
                                  
                                      <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', minWidth:'300px', maxWidth:'500px', margin:'auto', marginBottom:'10px'}}>
                                            <h4 style={{flex:1, textAlign:'left', margin:'auto', color:style.color5}}> </h4>
                                            <div style={{marginBottom:'5px', display:'flex', flex:1, width:'100%'}}>
                                            <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            variant="outlined"
                                            value= {data.email}
                                            onChange={(e) => setData({...data, email: e.target.value})}
                                            size='small'
                                            fullWidth
                                            
                                            sx={{
                                                width:'100%',
                                                backgroundColor: style.color10,
                                                borderRadius:'4px',
                                                marginBottom:'25px',
                                                '& .MuiInputBase-input': {
                                                color: style.color5,  // Change the font color here
                                                fontSize: '16pt',  // Change the font size here
                                                                                
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: style.color4, // Change the border color if needed
                                                    borderWidth:'2px'
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: style.color4, // Change the border color on hover if needed\
                                                    borderWidth:'3px',                                                               
                                            
                                                    
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: style.color4, // Change the border color when focused if needed
                                                    borderWidth:'3px'
                                                    
                                                },
                                                '&.Mui-disabled': {
                                                    '& fieldset': {
                                                    borderColor: style.color4, // Change the border color when disabled
                                                    },
                                                },
                                                },
                                                '& .MuiInputLabel-root': {
                                                color: style.color7, // Change the label color here
                                                fontSize:'16pt',
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                color: style.color5, // Change the label color when focused here                                 
                                                },
                                                '& .MuiInputLabel-root.Mui-disabled': {
                                                color: style.color7, // Change the label color when disabled
                                                },
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                '& input': {
                                                    color: style.color4, // Change the input font color when disabled // so it works in safari
                                                    WebkitTextFillColor: style.color4, // so it works on chrome
                                                    fontSize:'16pt'
                                                },
                                                },
                                                '& .MuiOutlinedInput-input': { color: 'white',
                                                '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                                                    WebkitTextFillColor: style.color7, color:style.color7
                                                }, }, 
                                            }}
                                            />
                                            

                                            </div>

                                            <div style={{display:'flex', flex:1, width:'100%'}}>                        
                                                    <FormControl sx={{
                                                            backgroundColor: style.color10,
                                                            flex:1,
                                                            margin:'auto',
                                                            size:'small',
                                                            '& .MuiInputBase-input': {
                                                            color: 'white',  // Change the font color here
                                                            fontSize: '16pt',  // Change the font size here
                                                            marginBottom: '5px',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: style.color4, // Change the border color if needed
                                                                borderWidth:'2px'
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: style.color4, // Change the border color on hover if needed
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: style.color4, // Change the border color when focused if needed
                                                            },
                                                            '&.Mui-disabled': {
                                                                '& fieldset': {
                                                                borderColor: style.color4, // Change the border color when disabled
                                                                },
                                                            },
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                            color: style.color7, // Change the label color here
                                                            fontSize:'14pt',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                            color: style.color5, // Change the label color when focused here
                                                            },
                                                            '& .MuiInputLabel-root.Mui-disabled': {
                                                            color: style.color5, // Change the label color when disabled
                                                            },
                                                            '& .MuiInputBase-root.Mui-disabled': {
                                                            '& input': {
                                                                color: style.color5, // Change the input font color when disabled // so it works in safari
                                                                WebkitTextFillColor: style.color5, // so it works on chrome
                                                                fontSize:'18pt'
                                                            },
                                                            },
                                                            '& .MuiOutlinedInput-input': { color: 'white',
                                                            '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                                                            WebkitTextFillColor: style.color7, color:style.color7
                                                }, }, 
                                                        }} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                        <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showLoginPassword ? 'text' : 'password'}
                                                        value= {data.password}
                                                        size={'small'}
                                                        onChange={(e) => setData({...data, password: e.target.value})}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowLoginPassword}
                                                                onMouseDown={handleMouseDownLoginPassword}
                                                                edge="end"
                                                                sx={{ color:style.color4}}
                                                            >
                                                                {showLoginPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Password"
                                                        />
                                                    </FormControl>
                                                    
                                                    </div>
                                        </div>                                        
                                        </div>
                                        {(data.email && data.password ) &&(
                                        <div style={{marginTop:'50px', display:'flex', flex:1}}>
                                          <Button onClick={loginUser} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Login</Button>
                                        </div>
                                        )}
                                        {(!data.email || !data.password ) &&(
                                        <div style={{marginTop:'50px', display:'flex', flex:1}}>
                                          <Button disabled onClick={loginUser} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Login</Button>
                                        </div>
                                        )} 
                                        <div style={{ marginTop:'10px'}}>
                                        <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color2, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Go Back</Button>
                                        </div>
        
        
                                   
        
                                      </>





                                </div>)}

                                {!showLogin && (

<div>
<div style={{ maxWidth: '400px', margin: 'auto'}}>
              <div style={{ display: 'flex', flex: 1, flexDirection:'column', textAlign: 'center', minWidth: '300px', margin: 'auto'}}>
            <div>


              <h1 style={{marginBottom:'10px'}}>Create Your Account</h1>
              </div>

                                     <div style={{marginBottom:'25px'}}>
                                      Already have an account? 
                                      <Button onClick={switchToRegister} style={{color:style.color4, fontSize:'14pt'}}>Login</Button>
                                      </div>
                <h4 style={{ flex: 1, textAlign: 'left', margin: 'auto', color: style.color5 }}>
                  <div style={{display:'flex', flex:1, marginBottom: '35px', minWidth:'400px'}}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      width='100%'
                      value={data.registerEmail}
                      onChange={(e) => setData({ ...data, registerEmail: e.target.value })}
                      size='small'
                      fullWidth
                      sx={{
                        backgroundColor: style.color10,
                        borderRadius: '4px',
                        '& .MuiInputBase-input': {
                          color: style.color5,
                          fontSize: '16pt',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: style.color4,
                            borderWidth: '2px'
                          },
                          '&:hover fieldset': {
                            borderColor: style.color4,
                            borderWidth: '3px',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: style.color4,
                            borderWidth: '3px'
                          },
                          '&.Mui-disabled': {
                            '& fieldset': {
                              borderColor: style.color4,
                            },
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: style.color7,
                          fontSize: '16pt',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: style.color5,
                        },
                        '& .MuiInputLabel-root.Mui-disabled': {
                          color: style.color7,
                        },
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& input': {
                            color: style.color4,
                            WebkitTextFillColor: style.color4,
                            fontSize: '16pt'
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          color: 'white',
                          '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                            WebkitTextFillColor: style.color7, color: style.color7
                          },
                        },
                      }}
                    />
                  </div>
                </h4>
              </div>

              <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginBottom: '30px', width: '100%', backgroundColor:style.color9  }}>
                <FormControl sx={{
                  backgroundColor: style.color10,
                  flex: 1,
                  size: 'small',
                  width:'100%',
                  margin: 'auto',
                  '& .MuiInputBase-input': {
                    color: 'white',
                    fontSize: '16pt',
                    size: 'small',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: style.color4,
                      borderWidth: '2px'
                    },
                    '&:hover fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-disabled': {
                      '& fieldset': {
                        borderColor: style.color4,
                      },
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: style.color7,
                    fontSize: '14pt',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5,
                  },
                  '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color5,
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                      color: style.color5,
                      WebkitTextFillColor: style.color5,
                      fontSize: '18pt'
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'white',
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                      WebkitTextFillColor: style.color7, color: style.color7
                    },
                  },
                }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword1 ? 'text' : 'password'}
                    value={data.registerPassword1}
                    size='small'
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownRegisterPW1}
                          edge="end"
                          sx={{ color: style.color4 }}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <FormHelperText sx={{ color: passwordHelper.color, backgroundColor: style.color1, fontSize: '12pt' }}>
                    {passwordHelper.message}
                  </FormHelperText>
              </div>

              <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginBottom: '30px', width: '100%', backgroundColor:style.color9  }}>
                <FormControl sx={{
                  backgroundColor: style.color10,
                  flex: 1,
                  width:'100%',
                  margin: 'auto',
                  size: 'small', 
                  '& .MuiInputBase-input': {
                    color: 'white',
                    fontSize: '16pt',
                    size: 'small',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: style.color4,
                      borderWidth: '2px'
                    },
                    '&:hover fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: style.color4,
                    },
                    '&.Mui-disabled': {
                      '& fieldset': {
                        borderColor: style.color4,
                      },
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: style.color7,
                    fontSize: '14pt',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5,
                  },
                  '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color5,
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                      color: style.color5,
                      WebkitTextFillColor: style.color5,
                      fontSize: '18pt'
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'white',
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                      WebkitTextFillColor: style.color7, color: style.color7
                    },
                  },
                }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password Again</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword2 ? 'text' : 'password'}
                    value={data.registerPassword2}
                    onChange={handleConfirmPasswordChange}
                    size={'small'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownRegisterPW2}
                          edge="end"
                          sx={{ color: style.color4 }}
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password Again"
                  />
                </FormControl>
                <FormHelperText sx={{ color: confirmPasswordHelper.color, backgroundColor: style.color1, fontSize: '12pt' }}>
                    {confirmPasswordHelper.message}
                  </FormHelperText>
              </div>


              
                <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flex: 1 }}>
                  <Button onClick={registerUser} sx={{ my: 2, color: style.color5, display: 'block', backgroundColor: style.color4, fontSize: '14pt', width: '100%', minWidth: '250px', maxWidth:'300px', margin: 'auto', marginTop: '0px', borderColor: style.color4, borderWidth: '2px', borderStyle: 'solid', '&:hover': { backgroundColor: style.color4 }, }}>Create Account</Button>
                 </div>
                 <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flex: 1 }}>

                  <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color2, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Go Back</Button>
                </div>
              
              {/* {(data.password.length < 6 || data.password != data.password2) && (
                <div style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', flex: 1 }}>
                  <Button disabled onClick={registerUser} sx={{ my: 2, color: style.color5, display: 'block', backgroundColor: style.color3, fontSize: '16pt', width: '100%', minWidth: '250px', margin: 'auto', marginTop: '0px', borderColor: style.color4, borderWidth: '2px', borderStyle: 'solid', '&:hover': { backgroundColor: style.color4 }, }}>Create Account</Button>
                </div>
              )} */}

              <div style={{ flex: 1, borderBottomLeftRadius: '15px', color: style.color5, marginBottom: '25px' }}>
                By using this website, you agree to our
                <div>
                  <Button sx={{ color: style.color4, marginTop: '5px' }} onClick={setModalOpen.bind(null, true)}>terms and conditions</Button>
                </div>
              </div>

              <div id='modal-root'>
                <TermsModal isOpen={isModalOpen} close={closeModal}>
                  <Terms />
                </TermsModal>
              </div>
            </div>


</div>




                                )}


                                  
                                    
        
                            </div>
                   
                    
                </div>
              </div>
        
            </div>,
            document.getElementById('modal-root')
          );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};


const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default LoginSubscribePromptModal;
