
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';



export default function About(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

    ReactGA.send({
      hitType:"pageview",
      page:"about",
      title:"about",
    })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | Contact';
  }, []);



    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', minHeight:'68vh', margin:'auto'}}>
          <div style={{textAlign:'left', color: style.color5,}}>
            <h1 style={{ marginTop:'50px', marginBottom:'50px'}}> nice to meet you!</h1>
            <p>
                My name is Nate, I am the founder of Brainwave Sound Lab. I am also the author of the software we sell. I have always had a passion for music and audio production, and I love tinkering with different sounds and effects. I have a long history playing music and recording (going back to the mid 90's). In 2023 I began writing my own audio plugins to use in my personal projects, and I decided to offer them to the public. I hope you enjoy!
            </p>
            </div>
          </div>
        </>

    )
}