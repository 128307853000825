import { React, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStyle } from '../../context/style_context/styleContext';
import axios from 'axios';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';
import OrderConfirmationModal from '../../pages/checkoutPage/ConfirmaionModal';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { style } = useStyle();
  const { cartItems, clearShoppingCart, cartSubtotal } = useContext(ShoppingCartContext);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const openConfirmationModal = () => setConfirmationModalOpen(true);
  const closeConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var paid = false;
    var message = "";

    try {
      if (!stripe || !elements) {
        return;
      }

      setIsProcessing(true);

      const cardNumberElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
        return;
      }

      console.log("Payment Method ID: ", paymentMethod.id);

      const response = await axios.post('/api/create_payment_intent',
        {
          paymentMethodId: paymentMethod.id,
          totalAmount: Math.round(cartSubtotal * 100)
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      const paymentIntentResponse = await response.data;
      message = paymentIntentResponse.message;

      if (paymentIntentResponse.error) {
        setErrorMessage(paymentIntentResponse.error.message);
        setIsProcessing(false);
        toast.error(paymentIntentResponse.message);
      } else {
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          paymentIntentResponse.clientSecret
        );

        if (confirmError) {
          setErrorMessage(confirmError.message);
          toast.error(confirmError.message);
        } else if (paymentIntent.status === 'succeeded') {
          paid = true;
          finalizeTransaction();
          clearShoppingCart();
          openConfirmationModal();
        }

        setIsProcessing(false);
      }

    } catch {
      if (!paid) {
        toast.error("ERROR: We were unable to process your payment - Card Declined.");
        setIsProcessing(false);
      } else {
        setIsProcessing(false);
      }
    }
  };

  const finalizeTransaction = async () => {
    console.log("Attempting to create license...");
    try {
      const { data } = await axios.post('/api/finalize_transaction', { products: cartItems, total: cartSubtotal, salesTax: 0 });
      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success("TRANSACTION FINALIZED!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '20pt',
        color: 'white',
        backgroundColor: style.color10,
        padding: '5px',
        '::placeholder': {
          color: 'grey',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: 'auto', backgroundColor: style.color, width:'95%', maxWidth:'300px' }}>
        <div style={{ marginBottom: '15px' }}>
          <label>Card Number</label>
          <CardNumberElement options={cardElementOptions} />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label>Expiration Date</label>
          <CardExpiryElement options={cardElementOptions} />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label>CVC</label>
          <CardCvcElement options={cardElementOptions} />
        </div>

        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

        <Button
          type="submit"
          sx={{
            backgroundColor: style.color4,
            color: style.color5,
            minWidth: '250px',
            fontSize: '1.2em',
            marginTop: "35px",
            marginBottom: "15px"
          }}
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Submit Payment'}
        </Button>

        <div style={{ marginBottom: '25px' }}>
          <Link to={'/shopping-cart'} style={{ color: style.color4 }}>Back To Shopping Cart</Link>
        </div>

        <div id='modal-root'>
          <OrderConfirmationModal isOpen={isConfirmationModalOpen} close={closeConfirmationModal} />
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;




// // CheckoutForm.js
// import {React, useState, useContext } from 'react';
// import {useNavigate, Link} from 'react-router-dom';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { useStyle } from '../../context/style_context/styleContext';
// import axios from 'axios'
// import { ShoppingCartContext } from '../../context/shoppingCartContext';
// import Button from '@mui/material/Button';
// import {toast} from 'react-hot-toast'
// import OrderConfirmationModal from '../../pages/checkoutPage/ConfirmaionModal';


// const CheckoutForm = (openConfermationModal) => {

//   const stripe = useStripe();
//   const elements = useElements();
//   const [errorMessage, setErrorMessage] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const { style } = useStyle();
//   const { cartItems, clearShoppingCart, cartSubtotal } = useContext(ShoppingCartContext);
//   const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
//   const openConfirmationModal = () => setConfirmationModalOpen(true);
//   const closeConfirmationModal = () => setConfirmationModalOpen(false);


//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     var paid = false;
//     var message = "";

//     try{
    
//       if (!stripe || !elements) {
//         return;
//       }

//       setIsProcessing(true);

//       const cardElement = elements.getElement(CardElement);

//       const { error, paymentMethod } = await stripe.createPaymentMethod({
//         type: 'card',
//         card: cardElement,
//       });

//       if (error) {
//         setErrorMessage(error.message);
//         setIsProcessing(false);
//         return;
//       }


//       console.log("Payment Method ID: ", paymentMethod.id)
      

//       const response = await axios.post('/api/create_payment_intent', 
//         {
//           paymentMethodId: paymentMethod.id,
//           totalAmount: Math.round(cartSubtotal * 100)
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           }
//         }
//       );

//       const paymentIntentResponse = await response.data; 
//       message = paymentIntentResponse.message;     

//       if (paymentIntentResponse.error) {
//         setErrorMessage(paymentIntentResponse.error.message);
//         setIsProcessing(false);
//         toast.error(paymentIntentResponse.message)
//       } else {
//         // Confirm the payment
//         const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
//           paymentIntentResponse.clientSecret
//         );

//         if (confirmError) {
//           setErrorMessage(confirmError.message);
//           toast.error(confirmError.message)
//         } else if (paymentIntent.status === 'succeeded') {
//           paid = true;
//           finalizeTransaction();
//           clearShoppingCart();
//           const openModal = openConfirmationModal();
//         }

//         setIsProcessing(false);
//       }

//     }

//     catch{
//       if(!paid)
//       {
//         toast.error("ERROR: We were unable to process your payment - Card Declined.")
//         setIsProcessing(false);
//       }
//       else
//       {
//         //toast.error('ERROR! We have encountered an internal server error while generating the licenses for your purchase. This issue will normally be corrected automatically within approximately 5 - 10 minutes. Please contact customer service if the issue is not resolved automatically, and we would be happy to help you.')
//         setIsProcessing(false);
//       }
      
//     }

//   };

//   const finalizeTransaction = async (e) => {
//     console.log("Attempting to create license... ")        
//     try{        
//         const{data} = await axios.post('/api/finalize_transaction', {products: cartItems, total: cartSubtotal, salesTax: 0})
       
//     if(data.error){
//         toast.error(data.error)
//     }
//     else{   

//       toast.success("TRANSACTION FINALIZED!")
//         //setSkuList(listOfSkus)       

//     }
//     }catch(error){
      
//         console.log(error)
//     }
// }


//   const cardStyle = {
//     style: {
//       base: {
//         fontSize: '20pt',  // Set the font size to 16pt
//         color: 'white',    // Set the text color to orange
//         backgroundColor:style.color10,
//         width:'50%', 
//         maxWidth:'500px',
//         marginLeft:'50px',
//         padding:'5px',
//         borderColor:style.color4,
//         borderWidth:'1px',
//         borderStyle:'solid',
//         marginTop:'20px',
        

//         padding:'5px',
//         '::placeholder': {
//           color: 'grey',    // You can also style the placeholder text
//         },
//       },
//       invalid: {
//         color: 'red',       // Style for invalid card information
//       },
//     },
//   };





//   return (
//    <>
   
//   <form onSubmit={handleSubmit} style={{ margin:'auto', backgroundColor:style.color2}}>
//     <div style={{width:'80%', maxWidth:'800px', maxHeight:'40px', margin:'auto', backgroundColor:style.color10, paddingTop:'10px', paddingBottom:'10px', paddingLeft:'10px',  paddingRight:'10px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid'}}>
//     <CardElement options={cardStyle}/>
//       {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
//       </div>
//       <div>
//       <Button type="submit" sx={{backgroundColor:style.color4, color:style.color5, minWidth:'250px', fontSize:'1.2em', marginTop:"50px", marginBottom:"25px"}}disabled={!stripe || isProcessing}>
//         {isProcessing ? 'Processing...' : 'Submit Payment'}
//       </Button>
//       </div>
//       <div style={{marginBottom:'25px'}}>
//         <Link to={'/shopping-cart'} style={{color:style.color4}}>Back To Shopping Cart</Link>
//       </div>

//       <div id='modal-root'>
//         <OrderConfirmationModal isOpen={isConfirmationModalOpen} close={closeConfirmationModal}>
            
//         </OrderConfirmationModal>
//     </div>

//     </form>
    
//     </> 
//   );
// };

// export default CheckoutForm;
