import axios from 'axios';
import { createContext, useState, useEffect, useContext } from 'react';

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
    const [user, setUserState] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const [updateFlag, setUpdateFlag] = useState(false);
    const [userEmail, setUserEmail] = useState(false);
    const [userFirstName, setUserFirstName] = useState(false);
    const [userLastName, setUserLastName] = useState(false);
    const [userShoppingCart, setUserShoppingCart] = useState([]);
    

    const setUser = async (userData) => {        
        setLoading(true)     
        setUserState(userData);
        if(userData)
        {
            setUserEmail(userData.email)
            setUserFirstName(userData.firstName)
            setUserLastName(userData.lastName)
            setUserShoppingCart(userData.ShoppingCart)
        }
        else
        {
            setUserEmail("")
            setUserFirstName("")
            setUserLastName("")
            setUserShoppingCart("")
        }       

        return new Promise((resolve) => {

            resolve();
            setLoading(false);

        });
    };







    useEffect(() => {
            setLoading(true)

        axios.get('/profile').then(({ data }) => {
            //console.log(data)
            //console.log("user data: ")
            //console.log(data);
            setUser(data);

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });

    }, []);

    const logout = async () => {
        try {
            const response = await axios.get('/logout', { withCredentials: true });
            console.log(response.data.message);  // Log the server response message
            setUser(null);  // Clear user context
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, isLoading, logout, updateFlag, setUpdateFlag, userEmail, setUserEmail, userFirstName, setUserFirstName, userLastName, setUserLastName, userShoppingCart, setUserShoppingCart }}>
            {children}
        </UserContext.Provider>
    );
}

