
import {React, useState, useContext, useEffect} from "react"
import { Link } from "react-router-dom"
import { UserContext } from "../../context/userContext";
import { useStyle } from '../../context/style_context/styleContext';


import ReactGA from 'react-ga4';
import axios from 'axios'
import {toast} from 'react-hot-toast'
import Button from '@mui/material/Button';




export default function AdminDashboard(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const {email, updateFlag, setUpdateFlag} = useContext(UserContext);
  const { style, setStyle } = useStyle();


  useEffect(() => {
    document.title = 'Brainwave Sound Lab | Admin Dashboard';
}, []);


  const[data,setData] = useState({
    email: '',
    password: '',
    credType:'cookie'
})

    ReactGA.send({
      hitType:"pageview",
      page:"Admin_Dashboard",
      title:"Admin_Dashboard",
    })

  const changeBackground = () => {
    setStyle({ ...style, backgroundColor: 'rgb(0,255,0)' });
  };

  const fireFunction = async (e) =>
    {
        const loading = user.isLoading
        console.log("FIRE FUNCTION! Loading: ", email)
        console.log(isLoading)
        console.log(updateFlag)
        }

    const flip = async (e) =>
        {
            if(updateFlag)
            {
                await setUpdateFlag(false)
            }
            else{
                await setUpdateFlag(true)
            }


        }

        
        const createSubscriptionType = async (e) =>
          {

            console.log("firing create function")
            const{data} = await axios.post('/auth/create_subscription_type',{})
            console.log(data)
            if(data.error){
              console.log("error")
              toast.error(data.error)
          }
          else{
              //setData({})
              toast.success('Success! Product created!')
              //navigate('/home')
          }


          }

  const testFunction = async (e) =>
    {
       console.log("test")
    }

    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'100%', margin:'auto', textAlign:'center', minHeight:'70vh'}}>
            <div style={{margin:'auto'}}>
                <h1>
                Admin Dashboard
                </h1>
            </div>

            <div>
                <h3>
                <Link to = '/admin/create_product' style={{color:style.color4}}> Create Product</Link>
            </h3>
            <h2> click to <a href={`https://buy.stripe.com/test_fZe2bz1OL84P2VGcMP?prefilled_email=${user.email}`}> Subscribe</a></h2>
                
            </div>
            <div>
              <a href='/Infinity-Reverb-v1_0_0-Windows-Installer.exe' download style={{color:style.color4, fontWeight:'bold', marginLeft:'10px' }}>Windows</a>
            </div>
            <div style={{margin:'auto'}}>
                    <Button sx={{color:style.color4, marginTop:'5px'}}onClick={createSubscriptionType}>Create Subscription Type</Button>
                </div>

          <div style={{margin:'auto'}}>
                    <Button sx={{color:style.color4, marginTop:'5px'}}onClick={testFunction}>TEST</Button>
                </div>
                <div style={{margin:'auto'}}>
                    <Button sx={{color:style.color4, marginTop:'5px'}}onClick={flip}>Flip</Button>
                </div>


<div>
    <a href='/Infinity-Reverb-Windows.exe' download>Click to download Infinity Reverb</a>
</div>
          </div>

        </>

    )
}