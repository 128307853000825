import { Outlet, Navigate } from "react-router-dom";

import {useContext, useState} from "react";
import {UserContext} from '../context/userContext';
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-hot-toast'
import axios from 'axios'


const AdminRoutes = () => {

  const{user, isLoading} = useContext(UserContext);
  
  const [adminCred, setAdminCred] = useState({});
  const [checkedFlag, setCheckedFlag] = useState(false);
  const navigate = useNavigate()




  const getAdminCreds = async (e) => {

         

    
    try{        
        const{data} = await axios.get('/admin/authenticate')
        //console.log(data)
    if(data.error){
        toast.error(data.error)
        setCheckedFlag(true)
        return(null)
    }
    else{
        
        setAdminCred(data) 
        setCheckedFlag(true)
        return(data)         

    }
    }catch(error){
        console.log(error)
        setCheckedFlag(true)
        return(null)
    }
}

if(!checkedFlag)
{

  setCheckedFlag(true)
  getAdminCreds()

}


  if(isLoading) {
    return <div>Loading...</div>
  }
  else{   


    if(!user)
    {    
        
    console.log("USER NOT FOUND! user: ")
          
      return <Navigate to="/login" />;

    }
    if(adminCred)
    {

      //return(<Outlet />)
      return <Navigate to="/" />;
    }

    else{
      

      toast.error("ADMIN CREDENTIALS DENIED")
      toast.error(user.email)
      return <Navigate to="/" />;
    }

}

};

export default AdminRoutes;


// import { Outlet, Navigate } from "react-router-dom";
// import axios from 'axios'
// import {useContext} from "react";
// import {UserContext} from '../context/userContext';
// import {useNavigate} from 'react-router-dom'
// import {toast} from 'react-hot-toast'



// const authCreds = async (e) =>
//   {
//     const{authorizedUser} = await axios.get('/admin/authenticate')
//     return authorizedUser

//   }


// const AdminRoutes = async (e) => {

//   //const{user, isLoading} = useContext(UserContext);
//   //const navigate = useNavigate()

//   console.log("admin auth function fired")
//   const adminUser = authCreds();
  
//   if(!adminUser)

//     //const{authorizedUser} = await axios.get('/admin/authenticate')
    
//     console.log(adminUser)

//     if(adminUser)
//     {
//       return(<Outlet />)
      
//     }
//     else
//     {
//       return <Navigate to="/login" />;
//     }



// };

// export default AdminRoutes;