import {React, useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import{UserContext} from '../../context/userContext'
import Button from '@mui/material/Button';
import {useNavigate, Link} from 'react-router-dom';
import { Checkbox, TextField, FormGroup, FormControlLabel } from "@mui/material";
import Rating from '@mui/material/Rating';
import { useStyle } from "../../context/style_context/styleContext";
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';



const LeaveReviewModal = ({ isOpen, close, sku, children }) => {

    const { style, setStyle } = useStyle();
    const navigate = useNavigate()
    const { user, userEmail, userFirstName, userLastName } = useContext(UserContext);
    const [checkBoxChecked, setCheckBoxChecked] = useState(false)
    const [value, setValue] = useState(5);
    const [reviewComment, setReviewComment] = useState("");
    const [reviewUserName, setReviewUserName] = useState("Anonymous");
    const [reviewSent, setReviewSent] = useState(false)
    
  
    if (!isOpen) return null;

    const closeFunction = async (e) => { 
                         
                setReviewSent(false)
                setValue(5)
                setReviewUserName("Anonymous")
                setReviewComment("")
                close() 

    }



    const submit = async (e) => {

        e.preventDefault();
        console.log("submit...")
        
        
        var okToSend = true;

        
        const productSKU = sku
        const userName = reviewUserName

        console.log("sku:", productSKU)
        
        const starRating = value
        const comment = reviewComment
       


        if(okToSend)
        {
            console.log("sending....")
            try{
                okToSend = false   
                const{data} = await axios.post('/api/create_review',{userName, productSKU, starRating, comment})
                    
    
            if(data.error){
                console.log("error")
                toast.error(data.error)
            }
            else{
                //setData({})
                toast.success('Thank you for your review!')
                setReviewSent(true)
                //navigate('/home')
            }
            }catch(error){
                console.log(error)
            }
        }
    }



        

  return ReactDOM.createPortal(


    
    <div style={modalStyle}>
      <div style={{padding: '0px',
            borderRadius: '15px',
            maxWidth: '800px',
            minHeight: '300px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            borderColor:'#535bf2',
            borderWidth:'2px',
            borderStyle:'solid',
            backgroundColor: style.color1,}}>
        <div style={{width:'100%', margin:'auto', marginTop:'0px'}}>
            

                  {!reviewSent && (

                    <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'100%', margin:'auto'}}>
                        
                        <>
                        <div style={{marginBottom:'5px', marginTop:'0px'}}>
                          <h1 style={{ fontSize:'1.6em'}}>
                            Your Rating:
                          </h1>
                          
                          </div>
                          <div style={{marginBottom:'15px'}}><Rating name="simple-controlled" size={'large'} precision={0.5} value={value} onChange={(event, newValue) => {setValue(newValue);}} icon={<StarIcon sx={{ stroke: style.color8, strokeWidth: 1, width:'40px', height:'40px' }} />}
                                                                               emptyIcon={<StarBorderIcon sx={{ stroke: style.color8, width:'40px', height:'40px'}} />}/></div>

                          <Box
                              component="form"
                              sx={{ '& .MuiTextField-root': { m: 1, width: '95%', maxWidth:'1000px' } }}
                              noValidate
                              autoComplete="off"
                              >
                                <div style={{ width:'95%', margin:'auto'}}>

                                <TextField
                                  id="standard-multiline-static"
                                  label="Your Name:"
                                  value={reviewUserName}
                                  onChange={(e) => setReviewUserName(e.target.value)}
                              
                                  variant="outlined"

                                          
                                          sx={{
                                              width:'95%',                                                
                                              backgroundColor: style.color10,
                                              borderRadius:'4px',

                                              marginBottom:'25px',
                                              '& .MuiInputBase-input': {
                                              color: style.color5,  // Change the font color here
                                              fontSize: '14pt',  // Change the font size here
                                                                              
                                              },
                                              '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                  borderColor: style.color4, // Change the border color if needed
                                                  borderWidth:'2px',
                                                  borderStyle:'solid'
                                              },
                                              '&:hover fieldset': {
                                                  borderColor: style.color4, // Change the border color on hover if needed\
                                                  borderWidth:'3px',                                                               
                                          
                                                  
                                              },
                                              '&.Mui-focused fieldset': {
                                                  borderColor: style.color4, // Change the border color when focused if needed
                                                  borderWidth:'3px'
                                                  
                                              },
                                              '&.Mui-disabled': {
                                                  '& fieldset': {
                                                  borderColor: style.color4, // Change the border color when disabled
                                                  },
                                              },
                                              },
                                              '& .MuiInputLabel-root': {
                                              color: style.color7, // Change the label color here
                                              fontSize:'16pt',
                                              },
                                              '& .MuiInputLabel-root.Mui-focused': {
                                              color: style.color7, // Change the label color when focused here                                 
                                              },
                                              '& .MuiInputLabel-root.Mui-disabled': {
                                              color: style.color7, // Change the label color when disabled
                                              },
                                              '& .MuiInputBase-root.Mui-disabled': {
                                              '& input': {
                                                  color: style.color4, // Change the input font color when disabled // so it works in safari
                                                  WebkitTextFillColor: style.color4, // so it works on chrome
                                                  fontSize:'16pt'
                                              },
                                              },
                                              '& .MuiOutlinedInput-input': { color: 'white',
                                              '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                                                  WebkitTextFillColor: style.color7, color:style.color7
                                              }, }, 
                                          }}
                                          />


                                </div>
                              <div style={{ width:'95%', margin:'auto'}}>

                                  <TextField
                                  id="standard-multiline-static"
                                  label="Your Review:"
                                  value={reviewComment}
                                  onChange={(e) => setReviewComment(e.target.value)}
                                  multiline
                                  rows={5}                                    
                                  variant="outlined"

                                          
                                          sx={{
                                              width:'90%',

                                              backgroundColor: style.color10,
                                              borderRadius:'4px',
                                              marginBottom:'25px',
                                              '& .MuiInputBase-input': {
                                              color: style.color5,  // Change the font color here
                                              fontSize: '14pt',  // Change the font size here
                                                                              
                                              },
                                              '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                  borderColor: style.color4, // Change the border color if needed
                                                  borderWidth:'2px',
                                                  borderStyle:'solid'
                                              },
                                              '&:hover fieldset': {
                                                  borderColor: style.color4, // Change the border color on hover if needed\
                                                  borderWidth:'3px',                                                               
                                          
                                                  
                                              },
                                              '&.Mui-focused fieldset': {
                                                  borderColor: style.color4, // Change the border color when focused if needed
                                                  borderWidth:'3px'
                                                  
                                              },
                                              '&.Mui-disabled': {
                                                  '& fieldset': {
                                                  borderColor: style.color4, // Change the border color when disabled
                                                  },
                                              },
                                              },
                                              '& .MuiInputLabel-root': {
                                              color: style.color7, // Change the label color here
                                              fontSize:'16pt',
                                              },
                                              '& .MuiInputLabel-root.Mui-focused': {
                                              color: style.color7, // Change the label color when focused here                                 
                                              },
                                              '& .MuiInputLabel-root.Mui-disabled': {
                                              color: style.color7, // Change the label color when disabled
                                              },
                                              '& .MuiInputBase-root.Mui-disabled': {
                                              '& input': {
                                                  color: style.color4, // Change the input font color when disabled // so it works in safari
                                                  WebkitTextFillColor: style.color4, // so it works on chrome
                                                  fontSize:'16pt'
                                              },
                                              },
                                              '& .MuiOutlinedInput-input': { color: 'white',
                                              '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                                                  WebkitTextFillColor: style.color7, color:style.color7
                                              }, }, 
                                          }}
                                          />
                              </div>
                              </Box>


                          
                          <div style={{ marginTop:'35px'}}>
                          <Button onClick={submit} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color2, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Submit</Button>
                          </div>
                          <div style={{ marginTop:'10px', marginBottom:'25px'}}>
                          <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color2, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Go Back</Button>
                          </div>

                            </>
                          

                  </div>

                  )}

                  {reviewSent && (
                    <>
                    <div style={{margin:'auto', textAlign:'center',  marginTop:'50px', marginBottom:'100px'}}><h1>
                      Thank you!
                      </h1></div>
                      <div>
                        <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color2, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Go Back</Button>
                      </div>
                    </>

                    
                  )}

                    
           
            
        </div>
      </div>

    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};


const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default LeaveReviewModal;
