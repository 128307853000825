import {React, useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import { useStyle } from "../../context/style_context/styleContext";
import axios from 'axios'
import ReactGA from 'react-ga4';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {toast} from 'react-hot-toast'

// import IconButton from "@material-ui/core/IconButton";
// import InputLabel from "@material-ui/core/InputLabel";
// import Visibility from "@material-ui/icons/Visibility";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Input from "@material-ui/core/Input";

const ChangePasswordModal = ({ isOpen, close, children }) => {

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { style, setStyle } = useStyle();
  const [formData, setFormData] = useState({
    newPassword1: "",
    newPassword2: "",
    })
    const [values, setValues] = useState({
      password: "",
      showPassword: false,
  });

  ReactGA.send({
    hitType:"pageview",
    page:"ChangePasswordModal",
    title:"ChangePasswordModal",
  })


  useEffect(() => {
  document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Change Password';
}, []);

  if (!isOpen) return null;




const handlePasswordChange = (prop) => (event) => {
    setValues({
        ...values,
        [prop]: event.target.value,
    });
};

const closeFunction = async (e) => {

setShowConfirm(false)
setFormData({newPassword1:"", newPassword2:""})
close()

}

const submitChanges = async (e) => {

  e.preventDefault();
  var okToSend = true;

  const {newPassword1, newPassword2} = formData



  if(!newPassword1)
      {
          okToSend = false
          toast.error("Error: You must enter a new password if you want to change it")
      }


  if(!newPassword2)
      {
          okToSend = false
          toast.error("Error: Please enter your password again")
      }
    if(newPassword1.length < 6)
      {
          okToSend = false
          toast.error("Password must be at least 6 characters long")
          
      };
      if(newPassword1.length > 1000)
      {
          okToSend = false
          toast.error( "Password must be 1000 characters or less")
          
      };



  if(newPassword1 != newPassword2)
  {
      okToSend = false
      toast.error("Passwords do not match")
  }

  if(okToSend)
  {
    try {
      const { data } = await axios.patch('/change_password', formData);
      if (data.error) {
          toast.error(data.error);
      } else {
          
          toast.success('Password successfully changed!');
          setShowConfirm(true)         
      }
  } catch (error) {
      console.error(error);
      toast.error('Error: Password update failed');
  }
  }
}

 
        

  return ReactDOM.createPortal(


    
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <div style={{backgroundColor:style.color9, width:'90%', margin:'auto'}}>
            
                    <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'90%', margin:'auto'}}>
                        {showConfirm &&(
                          <>
                          <div style={{marginBottom:'75px', marginTop:'0px'}}>
                            <h2>
                              Success! Your password has been successfully changed.
                            </h2>
                            
                            </div>
                            <div>
                            <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>OK</Button>
                              </div>
                              </>
                            )}

{!showConfirm &&(
                        <>
                        <div style={{margin:'auto', textAlign:'center', marginBottom:'50px'}}>
                            <h1>Change Your Password</h1>
                        </div>

                        <div style={{marginBottom:'30px'}}>                        
                          <FormControl sx={{
                                backgroundColor: style.color10,
                                '& .MuiInputBase-input': {
                                color: 'white',  // Change the font color here
                                fontSize: '16px',  // Change the font size here
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: style.color4, // Change the border color if needed
                                    borderWidth:'2px'
                                },
                                '&:hover fieldset': {
                                    borderColor: style.color4, // Change the border color on hover if needed
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: style.color4, // Change the border color when focused if needed
                                },
                                '&.Mui-disabled': {
                                    '& fieldset': {
                                    borderColor: style.color4, // Change the border color when disabled
                                    },
                                },
                                },
                                '& .MuiInputLabel-root': {
                                color: style.color7, // Change the label color here
                                fontSize:'14pt',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                color: style.color5, // Change the label color when focused here
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                color: style.color5, // Change the label color when disabled
                                },
                                '& .MuiInputBase-root.Mui-disabled': {
                                '& input': {
                                    color: style.color5, // Change the input font color when disabled // so it works in safari
                                    WebkitTextFillColor: style.color5, // so it works on chrome
                                    fontSize:'18pt'
                                },
                                },
                            }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword1 ? 'text' : 'password'}
                              value= {formData.newPassword1}
                              onChange={(e) => setFormData({...formData, newPassword1: e.target.value})}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword1}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ color:style.color4}}
                                  >
                                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          </FormControl>
                        </div>

                        <div>                        
                          <FormControl sx={{
                                backgroundColor: style.color10,
                                '& .MuiInputBase-input': {
                                color: 'white',  // Change the font color here
                                fontSize: '16px',  // Change the font size here
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: style.color4, // Change the border color if needed
                                    borderWidth:'2px'
                                },
                                '&:hover fieldset': {
                                    borderColor: style.color4, // Change the border color on hover if needed
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: style.color4, // Change the border color when focused if needed
                                },
                                '&.Mui-disabled': {
                                    '& fieldset': {
                                    borderColor: style.color4, // Change the border color when disabled
                                    },
                                },
                                },
                                '& .MuiInputLabel-root': {
                                color: style.color7, // Change the label color here
                                fontSize:'14pt',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                color: style.color5, // Change the label color when focused here
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                color: style.color5, // Change the label color when disabled
                                },
                                '& .MuiInputBase-root.Mui-disabled': {
                                '& input': {
                                    color: style.color5, // Change the input font color when disabled // so it works in safari
                                    WebkitTextFillColor: style.color5, // so it works on chrome
                                    fontSize:'18pt'
                                },
                                },
                            }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">New Password Again</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword2 ? 'text' : 'password'}
                              value= {formData.newPassword2}
                              onChange={(e) => setFormData({...formData, newPassword2: e.target.value})}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ color:style.color4}}
                                  >
                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="New Password Again"
                            />
                          </FormControl>
                        </div>


                        


                        <div style={{display:'flex', flexDirection:'row', maxWidth:'800px', margin:'auto'}}>
                        <div style={{marginTop:'75px', marginBottom:'30px', display:'flex', flex:1, marginRight:'10px'}}>
                            <Button onClick={submitChanges} sx={{ my: 2, flex:1, minWidth:'150px', color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', margin:'auto', marginTop:'0px', padding:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Submit</Button>
                        </div>
                        <div style={{marginTop:'75px', marginBottom:'30px', display:'flex', flex:1, marginLeft:'10px'}}>
                            <Button onClick={closeFunction} sx={{ my: 2, flex:1, minWidth:'150px', color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', margin:'auto', marginTop:'0px', padding:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Cancel</Button>
                        </div>
                        

                        </div>

                           </> )}



                    </div>
           
            
          <div>
                {/* ****************** NEW STUFF ******************************** */}
                {/* <div
            style={{
                marginLeft: "30%",
            }}
        >
            <h4>
                How to show and hide password in ReactJS?
            </h4>
            <InputLabel htmlFor="standard-adornment-password">
                Enter your Password
            </InputLabel>
            <Input
                type={
                    values.showPassword
                        ? "text"
                        : "password"
                }
                onChange={handlePasswordChange("password")}
                value={values.password}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={
                                handleClickShowPassword
                            }
                            onMouseDown={
                                handleMouseDownPassword
                            }
                        >
                            {values.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div> */}

                {/* ************************************************************** */}

          </div>



        </div>
      </div>

    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};

const modalContentStyle = {
  padding: '20px',
  borderRadius: '15px',
  maxWidth: '800px',
  minHeight: '300px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  borderColor:'#535bf2',
  borderWidth:'2px',
  borderStyle:'solid',
  backgroundColor: 'rgb(58,59,60)',
};

const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default ChangePasswordModal;
