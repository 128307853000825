import {React, useState, useContext, useEffect} from "react";
import {Link, useNavigate} from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useStyle } from "../../context/style_context/styleContext";
import Button from '@mui/material/Button';




const OrderConfirmationModal = ({ isOpen, close, children, message, buttonText }) => {

    const { style, setStyle } = useStyle();
    const navigate = useNavigate()
  
    if (!isOpen) return null;
    
    const closeFunction = async (e) => {            
                close() 
                navigate('/my-gear')

    }



  return ReactDOM.createPortal(


    
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <div style={{backgroundColor:style.color9, width:'90%', margin:'auto'}}>
            
                    <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'90%', margin:'auto'}}>
                        
                          <>
                          <div style={{marginBottom:'75px', marginTop:'0px'}}>
                            <h1 style={{marginBottom:'25px'}}>
                              Thank you for your purchase!
                            </h1>

                            
                                <h3 style={{marginTop:'25px', marginBottom:'25px'}}>
                                    Your products will be available for download in the <Link to={"/my-gear"} style={{color:'white', color:style.color4}}>My Gear</Link> Page.

                                </h3>
                                <p>
                                    If you need assistance with installation, please visit our <Link to ={"/support"} style={{color:'white', color:style.color4}}>Support</Link> Page.
                                </p>
                               
                            
                            </div>
                            <div style={{marginBottom:'5px'}}>
                                Click OK to Proceed to My Gear
                            </div>
                            <div>
                                <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>OK</Button>
                            </div>
                              </>
                            

                    </div>
           
            
        </div>
      </div>

    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};

const modalContentStyle = {
  padding: '20px',
  borderRadius: '15px',
  maxWidth: '800px',
  minHeight: '300px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  borderColor:'#535bf2',
  borderWidth:'2px',
  borderStyle:'solid',
  backgroundColor: 'rgb(58,59,60)',
};

const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default OrderConfirmationModal;
