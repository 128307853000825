import { useState, useEffect, useContext } from "react";
import { useStyle } from "../../context/style_context/styleContext";
import {useNavigate, Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ReactGA from 'react-ga4';
import ProductsTable from "../../components/ProductsTable/ProductsTable";
import ShoppingCartItemRow from "../../components/ShoppingCartItemRow/ShoppingCartItemRow";
import ShoppingCartTable from "../../components/ShoppingCartTable/ShoppingCartTable";
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import CartIcon from '../../icons/shopping_cart3.svg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function ShoppingCart() {
    const { style, setStyle } = useStyle();
    const [products, setProducts] = useState([]); // Corrected initialization as an empty array
    const [productDetails, setProductDetails] = useState([]);
    const navigate = useNavigate()
    const { cartItems, cartSubtotal, removeFromCart } = useContext(ShoppingCartContext);
    const [taxTotal, setTaxTotal] = useState(0);

    const [width, setWidth] = useState(window.innerWidth)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const deleteFromCart = async (productSKU) => {


      console.log("Sending request to cart componant to DELETE..", productSKU)
  
      removeFromCart(productSKU)  
  }
    
    const isMobile = width <= 768;
    //const isMobile = true;

    // Google Analytics pageview tracking
    ReactGA.send({
        hitType: "pageview",
        page: "shopping_cart",
        title: "shopping_cart",
    });

    // Set the document title when the page loads
    useEffect(() => {
        document.title = 'Brainwave Sound Lab | State Of The Art Audio Processing | Shopping Cart';
    }, []);

    const navToCheckout = () => {

      navigate('/checkout')
    }


    return (
        <>
        <div style={{minHeight:'68vh', margin:'auto'}}>


        
        
            {cartItems.length < 1 &&(<>
                    <div style={{textAlign:'center', margin:'auto'}}><h1>Your cart Is currently empty</h1></div>
                    <div style={{textAlign:'center', margin:'auto'}}>

                    <img src={CartIcon} style={{flex:1, height:'250px', maxWidth:'100%', maxHeight:'100%', margin:'auto', textAlign:'center', marginTop:'15px', marginBottom:'15px', paddingRight:'15px'}} />
                    </div>
                    <div style={{textAlign:'center', marginTop:'25px'}}><h3><Link to={'/products'} style={{color:style.color4}}>Return to Shop</Link></h3></div>
                    </>
                  )}


                {/* -----------------   mobile --------------- */}


                {cartItems.length >= 1 && isMobile &&(<>
                  <div style={{ display:'flex', flexDirection:'column', width:'95%', margin:'auto'}}>
                <div style={{display:'flex', flexDirection:'row', width:'95%', maxWidth:'1100px', margin:'auto', marginTop:'30px'}}>
                <h1 style={{fontSize:"18pt"}}>shopping cart:</h1>
              </div>


              <div style={{}}>              
              {cartItems.map((item) => (


                  <div key={item.SKU} style={{ margin:'auto', marginBottom:'15px',display:'flex', minHeight:'200px', backgroundColor:'rgb(25,31,34)', flexDirection:'row', width:'100%', borderColor:style.color3, borderWidth:'1px', borderStyle:'solid', borderRadius:'5px'}}>

                                <div style={{ textAlign:'center', padding:'15px', backgroundColor:'rgb(25,31,34)', margin:'auto', height:'100%'}}>
                                              <a href={"/product/" + item.productURI}>
                                              <img src={item.imageURIs[0]} style={{width:'75px', margin:'auto'}} />
                                              </a>
                                </div>
                                <Card sx={{ backgroundColor:style.color2, color:style.color7, flex:1}}>

                                          <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                            <a href={"/product/" + item.productURI} style={{color:'white'}}>
                                              {item.name}
                                              </a>
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
                                            <h3><strong>${item.price} </strong> </h3>
                                            </Typography>
                                          </CardContent>
                                          <CardActions>
                                             <Button onClick={() => deleteFromCart(item.SKU)} size="small">Remove From Cart</Button>
                                            
                                          </CardActions>
                                        </Card>

                                </div>

                                
                  
                  
                  
                  

  ))}
              

              </div>


                            
                       
                        <div style={{flex:1}}>
                            <table style={{margin:'auto', width:'100%', borderColor:style.color4, borderThickness:'1px', borderStyle:'solid', borderRadius:'5px', padding:'20px'}}>
                              <tbody>
              
                              
                            <tr>
                              <td style={{width:'125px'}}><h3 style={{padding:'5px', margin:'0px'}}>Subtotal:</h3></td>
                              <td><h3 style={{ padding:'5px', margin:'0px', textAlign:'center'}}>$ {parseFloat(cartSubtotal).toFixed(2)}</h3></td>
                            </tr>
                            <tr>
                              <td><h3 style={{padding:'5px', margin:'0px'}}>Sales Tax</h3></td>
                              <td><h3 style={{padding:'5px', margin:'0px', textAlign:'center'}}>$ {parseFloat(taxTotal).toFixed(2)}</h3></td>
              
                            </tr>
                            <tr>
                              <td><h2 style={{padding:'5px', margin:'0px'}}><strong>TOTAL</strong></h2></td>
                              <td><h2 style={{padding:'5px', margin:'0px', textAlign:'center'}}><strong>$ {parseFloat(cartSubtotal + taxTotal).toFixed(2)} </strong></h2></td>
              
                            </tr>
                            </tbody>
                          </table>
              
                          <Button onClick={navToCheckout} sx={{width:'100%', backgroundColor:style.color4, color:'white', marginTop:'25px','&:hover': {backgroundColor: style.color4 }}}> Proceed To Checkout </Button>   </div>
              

  
              
              <div style={{width:'95%', maxWidth:'800px', margin:'auto', marginTop:'75px'}}>
                <p><strong>Once your order is complete, you can download your products from the <a href='/my-gear' style={{color:style.color4}}>my gear</a> page</strong></p>
              </div>
              </div>
              
              </>)}      

                {/* -----------------   desktop   --------------- */}

              {cartItems.length >= 1 && !isMobile &&(<>
                <div style={{display:'flex', flexDirection:'row', width:'95%', maxWidth:'1100px', margin:'auto', marginTop:'30px'}}>
                <h1 style={{fontSize:"18pt"}}>shopping cart:</h1>
              </div>

                <div style={{display:'flex', flexDirection:'row', width:'95%', maxWidth:'1100px', margin:'auto'}}>
                            
                            <div style={{ textAlign: 'center', color: style.color5, width: '95%', maxWidth: '1000px', margin: 'auto'}}>
                                
                                <div style={{maxWidth:'1000px', margin:'auto'}}>
                                  <ShoppingCartTable />
                                </div>  
                            </div>
                       
                        <div style={{flex:1, marginLeft:'10px'}}>
                            <table style={{margin:'auto', width:'95%', borderColor:style.color4, borderThickness:'1px', borderStyle:'solid'}}>
                              <tbody>
              
                              
                            <tr>
                              <td style={{width:'125px'}}><h3 style={{padding:'5px', margin:'0px'}}>Subtotal:</h3></td>
                              <td><h3 style={{width:'125px', padding:'5px', margin:'0px', textAlign:'center'}}>$ {parseFloat(cartSubtotal).toFixed(2)}</h3></td>
                            </tr>
                            <tr>
                              <td><h3 style={{padding:'5px', margin:'0px'}}>Sales Tax</h3></td>
                              <td><h3 style={{padding:'5px', margin:'0px', textAlign:'center'}}>$ {parseFloat(taxTotal).toFixed(2)}</h3></td>
              
                            </tr>
                            <tr>
                              <td><h2 style={{padding:'5px', margin:'0px'}}>TOTAL</h2></td>
                              <td><h2 style={{padding:'5px', margin:'0px', textAlign:'center'}}>$ {parseFloat(cartSubtotal + taxTotal).toFixed(2)} </h2></td>
              
                            </tr>
                            </tbody>
                          </table>
              
                          <Button onClick={navToCheckout} sx={{width:'100%', backgroundColor:style.color4, color:'white', marginTop:'25px','&:hover': {backgroundColor: style.color4 }}}> Proceed To Checkout </Button>   </div>
              

                      </div>    
              
              <div style={{width:'95%', maxWidth:'800px', margin:'auto', marginTop:'75px'}}>
                <p><strong>Once your order is complete, you can download your products from the <a href='/my-gear' style={{color:style.color4}}>my gear</a> page</strong></p>
              </div>
              </>)}       
        

        </div>

        </>
    );
}


// import {useState, useEffect} from "react"
// import { useStyle } from "../../context/style_context/styleContext";
// import { useParams } from 'react-router-dom';
// import {useNavigate, Link} from 'react-router-dom';
// import axios from 'axios';
// import {toast} from 'react-hot-toast';
// import ReactGA from 'react-ga4';

// import ProductsTable from "../../components/ProductsTable/ProductsTable";




// export default function Products(){

//     const { style, setStyle } = useStyle();
//     const [products, setProducts] = useState({})
//     const [productDetails, setProductDetails] = useState([]);




//     useEffect(() => {getProductsInCart();}, []); // pull list when screen loads
    

//     useEffect(() => {
//         if (products.length > 0) {
//             fetchProductDetails();
//         }
//     }, [products]);


//     ReactGA.send({
//         hitType:"pageview",
//         page:"shopping_cart",
//         title:"shopping_cart",
//       })

//       useEffect(() => {
//         document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Shopping Cart';
//       }, []);


//     const getProductsInCart = async (e) => {
//         console.log("getting cart info")        
//         try{
            
//             const{data} = await axios.get('/shopping_cart')
//             console.log(data)
//         if(data.error){
//             toast.error(data.error)
//         }
//         else{

//             setProducts(data)            

//         }
//         }catch(error){
//             console.log(error)
//         }
//     }

//     const fetchProductDetails = async () => {
      
//       const skus = products.map(product => product.sku); // Extract SKUs from the products in cart
//       try {
//           const response = await axios.get('/api/get_multiple_product_info', { skus });
//           setProductDetails(response.data); // Update state with detailed product info
//       } catch (error) {
//           console.error('Error fetching product details', error);
//       }
//   };

  

//     return(<>
//     <div style={{textAlign:'center', color:style.color2, width:'95%', maxWidth:'1000px', backgroundColor:'green', margin:'auto'}}>
//         <h1>Shopping Cart</h1>
//     </div>
//     <div>
//     {products.map((product) => (


//       <div style={{display:'flex', flex:1, flexDirection:'row', margin:'auto', backgroundColor:'red'}}>
//         <div>{product.sku}</div>
//         <div style={{marginLeft:'10px'}}>{product.version}</div>
//       </div>


//                   ))}
//     </div>
//     <div style={{display:'flex', flexDirection:'row', margin:'auto', textAlign:'center', justifyContent:'center', alignContent:'center', width:'90%'}}>

//         <div style={{display:'flex', flex:1, flexDirection:'column', backgroundColor:style.color2, minWidth:'320px', maxWidth:'800px', width:'90%', margin:'auto', marginTop:'20px', alignContent:'center', justifyItems:'center', textAlign:'center', borderRadius:'15px', marginBottom:'50px'}}>
//         <div><h1>Products</h1></div>
//         <div style={{display:'flex', flexDirection:'row', backgroundColor:style.color5, borderTopLeftRadius:'15px', borderTopRightRadius:'15px', boxShadow: "rgba(0, 0, 0, .2) 1px 1px 20px 5px, rgba(31, 31, 35, .1) 0px 0px 0px 2px"}}>

//             <div style={{flex:1}}>
//                 <ProductsTable products={products}/>
//             </div>

//         </div>
//         </div>
//     </div>

//         </>
//     )
// }