
import {React, useState, useContext, useEffect, useRef, List} from "react"
import { Link } from "react-router-dom"
import axios from 'axios';
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import { ShoppingCartContext } from "../../context/shoppingCartContext";
import Button from '@mui/material/Button';
import {toast} from 'react-hot-toast';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Rating from '@mui/material/Rating';
import ReactGA from 'react-ga4';
import Background from '../../images/RecordingStudioStockImage1.jpg'
import SubscribeRedirectModal from "../../components/SubscribeRedirectModal/SubscribeRedirectModal";
import LoginSubscribePromptModal from "../../components/LoginSubscribePromptModal/LoginSubscribePromptModal";
import LeaveReviewModal from "./leaveReviewModal";




export default function ProductPage(props){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();
  const { addToCart, removeFromCart, cartItems } = useContext(ShoppingCartContext);
  const [productData, setProductData] = useState();

  const [isSubscribeRedirectModalOpen, setSubscribeRedirectModalOpen] = useState(false);
  const openSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(true);
  const closeSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(false);
  
  
  const [isLoginRegisterModalOpen, setLoginRegisterModalOpen] = useState(false);
  const openLoginRegisterModal = () => setLoginRegisterModalOpen(true);
  const closeLoginRegisterModal = () => setLoginRegisterModalOpen(false);

  const [isLeaveReviewModalOpen, setLeaveReviewModalOpen] = useState(false);
  const openLeaveReviewModal = () => setLeaveReviewModalOpen(true);
  const closeLeaveReviewModal = () => setLeaveReviewModalOpen(false);

  const name = window.location.pathname.split("/").pop();

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;


    ReactGA.send({
      hitType:"pageview",
      page: name,
      title: name,
    })

    const leaveReview = async (e) => {

      if(user)
      {
        openLeaveReviewModal()
      }
      else
      {
        openLoginRegisterModal()
      }
    }

    const getProductPageInfo = async (e) => {
        const productURI = name
        try{
          const{data} = await axios.post('/api/get_product_page_info', {productURI})
          if(data)
          {
              //console.log(data)
              setProductData(data)
          }
          else{
              console.log('data error')
          }
        }
        catch
        {
          console.log('Error retrieving data...')
        }
        
    }
    useEffect(() => {
        getProductPageInfo();
    }, []);

    const addProductToCart = async (e) => {

        e.preventDefault();
        
        addToCart(productData.SKU)
        toast.success('Product added to cart')
    
    
    }
    
    const navToSubscribePage = async (e) =>
      {
        console.log("subscribe button")
        if(user)
        {
          window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
        }
        else
        {
          openLoginRegisterModal()
        }
        
      }



  

const downloadProductWindows = async (e) => 
    {

      try{
        const response = await axios.post('/api/log_download_attempt', 
          {
            sku: "10001",
            productName:"Infinity Reverb",
            productVersion:"1.0.0",
            productURI: '/Infinity-Reverb-v1_0_0-Windows-Installer-VST3.exe',
          });
      }
      catch{
        
      }
      

      console.log("download product!")
      
      window.location.href = "/Infinity-Reverb-v1_0_0-Windows-Installer-VST3.exe";
  }




    return(
        <>

<>

        {!productData && (<div style={{margin:'auto', textAlign:'center', marginTop:'100px'}}><h1>Loading...</h1></div>)}
        {productData && !isMobile &&(


<div>



<div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', margin:'auto', marginTop:'25px', backgroundImage: "url(" + Background + ")", backgroundSize: 'cover',  backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>

    
    

          <div style={{display:'flex', flex:1, flexDirection:'row', textAlign:'center', color: style.color5, minHeight:'600px', backgroundColor:'rgba(0,0,0,.7)'}}>
            <div style={{display:'flex', flexDirection:'column', flex:1, marginLeft:'75px'}}>
                <div style={{display:'flex', marginBottom:'5px'}}>
                    <h1 style={{marginBottom:'0px'}}> {productData.name} </h1>
                </div>
                <div style={{textAlign:'left'}}>
                    <h4 style={{marginTop:'0px'}}>
                        {productData.shortDescription}
                    </h4>


                <div style={{display:'flex', flexDirection:'row', marginTop:'25px'}}>
                <a href={'/reviews/' + productData.productURI } style={{display:'flex', flexDirection:'row', color:style.color5}}>
                <div>
                  
                <Rating name="read-only" precision={0.5} value={productData.starRating} readOnly />

                        
                    </div>
                    <div>
                    ({productData.ratingCount} reviews)

                    
                    </div>
                   </a>
                    </div>
                    
                    <div><Button onClick={openLeaveReviewModal}>Leave a Review</Button></div>
                    <div>
                    <div style={{width:'300px', height:'60px', marginTop:'20px'}}>
                {productData.audioURI &&(
                  <audio controls style={{width:'300px'}}>  
                  <source src={productData.audioURI} type="audio/mp3" size='small'  />              
                  </audio>

                )}

              </div>
                    

                </div>
                <div style={{display:'flex', flexDirection:'column', marginTop:'25px'}}>
                <div style={{textAlign:'left'}}>
                 <h3 style={{marginTop:'0px', marginBottom:'0px'}}>
                <strike>${productData.msrp}</strike>
                </h3>

    </div>

    <div style={{display:'flex', flexDirection:'row', marginTop:'0px', textAlign:'left'}}>
        <div style={{display:'flex', flex:1, color:'white', marginTop:'0px', marginBottom:'0px'}}><span style={{marginTop:'0px', marginBottom:'0px', fontSize:'1.6em'}}><strong>${productData.price}</strong></span>
        <span style={{marginTop:'0px', marginBottom:'0px', color:'red', fontSize:'1.6em', marginLeft:'10px'}}>Save {(Math.round((1000*(productData.msrp-productData.price)/productData.msrp))/10)} %</span></div>

    </div>
<div style={{textAlign:'center'}}>
    <div style={{flex:1, flexDirection:'row', marginTop:'50px'}}>

        
            <Button onClick={addProductToCart} sx={{fontSize:'1.3em', backgroundColor:style.color4, color:style.color5, minWidth:'350px'}}>Add to Cart</Button>
        
    </div>

    <div style={{marginTop:'50px'}}>
        <h4>
        Included with subscription - 7 day FREE trial
        </h4>

    </div>
    <div style={{}}>
       
        <Button onClick={navToSubscribePage} sx={{fontSize:'1.3em',  backgroundColor:style.color4, color:style.color5, minWidth:'350px'}}>Subscribe</Button>
        

    </div>




        </div>


    </div>
   


  </div>

            </div>
            
            <div style={{ flex:1}}>
                
                <img src={productData.imageURIs[0]} style={{  maxWidth:'350px', margin:'auto', marginTop:'25px'}}></img>

            </div>
            </div>
            
          </div>
          <div style={{width:'95%', maxWidth:'800px', margin:'auto'}}>
          <div style={{textAlign:'center', width:'95%', maxWidth:'1000px'}}>
            <h1>{productData.descriptionHeader}</h1>
          </div>
          <div>
            <p>
              {productData.longDescription}
            </p>
          </div>
          <div style={{margin:'auto', marginTop:'25px', marginLeft:'50px'}}>
            <ul style={{margin:'auto', listStyleType:'disc'}}>
              <li>
                {productData.bulletPoint1}
              </li>
              <li>
                {productData.bulletPoint2}
              </li>
              <li>
                {productData.bulletPoint3}
              </li>
              <li>
                {productData.bulletPoint4}
              </li>
              <li>
                {productData.bulletPoint5}
              </li>
            </ul>
          </div>

          </div>
          
          <div id='modal-root'>
          <SubscribeRedirectModal isOpen={isSubscribeRedirectModalOpen} close={closeSubscribeRedirectModal} />
          <LoginSubscribePromptModal isOpen={isLoginRegisterModalOpen} close={closeLoginRegisterModal} />
          <LeaveReviewModal sku={productData.SKU}isOpen={isLeaveReviewModalOpen} close={closeLeaveReviewModal} />
        </div>
          </div>




        )}
        { productData && isMobile &&(

<div>



<div style={{display:'flex', flexDirection:'column', width:'95%', margin:'auto', marginTop:'25px', backgroundImage: "url(" + Background + ")", backgroundSize: 'cover',  backgroundPosition: 'center',  borderRadius:'10px', borderWidth:'2px', borderColor:style.color4, borderStyle:'solid'}}>

    
    

          <div style={{display:'flex', flex:1, flexDirection:'row', textAlign:'center', color: style.color5, minHeight:'600px', backgroundColor:'rgba(0,0,0,.7)', padding:'10px', borderRadius:'10px'}}>
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                <div style={{display:'flex', marginBottom:'5px'}}>
                    <h1 style={{marginBottom:'0px', fontSize:'1.8em'}}> {productData.name} </h1>
                </div>
                <div style={{textAlign:'left'}}>
                  <div>
                  <h5 style={{marginTop:'0px'}}>
                        {productData.shortDescription}
                    </h5>

                  </div>
                  <div style={{margin:'auto', width:'100%', textAlign:'center'}}>
                    <img src={productData.imageURIs[0]} style={{  maxWidth:'250px', margin:'auto', marginTop:'25px'}}></img>
                  </div>


                <div style={{display:'flex', flexDirection:'row', marginTop:'15px'}}>
                    <a href={'/reviews/' + productData.productURI } style={{display:'flex', flexDirection:'row', color:style.color5}}>
                <div>
                  
                <Rating name="read-only" precision={0.5} value={productData.starRating} readOnly />

                        
                    </div>
                    <div>
                    ({productData.ratingCount} reviews)

                    
                    </div>
                   </a>
                    </div>
                    
                    <div><Button onClick={openLeaveReviewModal}>Leave a Review</Button></div>
                    <div>
                    <div style={{width:'300px', height:'60px', marginTop:'20px'}}>
                {productData.audioURI &&(
                  <audio controls style={{width:'300px'}}>  
                  <source src={productData.audioURI} type="audio/mp3" size='small'  />              
                  </audio>

                )}

              </div>
                    

                </div>
                <div style={{display:'flex', flexDirection:'column', marginTop:'25px'}}>
                <div style={{textAlign:'left'}}>
                 <h3 style={{marginTop:'0px', marginBottom:'0px'}}>
                <strike>${productData.msrp}</strike>
                </h3>

    </div>

    <div style={{display:'flex', flexDirection:'row', marginTop:'0px', textAlign:'left'}}>
        <div style={{display:'flex', flex:1, color:'white', marginTop:'0px', marginBottom:'0px'}}><span style={{marginTop:'0px', marginBottom:'0px', fontSize:'1.6em'}}><strong>${productData.price}</strong></span>
        <span style={{marginTop:'0px', marginBottom:'0px', color:'red', fontSize:'1.6em', marginLeft:'10px'}}>Save {(Math.round((1000*(productData.msrp-productData.price)/productData.msrp))/10)} %</span></div>

    </div>
<div style={{textAlign:'center'}}>
    <div style={{flex:1, flexDirection:'row', marginBottom:'25px', marginTop:'30px'}}>

        
            <Button onClick={addProductToCart} sx={{fontSize:'1.3em', backgroundColor:style.color4, color:style.color5, minWidth:'250px'}}>Add to Cart</Button>
        
    </div>

    <div style={{marginTop:'30px', marginBottom:'10px', width:'100%', fontSize:'.9em'}}>
        <h5 style={{marginBottom:'0px'}}>
        Included with subscription - 7 day FREE trial
        </h5>

    </div>
    <div style={{marginBottom:'25px'}}>
       
        <Button onClick={navToSubscribePage} sx={{fontSize:'1.3em',  backgroundColor:style.color4, color:style.color5, minWidth:'250px'}}>Subscribe</Button>
        

    </div>




        </div>


    </div>
   


  </div>

            </div>
            
            
            </div>
            
          </div>
          <div style={{width:'95%', maxWidth:'800px', margin:'auto'}}>
          <div style={{textAlign:'center', width:'95%', maxWidth:'1000px'}}>
            <h1>{productData.descriptionHeader}</h1>
          </div>
          <div>
            <p>
              {productData.longDescription}
            </p>
          </div>
          <div style={{margin:'auto', marginTop:'25px', marginLeft:'50px'}}>
            <ul style={{margin:'auto', listStyleType:'disc'}}>
              <li>
                {productData.bulletPoint1}
              </li>
              <li>
                {productData.bulletPoint2}
              </li>
              <li>
                {productData.bulletPoint3}
              </li>
              <li>
                {productData.bulletPoint4}
              </li>
              <li>
                {productData.bulletPoint5}
              </li>
            </ul>
          </div>

          </div>
          
          <div id='modal-root'>
          <SubscribeRedirectModal isOpen={isSubscribeRedirectModalOpen} close={closeSubscribeRedirectModal} />
          <LoginSubscribePromptModal isOpen={isLoginRegisterModalOpen} close={closeLoginRegisterModal} />
          <LeaveReviewModal sku={productData.SKU}isOpen={isLeaveReviewModalOpen} close={closeLeaveReviewModal} />
        </div>
          </div>
        )}


        </>
    
        
        </>

    )
}