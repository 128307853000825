


import {React, useState, useContext, useEffect} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductCardVertical from "../../components/ProductCard/ProductCardVertical";
import SubConfirmModal from "./SubConfirmModal"



export default function SubConfirmPage(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();
  const [isSubConfirmationModalOpen, setSubConfirmationModalOpen] = useState(true);

    const openSubConfirmationModal = () => setSubConfirmationModalOpen(true);
    const closeSubConfirmationModal = () => setSubConfirmationModalOpen(false);

    ReactGA.send({
      hitType:"pageview",
      page:"Sub-Confirm_Page",
      title:"Sub-Confirm_Page",
    })

    const [width, setWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        function handleResize() {      
          setWidth(window.innerWidth)
      }      
        window.addEventListener('resize', handleResize)
      })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | State Of The Art Audio Processors | Confirm New Subscription';
  }, []);


    return(
        <>
        <div style={{minHeight:'68vh' }}>
            
        </div>
        <div id='modal-root'>
        <SubConfirmModal isOpen={isSubConfirmationModalOpen} close={closeSubConfirmationModal} />
        </div>
        </>

    )
}