
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import axios from 'axios';
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import { ShoppingCartContext } from "../../context/shoppingCartContext";
import Button from '@mui/material/Button';
import {toast} from 'react-hot-toast';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';



export default function InstaGate(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();
  const { addToCart, removeFromCart, cartItems } = useContext(ShoppingCartContext);

    ReactGA.send({
      hitType:"pageview",
      page:"InstaGate",
      title:"InstaGate",
    })

    

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | State Of The Art Audio Plugins and Processors | Insta-Gate Noisegate';
  }, []);



  const addProductToCart = async (e) => {

    e.preventDefault();

    addToCart("10002")
    
    var okToSend = true;


    if(okToSend)
    {

        try{
            okToSend = false   
            const{data} = await axios.post('/api/add_to_cart',
              {
              shoppingCart: cartItems,
             })


        if(data.error){
            console.log("error")
            toast.error("An internal error has occured.")
        }
        else{
            //setData({})
            toast.success('Success! Product added to cart')
            //navigate('/home')
        }
        }catch(error){
            console.log(error)
            toast.error('An internal error has occured. ')
        }
    }
}

const downloadProductWindows = async (e) => 
    {

      try{
        const response = await axios.post('/api/log_download_attempt', 
          {
            sku: "10002",
            productName:"Insta-Gate Noisegate",
            productVersion:"1.0.0",
            productURI: '/InstaGate-v1_0_0-Windows-Installer-VST3.exe',
          });
      }
      catch{
        
      }
      

      console.log("download product!")
      
      window.location.href = "/InstaGate-v1_0_0-Windows-Installer-VST3.exe";
  }



    return(
        <>
        
        <div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', margin:'auto'}}>
          <div style={{textAlign:'center', color: style.color5, minHeight:'600px'}}>
            <h1> Insta-Gate Noisegate</h1>
            <img src ='InstaGate.png' altText={"Insta-Gate Noisegate stompbox with glowing red LED indicator"}></img>
            <p style={{marginTop:'30px'}}>
            The Insta-Gate is a an ultra fast acting noisegate designed specifically for guitar, providing near instant attack response while at the same time allowing deep sustain.
            </p>
            <p>
            This reverb can be used by itself to create a rich shimmering clean tone, or use it as part of your overall signal chain to add complexity and ambiance to your sound. Currently available for Windows, this download comes as a VST3 plugin that can be used with your favorite DAW, such as Pro Tools, Reaper, Cubase, FL Studio, and many more.
            </p>
            
            <Button onClick={addProductToCart}>Add to Cart</Button>
            </div>
            <div style={{ marginTop:'50px'}}>

            
            <h2 style={{textAlign:'center'}}>
                Installation
            </h2>
            <h3 style={{textAlign:'center'}}>
                Windows
            </h3>
            <p>
                The preferred way to install on Windows is by using the Windows Installer. This will install all necessary files in the correct location. Once the files are installed, you can open the plugin using your DAW. 
            </p>
            <div style={{margin:'auto', textAlign:'center' }}>
            <Button onClick={downloadProductWindows} style={{margin:'auto'}}><div><div><img src='/windows_logo.png' style={{flex:1 , minWidth:'50px', height:'60px', maxWidth:'100%', maxHeight:'100%', margin:'auto'}} /></div>Windows Download</div></Button>
            </div>
            

                <p>
                    Once installed, you will use your username and password to unlock the plugin.
                </p>
                <p>
                    If you need further assistance, please visit our <a href='/support'>Support</a> page.
                </p>
          </div>
          </div>
        </>

    )
}