
import './App.css';
import {useContext} from "react";
import {useState, useEffect} from "react"
import {useNavigate} from 'react-router-dom'
import {Routes, Route} from 'react-router-dom'
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';
import axios from 'axios'
import {Toaster} from 'react-hot-toast'
import {UserContextProvider, UserContext} from './context/userContext'
import PrivateRoutes from './routes/PrivateRoutes';
import AdminRoutes from './routes/AdminRoutes';
import { StyleContext, StyleProvider } from './context/style_context/styleContext'
import { ProfileProvider } from './context/profileContext';
import Home from './pages/home/Home';
import Login from './pages/user/Login';
import Register from './pages/user/Register';
import Account from './pages/user/Account';
import CreateCompany from './pages/company/CreateCompany';
import Companies from './pages/company/Companies';
import Company from './pages/company/Company';
import { ShoppingCartProvider } from './context/shoppingCartContext';
import CookieHandler from './components/CookieHandler/CookieHandler';
import CreateProduct from './pages/product/CreateProduct';
import Products from './pages/product/Products';
import CreateTransaction from './pages/transactions/CreateTransaction';
import ShoppingCart from './pages/shopping_cart/ShoppingCart';
import Plugins from './pages/plugins/plugins';
import Support from './pages/support/support';
import NavBar from './components/Navbar/Navbar';
import ResponsiveAppBar from './components/MuiNavbar/ResponsiveAppBar';
import Footer from './components/Footer/Footer';
import AdminDashboard from './pages/adminDashboard/AdminDashboard';
import PageAnalytics from './components/PageAnalytics/PageAnalytics';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import CheckoutPage from './pages/checkoutPage/CheckoutPage';
import ForgotPassword from './pages/user/ForgotPassword';
import ResetPassword from './pages/user/ResetPassword';
import AppLoginTestPage from './pages/admin/AppLoginTestPage';
import CreateLicense from './pages/admin/AddLicense';
import MyGear from './pages/myGear/MyGear';
import SubscribePage from './pages/subscribe/subscribe';
import TermsPage from './pages/terms/TermsPage';
import SubConfirmPage from './pages/subConfirmPage/SubConfirmPage';
import InfinityReverb from './pages/product_pages/InfinityReverb';
import InstaGate from './pages/product_pages/InstaGate';
import ProductPage from './pages/product_pages/ProductPage';
import ProductReviewPage from './pages/product_pages/ProductReviewPage';
import Contact from './pages/site_info/Contact';
import About from './pages/site_info/About';
import FAQ from './pages/site_info/FAQ';
import ReturnPolicy from './pages/site_info/ReturnPolicy';
import MK1OverdriveDevNotes from './components/Devnotes/MK1OverdriveDevNotes';


//axios.defaults.baseURL = 'http://localhost:8080';
axios.defaults.baseURL = 'https://api.brainwavesoundlab.com/'
// axios.defaults.withCredentials = true;

//axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;


function App() {


  return (
    <>

    <CookieHandler />
    <ShoppingCartProvider>   
    <UserContextProvider>
      <ProfileProvider>
      {/* <PageAnalytics endpoint='https://api.brainwavesoundlab.com/api/page_analytics'> */}
      <StyleProvider>
        <ResponsiveAppBar />
      <Toaster position='bottom-right' toastOptions={{duration: 2000}} />

        <Routes>

          {/* Private routes (Requires authentication token) */}
          <Route element= {<PrivateRoutes />}>

              <Route path='/account' element={<Account />} />
              <Route path='/my-gear' element={<MyGear />} />
              <Route path= '/checkout' element = {<CheckoutPage />} />
              <Route path= '/subscription-confirmation' element = {<SubConfirmPage />} />


          </Route>
          <Route element= {<AdminRoutes />}>
            <Route path='/admin' element={<AdminDashboard />} />
            <Route path='/subscribe' element={<SubscribePage />} />
            <Route path='/admin/create_product' element={<CreateProduct />} />
            
            <Route path= '/api/app_login' element = {<AppLoginTestPage />} />
            <Route path= '/api/create_license' element = {<CreateLicense />} />

            
          </Route>

              <Route path='/' element={<Home />} />
              <Route path= '/plugins' element={<Plugins />} />
              <Route path='/products' element={<Products />} />
              {/* <Route path= '/checkout' element = {<CheckoutPage />} /> */}
              <Route path='/product/:itemName' element={<ProductPage />}/>
              <Route path='/reviews/:itemName' element={<ProductReviewPage />}/>
              <Route path='/dev-notes/mk1-overdrive' element={<MK1OverdriveDevNotes />}/>
              <Route path= '/support' element={<Support />} />
              <Route path= '/contact' element={<Contact />} />
              <Route path= '/about' element={<About />} />
              <Route path= '/FAQ' element={<FAQ />} />
              <Route path= '/return-policy' element={<ReturnPolicy />} /> 
              <Route path= '/privacy-policy' element={<PrivacyPolicy />} />
              <Route path= '/terms' element={<TermsPage />} />
              <Route path='/register' element={<Register />} />
              <Route path='/login' element={<Login />} />
              <Route path= '/forgot-password' element = {<ForgotPassword />} />
              <Route path= '/reset-password/:token' element = {<ResetPassword/>} />
              {/* <Route path= '/reset-password/' element = {<ResetPassword/>} /> */}
              <Route path='/shopping-cart' element={<ShoppingCart />} />

              <Route path='/instagate' element={<InstaGate />} />


        </Routes>
        <Footer />
        
        </StyleProvider>
        {/* </PageAnalytics> */}
        </ProfileProvider>
      </UserContextProvider>
      </ShoppingCartProvider> 
    </>
  );
}

export default App;
