import {React, useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import { useStyle } from "../../context/style_context/styleContext";
import axios from 'axios'
import ReactGA from 'react-ga4';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {toast} from 'react-hot-toast'

// import IconButton from "@material-ui/core/IconButton";
// import InputLabel from "@material-ui/core/InputLabel";
// import Visibility from "@material-ui/icons/Visibility";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Input from "@material-ui/core/Input";

const ChangeStripeEmailModal = ({ isOpen, close, children }) => {


  const [showConfirm, setShowConfirm] = useState(false);

  const { style, setStyle } = useStyle();


  const [data, setData] = useState({

    stripeEmail: "",

})

  ReactGA.send({
    hitType:"pageview",
    page:"ChangeStripeEmailModal",
    title:"ChangeStripeEmailModal",
  })


  useEffect(() => {
  document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Add Stripe Email';
}, []);

  if (!isOpen) return null;



const closeFunction = async (e) => {

setShowConfirm(false)
setData({stripeEmail: ""})
close()

}


//************** */


    const submitData = async (e) => {

        e.preventDefault();
        
        var okToSend = true;

        const {
            stripeEmail,
        } = data



        if(okToSend)
        {
            console.log("sending....")
            try{
                okToSend = false   
                const{data} = await axios.post('/change_stripe_email',{stripeEmail: stripeEmail})
    
    
            if(data.error){
                console.log("error")
                toast.error(data.error)
            }
            else{
                //setData({})
                toast.success('Success, your stipe account has been connect')
                //navigate('/home')
            }
            }catch(error){
                console.log(error)
            }
        }
    }

 
        

  return ReactDOM.createPortal(


    
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <div style={{backgroundColor:style.color9, width:'90%', margin:'auto'}}>
            
                    <div style={{display:'flex', flex:1, flexDirection:'column', textAlign:'center', width:'90%', margin:'auto'}}>
                        {showConfirm &&(
                          <>
                          <div style={{marginBottom:'75px', marginTop:'0px'}}>
                            <h2>
                              Success! Your Stripe Email has been successfully changed.
                            </h2>
                            
                            </div>
                            <div>
                            <Button onClick={closeFunction} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'14pt', width:'100%', minWidth:'250px', maxWidth:'300px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>OK</Button>
                              </div>
                              </>
                            )}

{!showConfirm &&(
                        <>
                        <div style={{margin:'auto', textAlign:'center', marginBottom:'25x'}}>
                            <h1 style={{marginBottom:'0px'}}>Attach Your Stripe Email</h1>
                        </div>
                        <div style={{marginBottom:'25px'}}><p>If the email address you use to sign up for your subscription on Stripe is different than your login email for this platform, you will change it here to link the accounts together.</p></div>

<div><TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      value={data.stripeEmail}
                      onChange={(e) => setData({...data, stripeEmail: e.target.value})}
                      size='medium'
                      fullWidth
                      sx={{
                        backgroundColor: style.color10,
                        borderRadius: '4px',
                        '& .MuiInputBase-input': {
                          color: style.color5,
                          fontSize: '16pt',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: style.color4,
                            borderWidth: '2px'
                          },
                          '&:hover fieldset': {
                            borderColor: style.color4,
                            borderWidth: '3px',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: style.color4,
                            borderWidth: '3px'
                          },
                          '&.Mui-disabled': {
                            '& fieldset': {
                              borderColor: style.color4,
                            },
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: style.color7,
                          fontSize: '16pt',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: style.color5,
                        },
                        '& .MuiInputLabel-root.Mui-disabled': {
                          color: style.color7,
                        },
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& input': {
                            color: style.color4,
                            WebkitTextFillColor: style.color4,
                            fontSize: '16pt'
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          color: 'white',
                          '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                            WebkitTextFillColor: style.color7, color: style.color7
                          },
                        },
                      }}
                    />
                    </div>


                        


                        <div style={{display:'flex', flexDirection:'row', maxWidth:'800px', margin:'auto'}}>
                        <div style={{marginTop:'75px', marginBottom:'30px', display:'flex', flex:1, marginRight:'10px'}}>
                            <Button onClick={submitData} sx={{ my: 2, flex:1, minWidth:'150px', color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', margin:'auto', marginTop:'0px', padding:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Submit</Button>
                        </div>
                        <div style={{marginTop:'75px', marginBottom:'30px', display:'flex', flex:1, marginLeft:'10px'}}>
                            <Button onClick={closeFunction} sx={{ my: 2, flex:1, minWidth:'150px', color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', margin:'auto', marginTop:'0px', padding:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Cancel</Button>
                        </div>
                        

                        </div>

                           </> )}



                    </div>
           
            
          <div>
                {/* ****************** NEW STUFF ******************************** */}
                {/* <div
            style={{
                marginLeft: "30%",
            }}
        >
            <h4>
                How to show and hide password in ReactJS?
            </h4>
            <InputLabel htmlFor="standard-adornment-password">
                Enter your Password
            </InputLabel>
            <Input
                type={
                    values.showPassword
                        ? "text"
                        : "password"
                }
                onChange={handlePasswordChange("password")}
                value={values.password}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={
                                handleClickShowPassword
                            }
                            onMouseDown={
                                handleMouseDownPassword
                            }
                        >
                            {values.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div> */}

                {/* ************************************************************** */}

          </div>



        </div>
      </div>

    </div>,
    document.getElementById('modal-root')
  );
};

const modalStyle = {

    
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  overflowY: 'auto',
  margin:'auto',
  zIndex: "1000",
};

const modalContentStyle = {
  padding: '20px',
  borderRadius: '15px',
  maxWidth: '800px',
  minHeight: '300px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  borderColor:'#535bf2',
  borderWidth:'2px',
  borderStyle:'solid',
  backgroundColor: 'rgb(58,59,60)',
};

const buttonStyle = {
  marginTop: '20px',
  alignSelf: 'center'
};

export default ChangeStripeEmailModal;
