
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext'
import { useStyle } from '../../context/style_context/styleContext';
import ReactGA from 'react-ga4';
import video from '../../videos/recording_video.mp4'
import axios from 'axios'
import {toast} from 'react-hot-toast'
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductCardVertical from "../../components/ProductCard/ProductCardVertical";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import PageAnalytics from "../../components/PageAnalytics/PageAnalytics";
import SubscribeRedirectModal from "../../components/SubscribeRedirectModal/SubscribeRedirectModal";
import LoginSubscribePromptModal from "../../components/LoginSubscribePromptModal/LoginSubscribePromptModal";



export default function Home(){

  const { user, userEmail, userFirstName, userLastName } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

  const [width, setWidth] = useState(window.innerWidth)

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

  const [isSubscribeRedirectModalOpen, setSubscribeRedirectModalOpen] = useState(false);
  const openSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(true);
  const closeSubscribeRedirectModal = () => setSubscribeRedirectModalOpen(false);
  
  
  const [isLoginRegisterModalOpen, setLoginRegisterModalOpen] = useState(false);
  const openLoginRegisterModal = () => setLoginRegisterModalOpen(true);
  const closeLoginRegisterModal = () => setLoginRegisterModalOpen(false);
  

  const[loginData,setLoginData] = useState({
    email: '',
    password: '',
    credType:'cookie'
})

  ReactGA.send({
    hitType:"pageview",
    page:"home",
    title:"home",
  })

  useEffect(() => {
  document.title = 'Brainwave Sound Lab | State Of The Art State Of The Art Audio Processing | Home';
}, []);

const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end" });
    }, []);



useEffect(() => {
  function handleResize() {      
    setWidth(window.innerWidth)
}

  window.addEventListener('resize', handleResize)
})

const subscribeButtonClick = () => {

  if(user)
  {
    window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`
  }
  else
  {
    openLoginRegisterModal()
  }

};
const loadProductData = async (e) =>
  {

    console.log("loading product data")

  }

  const navToSubscribePage = async (e) =>
    {

      window.location.href = `https://buy.stripe.com/6oE3gfaAxdanaticMM?prefilled_email=${user.email}`

    }

    const downloadProductMac = async (e) => 
      {


        console.log("download product!")

        window.location.href = '/Infinity-Reverb-v1_0_0-Mac-Installer.pkg';
    }



    //console.log("WIDTH: ", width)

    return(
        <>
        <div ref={topContainer}></div>

{isMobile && (<>
  <div style={{display:'flex', flexDirection:'column', width:'95%%', margin:'auto'}}>

<div>
  <div style={{display:'flex', flexDirection:'column', margin:'auto', width:'95%'}}>
  <h2>
  <Button onClick={subscribeButtonClick} sx={{ my: 2, textTransform:'none', color: style.color5, display: 'block', backgroundColor: style.color4, fontSize: '16pt', width: '100%', minWidth: '250px', margin: 'auto', marginTop: '0px', borderColor: style.color4, borderWidth: '2px', maxWidth:'800px', borderStyle: 'solid', '&:hover': { backgroundColor: style.color4 }, }}><div>
      <div style={{fontSize:'1em'}}>
      Get Full Access - 7 day free trial
      </div>

      <div style={{fontSize:'0.8em'}}>
      $9.95 per month - cancel anytime
      </div>
    </div></Button>
    
    
  </h2>
<div style={{display:'flex', flexDirection:'column', margin:'auto'}}>
  <div style={{textAlign:'center'}}>
    <h1>
      New Products
    </h1>
  </div>
  <div style={{marginBottom:'25px', margin:'auto'}}>
<Card sx={{ maxWidth: 320, backgroundColor:style.color2, color:style.color7, borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px', marginBottom:'25px'}}>
          {/* <CardMedia
            sx={{ height: 140 }}
            image="InfinityReverb.png"
            title="green iguana"
          /> */}
          <CardContent >
            <div style={{ textAlign:'center', padding:'10px', backgroundColor:'rgb(25,31,34)', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
              <a href={'/product/mk1-overdrive'}>
              <img src={'/MK1Overdrive.png'} style={{width:'150px', margin:'auto'}} />
              </a>
            </div>
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            <a href={'/product/mk1-overdrive'} style={{color:'white'}}>
              MK1 Overdrive
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
            The MK1 Overdrive is a classic overdrive pedal...             </Typography>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Share</Button> */}
            <a href={'/product/mk1-overdrive'} style={{color:style.color4}}>LEARN MORE</a>
          </CardActions>
        </Card>

</div>

<div style={{marginBottom:'25px', margin:'auto'}}>
<Card sx={{ maxWidth: 320, backgroundColor:style.color2, color:style.color7, borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px', marginBottom:'25px'}}>
          {/* <CardMedia
            sx={{ height: 140 }}
            image="InfinityReverb.png"
            title="green iguana"
          /> */}
          <CardContent >
            <div style={{ textAlign:'center', padding:'10px', backgroundColor:'rgb(25,31,34)', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
              <a href={'/product/instagate'}>
              <img src={'/InstaGate.png'} style={{width:'150px', margin:'auto'}} />
              </a>
            </div>
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            <a href={'/product/instagate'} style={{color:'white'}}>
              Insta-Gate Noisegate
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
            The Insta-Gate is a noisegate designed specifically for high gain guitar. It is ultra fast acting, so it preserves the attack...             </Typography>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Share</Button> */}
            <a href={'/product/instagate'} style={{color:style.color4}}>LEARN MORE</a>
          </CardActions>
        </Card>

</div>
<div style={{marginBottom:'10px', margin:'auto'}}>
<Card sx={{ maxWidth: 320, backgroundColor:style.color2, color:style.color7, borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
          <CardContent >
            <div style={{ textAlign:'center', padding:'5px', backgroundColor:'rgb(25,31,34)', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
              <a href={'/product/infinity-reverb'}>
              <img src={'/InfinityReverb.png'} style={{width:'150px', margin:'auto', borderRadius:'5px'}} />
              </a>
            </div>
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            <a href={'/product/infinity-reverb'} style={{color:'white'}}>
              Infinity Reverb
              </a>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', color:style.color7}}>
              The Infinity Reverb is a world class reverb that is custom tailored specifically for guitar players. Designed to balance...
            </Typography>
          </CardContent>
          <CardContent>
              <div style={{margin:'auto', textAlign:'center'}}>
                <audio controls style={{width:'280px', margin:'auto'}}>  
                <source src="/ReverbSample.mp3" type="audio/mp3" />              
              </audio>
              </div>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Share</Button> */}
            <a href={'/product/infinity-reverb'} style={{color:style.color4}}>LEARN MORE</a>
          </CardActions>
        </Card>

</div>
        







  </div>
    </div>
</div>



  </div>



</>)}

        {!isMobile && (<>
          <div style={{display:'flex', flexDirection:'column', width:'100%', margin:'auto'}}>

          <div>
            <div style={{display:'flex', flexDirection:'column', margin:'auto', width:'100%'}}>
            <h2>
            <Button onClick={subscribeButtonClick} sx={{ my: 2, textTransform:'none', color: style.color5, display: 'block', backgroundColor: style.color4, fontSize: '16pt', width: '100%', minWidth: '250px', margin: 'auto', marginTop: '0px', borderColor: style.color4, borderWidth: '2px', maxWidth:'800px', borderStyle: 'solid', '&:hover': { backgroundColor: style.color4 }, }}><div>
                <div style={{fontSize:'1.3em'}}>
                Get Full Access - 7 day free trial
                </div>
            
                <div style={{fontSize:'1.0em'}}>
                $9.95 per month - cancel anytime
                </div>
              </div></Button>
              
              
            </h2>

            <div style={{textAlign:'center', marginBottom:'0px'}}>
            <h1 style={{marginBottom:'0px'}}>
              New Products
            </h1>            
          </div>

          <div style={{display:'flex', flexDirection:'row', margin:'auto', padding:'10px'}}>
            <div style={{flex:1, padding:'10px', marginRight:'25px'}}>
                <ProductCard productName="MK1 Overdrive" productSKU='10003' shortDescription = "Classic Overdrive" productImageURI="MK1Overdrive.png" productLink="/product/mk1-overdrive" imageAltText="MK1 Overdrive stompbox with green sparkle finish" userEmail={userEmail} starRating={4.5} ratingCount={3} msrp = {99.95} price={49.95} cardCallout='new'/>
              </div>
              <div style={{flex:1, padding:'10px', marginRight:'25px'}}>
              <ProductCard productName="Infinity Reverb" productSKU='10001' shortDescription = "World class reverb for guitar" productImageURI="InfinityReverb.png" productLink="/product/infinity-reverb" imageAltText="Infinity Reverb stompbox with glowing blue LED indicator" userEmail={userEmail} starRating={4.5} ratingCount={3} msrp = {99.95} price={49.95} cardCallout='new'/>
              </div>
               <div style={{flex:1, padding:'10px'}}>
               <ProductCard productName="Insta-Gate Noisegate" productSKU='10002' shortDescription = "Ultra fast acting guitar noisegate" productImageURI="InstaGate.png" productLink="/product/instagate" imageAltText="Insta-Gate stompbox with glowing red LED indicator" userEmail={userEmail} starRating={5.0} ratingCount={6} msrp = {99.95} price={49.95} cardCallout='bestSeller'/>

                </div> 
            </div>
              </div>
          </div>


       
            </div>

        
        
        
        </>)}
        <div id='modal-root'>
          <SubscribeRedirectModal isOpen={isSubscribeRedirectModalOpen} close={closeSubscribeRedirectModal} />
          <LoginSubscribePromptModal isOpen={isLoginRegisterModalOpen} close={closeLoginRegisterModal} />
        </div>

        
        </>

    )
}