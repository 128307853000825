import {React, useState, useEffect, useContext} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useStyle } from '../../context/style_context/styleContext';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { toast } from 'react-hot-toast';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


//addToCart(productSKU)



export default function ShoppingCartTableRow({productSKU, productName, productImageURIs, productPrice, imageAltText}) {
    //const { style, setStyle } = useStyle();
    const [products, setProducts] = useState([]); // Corrected initialization as an empty array
    const [productDetails, setProductDetails] = useState([]);
    const { style, setStyle } = useStyle();
    const { addToCart, removeFromCart, cartItems } = useContext(ShoppingCartContext);

    const deleteFromCart = async (e) => {

        e.preventDefault();
        console.log("Sending request to cart componant to DELETE..", productSKU)
    
        removeFromCart(productSKU)  
    }

    // console.log("PRODUCT SKU: ")
    // console.log(productSKU)

    
      const [productInfo, setProductInfo] = useState({
    
        name: productName,
        SKU: productSKU,
        shortDescription: "",
        longDescription: "",
        bulletPoint1: "",
        bulletPoint2: "",
        bulletPoint3: "",
        bulletPoint4: "",
        bulletPoint5: "",
        msrp: 0,
        price: productPrice,
        imageURIs: productImageURIs,
        UPC: "",
        productURI: "",
    
    })
    
   
    

  return (
 
            <StyledTableRow key={productInfo.SKU} sx={{minHeight:'50px', marginTop:'10px', color:'white'}}>


              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.productBGColor, color:'white', flex:1, paddingTop:'10px', paddingBottom:'10px'}}><img src={productInfo.imageURIs[0]} style={{width:'75px'}}></img></StyledTableCell>
              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}><div style={{fontSize:'16pt'}}>{productInfo.name}</div></StyledTableCell>
              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}><div style={{fontSize:'16pt'}}>$ {parseFloat(productInfo.price).toFixed(2)}</div></StyledTableCell>
              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}><Button onClick={deleteFromCart}>Remove From Cart</Button></StyledTableCell>

            </StyledTableRow>
          
  );
}
