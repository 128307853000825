import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ShoppingCartTableRow from './ShoppingCartTableRow';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import { useStyle } from '../../context/style_context/styleContext';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function ShoppingCartTable() {
  const { cartItems } = useContext(ShoppingCartContext);
  const { style, setStyle } = useStyle();

  return (
    <TableContainer component={Paper} sx={{display:'flex', backgroundColor:'green'}}>
      <Table sx={{width:'95%', maxWidth: 1000, flex:1, borderColor:style.color3, borderWidth:'2px', borderStyle:'solid'}} aria-label="customized table">
        <TableHead >
          <TableRow sx={{}}>

            <StyledTableCell sx={{ textAlign: 'center'}}></StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center'}} align="right"><h2 style={{marginTop:'0px', marginBottom:'0px'}}>Item</h2></StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }} align="right"> <h2 style={{marginTop:'0px', marginBottom:'0px'}}>Price</h2></StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }} align="right"> </StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
        {cartItems.map((item) => (
            <ShoppingCartTableRow
            key={item.SKU} 
            productSKU={item.SKU}
            productName={item.name}
            productPrice={item.price}
            licenseType="Standard License"
            productImageURIs={item.imageURIs}
            sx={{ minWidth: 700, marginBottom:'30px'}}
            />
  ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}



// import {React, useState, useEffect, useContext} from 'react';
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import ShoppingCartTableRow from './ShoppingCartTableRow';
// import { ShoppingCartContext } from '../../context/shoppingCartContext';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));


// export default function ShoppingCartTable() {

//     const { cartItems } = useContext(ShoppingCartContext);


    



//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 700 }} aria-label="customized table">
//         <TableHead>
//           <TableRow>
//             <StyledTableCell sx={{textAlign:'center'}}>SKU</StyledTableCell>
//             <StyledTableCell sx={{textAlign:'center'}} align="right">Item</StyledTableCell>
//             <StyledTableCell sx={{textAlign:'center'}} align="right"></StyledTableCell>
//             <StyledTableCell sx={{textAlign:'center'}} align="right">License Type</StyledTableCell>
//             <StyledTableCell sx={{textAlign:'center'}} align="right">Price</StyledTableCell>
//             <StyledTableCell sx={{textAlign:'center'}} align="right"></StyledTableCell>

//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {cartItems.map((item) => (
//             <ShoppingCartTableRow productSKU={item.SKU} productName ={item.name} itemPrice={item.price}/>

//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }


// import {React, useState, useEffect} from 'react';
// import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import axios from 'axios';
// import {toast} from 'react-hot-toast';
// import CardMedia from '@mui/material/CardMedia';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import { useStyle } from '../../context/style_context/styleContext';
// import {useNavigate, Link} from 'react-router-dom';

// export default function ShoppingCartItemRow({productSKU}) {

//   const { style } = useStyle();


//   return (
//     <><div style={{backgroundColor:'fuscia', display:'flex', flexDirection:'row', margin:'auto', flex:1, maxWidth:'1000px'}}>

//         <div style={{display:'flex', flex:1}}>
//             {productInfo.SKU}
//         </div>
//         <div style={{display:'flex', flex:1}}>
//             {productInfo.name}
//         </div>
//         <div style={{display:'flex', flex:1}}>
//            {productInfo.imageURIs[0]}
//         </div>
//         <div style={{display:'flex', flex:1}}>
//            {productInfo.price}
//         </div>
        

//     </div>
    
    
//     </>

//   );
// }