
import {React, useState, useContext, useEffect, useRef} from "react"
import { Link } from "react-router-dom"
import{UserContext} from '../../context/userContext';
import { useStyle } from '../../context/style_context/styleContext';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ReactGA from 'react-ga4';



export default function Contact(){

  const { user } = useContext(UserContext);
  const { isLoading } = useContext(UserContext);
  const { style, setStyle } = useStyle();

    ReactGA.send({
      hitType:"pageview",
      page:"contact",
      title:"contact",
    })

    useEffect(() => {
      document.title = 'Brainwave Sound Lab | Contact';
  }, []);



    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'95%', maxWidth:'1000px', minHeight:'68vh', margin:'auto'}}>
          <div style={{textAlign:'center', color: style.color5,}}>
            <h1 style={{ marginTop:'50px', marginBottom:'50px'}}> Contact Us</h1>
            <strong style={{ marginTop:'100px', marginBottom:'0px'}}>
                We would love to hear from you. please send us an email at
                </strong><div style={{marginTop:'20px'}}>
                <a href="mailto:brainwavesoundlab@gmail.com" style={{color:style.color4, fontSize:'18pt'}}><strong>brainwavesoundlab@gmail.com</strong></a></div>
                <h3 style={{ marginTop:'20px'}}>
                    and we will reply as soon as possible. 
                </h3>
            </div>
          </div>
        </>

    )
}