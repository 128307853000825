import {React, useState, useEffect, useContext} from 'react';
import { styled } from '@mui/material/styles';
import {useNavigate, Link} from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useStyle } from '../../context/style_context/styleContext';
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { toast } from 'react-hot-toast';
//import ProfilePicIcon from '../../icons/Default_Profile_Pic.svg'
import AppleLogo from "../../icons/apple_logo.svg"




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




//addToCart(productSKU)



export default function MyGearTableRow({productSKU, productName, productVersion, imageURI, productURI, windowsDownloadURI, macDownloadURI}) {
    //const { style, setStyle } = useStyle();
    const [products, setProducts] = useState([]); // Corrected initialization as an empty array
    const [productDetails, setProductDetails] = useState([]);
    const { style, setStyle } = useStyle();
    const { addToCart, removeFromCart, cartItems } = useContext(ShoppingCartContext);
    const navigate = useNavigate()

    const deleteFromCart = async (e) => {

        e.preventDefault();
        console.log("Button clicked")   

    }



    const downloadProductWindows = async (e) => 
      {

        try{
          const response = await axios.post('/api/log_download_attempt', 
            {
              sku: productSKU,
              productName,
              productVersion,
              productURI: windowsDownloadURI,
            });
        }
        catch{
          
        }
        window.location.href = windowsDownloadURI;
    }

    const downloadProductMac = async (e) => 
      {

        try{
          const response = await axios.post('/api/log_download_attempt', 
            {
              sku: productSKU,
              productName,
              productVersion,
              productURI: macDownloadURI,
            });
        }
        catch{
          
        }
        

        console.log("download product!")
        console.log(macDownloadURI);
        window.location.href = macDownloadURI;
    }


    // console.log("PRODUCT SKU: ")
    // console.log(productSKU)

    
      const [productInfo, setProductInfo] = useState({
    
        sku: productSKU,
        imgURI: imageURI,
        productURI: "",
        windowsDownloadURI: windowsDownloadURI,
    
    })
    

  return (
 
            <StyledTableRow key={productInfo.SKU} sx={{minHeight:'80px', marginTop:'10px', color:'white'}}>
              {/* <StyledTableCell component="th" scope="row">
                {productInfo.sku}
              </StyledTableCell> */}


              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}><div style={{fontSize:'16pt'}}>{productInfo.sku }</div></StyledTableCell>
              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}><img src={productInfo.imgURI} style={{width:'75px'}}></img></StyledTableCell>
              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}>
              <Button onClick={downloadProductWindows}><div><div><img src='/windows_logo.png' style={{flex:1 , minWidth:'50px', height:'60px', maxWidth:'100%', maxHeight:'100%', margin:'auto'}} /></div>Download For Windows</div></Button></StyledTableCell>
              <StyledTableCell align="right" sx={{textAlign:'center', backgroundColor:style.color9, color:'white', flex:1}}>
              <Button onClick={downloadProductMac}><div><div><img src='/windows_logo.png' style={{flex:1 , minWidth:'50px', height:'60px', maxWidth:'100%', maxHeight:'100%', margin:'auto'}} /></div>Download For Mac</div></Button></StyledTableCell>

            </StyledTableRow>
          
  );
}
