import {useState, useEffect, select} from "react"
import { Link } from "react-router-dom"
import axios from 'axios';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom'
import { useStyle } from "../../context/style_context/styleContext";
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';

import { Checkbox, TextField, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton, FormGroup, FormControlLabel } from "@mui/material";




export default function CreateLicense(){


    const { style, setStyle } = useStyle();

    ReactGA.send({
        hitType:"pageview",
        page:"create_license",
        title:"create_license",
      })

      useEffect(() => {
        document.title = 'Brainwave Sound Lab | ADMIN | Create License';
    }, []);

    const [data, setData] = useState({

        sku:"",
        productVersion:"",
        type:"",
        transactionNumber:"",
        allowedInstalls: 0,
        machinesInstalledOn:[],
        expires:"",

    })

    console.log("submitting data...")
    const submitData = async (e) => {

        e.preventDefault();     


            console.log("sending....")
            try{
                console.log(data)
                const{response} = await axios.post('/api/create_license',data)
    
                console.log("response")
                console.log(response)
            if(response.error){
                console.log("error")
                toast.error(response.error)
            }
            else{
                //setData({})
                toast.success('Success! License created!')
                //navigate('/home')
            }
            }catch(error){
                console.log(error)
            }

    }


    return(
        <>
        <div style={{display:'flex', flexDirection:'column', width:'90%', maxWidth:'800px', margin:'auto', color:style.color5, textAlign:'center', marginTop:'40px', backgroundColor:style.color9, borderRadius:'10px', borderStyle:'solid', borderColor:style.color4}}>
    <div style={{width:'90%', margin:'auto'}}>

    
        <div style={{display:'flex', flex:1, textAlign:'center', marginTop:'20px'}}>
            <h1 style={{flex:1, textAlign:'center', margin:'auto', marginBottom:'40px'}}>
                New Product
            </h1>
           
        </div>
        <div style={{maxWidth:'400px', margin:'auto'}}>
        <div style={{display:'flex', flex:1, textAlign:'center', minWidth:'300px', margin:'auto', marginBottom:'30px'}}>
            <h4 style={{flex:1, textAlign:'left', margin:'auto', color:style.color5}}>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="SKU"
                variant="outlined"
                value= {data.sku}
                onChange={(e) => setData({...data, sku: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>

                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="product version"
                variant="outlined"
                value= {data.productVersion}
                onChange={(e) => setData({...data, productVersion: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>

                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="Type"
                variant="outlined"
                value= {data.type}
                onChange={(e) => setData({...data, type: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                <div style={{marginBottom:'25px'}}>
                <TextField
                id="outlined-basic"
                label="transaction number"
                variant="outlined"
                value= {data.transactionNumber}
                onChange={(e) => setData({...data, transactionNumber: e.target.value})}
                size='small'
                fullWidth
                sx={{
                    backgroundColor: style.color10,
                    borderRadius:'4px',
                    '& .MuiInputBase-input': {
                    color: style.color5,  // Change the font color here
                    fontSize: '16pt',  // Change the font size here
                                                    
                    },
                    '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: style.color4, // Change the border color if needed
                        borderWidth:'2px'
                    },
                    '&:hover fieldset': {
                        borderColor: style.color4, // Change the border color on hover if needed\
                        borderWidth:'3px',                                                               
                 
                        
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: style.color4, // Change the border color when focused if needed
                        borderWidth:'3px'
                        
                    },
                    '&.Mui-disabled': {
                        '& fieldset': {
                        borderColor: style.color4, // Change the border color when disabled
                        },
                    },
                    },
                    '& .MuiInputLabel-root': {
                    color: style.color7, // Change the label color here
                    fontSize:'16pt',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                    color: style.color5, // Change the label color when focused here                                 
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                    color: style.color7, // Change the label color when disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                    '& input': {
                        color: style.color4, // Change the input font color when disabled // so it works in safari
                        WebkitTextFillColor: style.color4, // so it works on chrome
                        fontSize:'16pt'
                    },
                    },
                    '& .MuiOutlinedInput-input': { color: 'white',
                    '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 100px #312E33 inset',
                        WebkitTextFillColor: style.color7, color:style.color7
                     }, }, 
                }}
                />
                

                </div>
                
                



                </h4>
                </div>
        </div>


        <div style={{marginTop:'50px', marginBottom:'50px', display:'flex', flex:1}}>
            <Button onClick={submitData} sx={{ my: 2, color:style.color5, display:'block', backgroundColor:style.color4, fontSize:'16pt', width:'100%', minWidth:'250px', margin:'auto', marginTop:'0px', borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', '&:hover': {backgroundColor: style.color4 }, }}>Create Product</Button>
        </div> 

        </div>
        </div>      
                
         </>         

    )
}

