import { useState, useEffect, useContext } from "react";
import { useStyle } from "../../context/style_context/styleContext";
import {useNavigate, Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ReactGA from 'react-ga4';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ProductsTable from "../../components/ProductsTable/ProductsTable";
import ShoppingCartItemRow from "../../components/ShoppingCartItemRow/ShoppingCartItemRow";
import ShoppingCartTable from "../../components/ShoppingCartTable/ShoppingCartTable";
import { ShoppingCartContext } from '../../context/shoppingCartContext';
import MyGearTableRow from "./MyGearTableRow";






 export default function MyGear() {

    const [gear, setGear] = useState([])

    

    const { style, setStyle } = useStyle();

    useEffect(() => {
                     getMyGear();
    }, []);


    const download = async (e) => {

        var sku
        var productName
        var productVersion
        var downloadURI
        
        
        const{data} = await axios.post('/api/log_download_attempt',{

        })

    }
    

    const getMyGear = async (e) => {
        console.log("Getting gear.. ")       

        
        try{
            
            const{data} = await axios.post('/api/get_my_gear')
            console.log(data)
        if(data.error){
            toast.error(data.error)
        }
        else{
            
            console.log('*******************  incoming data ****************************')
            console.log(data)
            console.log('***********************************************')
            setGear(data)          

        }
        }catch(error){
            console.log(error)
        }
    }


        const getLicenses = async (e) => {
            console.log("Getting License Info.. ")        
            try{
                
                const{data} = await axios.post('/my_gear')
                console.log(data)
            if(data.error){
                toast.error(data.error)
            }
            else{
                
                var listOfSkus = [];

                for(var i = 0; i < data.length; i++)
                {
                    const extractedSKU = data[i].sku;
                    console.log("LICENSE NUMBER EXTRACTED: ", extractedSKU)
                    listOfSkus.push(extractedSKU)
                }
                console.log(data)
                setGear(data) 
                console.log("LIST OF SKUS:")
                //setSkuList(listOfSkus)           

            }
            }catch(error){
                console.log(error)
            }
        }



  return (
    <div style={{minHeight:'68vh' }}>

        <div style={{margin:'auto'}}>
        
        
            <div style={{display:'flex', flexDirection:'row', width:'95%', maxWidth:'1100px', margin:'auto', textAlign:'center', marginTop:'25px', marginBottom:'50px'}}>
                <h1 style={{fontSize:"24pt", textAlign:'center', margin:'auto'}}>My Gear</h1>
            </div>
 
            <TableContainer component={Paper} sx={{width:'95%', maxWidth: 1000, flex:1, display:'flex', margin:'auto'}}>
      <Table sx={{borderColor:style.color4, borderWidth:'2px', borderStyle:'solid', margin:'auto'}} aria-label="customized table">

        <TableBody>
        {gear.map((product) => (
            <MyGearTableRow
            key={product.SKU} 
            productSKU={product.name}
            imageURI = {product.imageURIs[0]}
            windowsDownloadURI={product.windowsDownloadURI}
            macDownloadURI={product.macDownloadURI}
            //productImageURIs={item.imageURIs}
            sx={{ minWidth: 700, marginBottom:'30px'}}
            />
  ))}
        </TableBody>
      </Table>
    </TableContainer>


</div>
</div>

    
  );

}