import { React, useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import CookieConsent, { Cookies } from "react-cookie-consent";




export default function CookieHandler(){

    const handleAcceptCookie = () => {

        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
          //ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
          //console.log("KEY: ", process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
        }    
      
      };
      

      const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
        console.log("DECLINED!")
      };

  return (  
<>

<CookieConsent enableDeclineButton flipButtons onAccept={handleAcceptCookie} onDecline={handleDeclineCookie} buttonText="Allow All" declineButtonText = "Only Necessary" style={{ fontSize:'1.2em', textAlign:'center'}} declineButtonStyle={{color: "black", background: '#535bf2', fontSize: "1.2em", fontWeight:'bold', minWidth:'250px', borderRadius:'8px'}} buttonStyle={{background: '#535bf2', fontSize: "1.2em", fontWeight:'bold', minWidth:'250px', borderRadius:'8px'}}>
      <div >
          We use cookies to enhance your browsing experience, provide personalized content, and analyze site traffic. Please select your cookies preference. For more information about the cookies we use, please view our <Link to='/privacy_policy' style={{fontSize:'.9em', fontWeight:'bold', textAlign:'center', color:'#535bf2'}}>Privacy Policy</Link>. <br />

      </div>
          
        
    </CookieConsent>



</>
    
  )}

