import React, { createContext, useContext, useState } from 'react';


export const ProfileContext = createContext();


export const ProfileProvider = ({ children }) => {


  const [profile, setProfile] = useState({

    firstName: 'Nate',
    lastName: 'Parish',
    profileImage:'https://d3zlw6prgce9n.cloudfront.net/da2bf95e-4177-46d1-a820-da66cec2d4b1-1719095065518.png',
  });



  return (
    <ProfileContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};


export const useProfile = () => useContext(ProfileContext);
